const nullBrand = {
  id: 0,
  slug: 'n-a',
  name: 'N/A',
  currency_code: 'N/A',
  members: {
    data: []
  }
}

export const activeBrand = (state) => {
  const brand = state.auth.brands.find(brand => brand.id === state.auth.activeBrandId)

  return brand || nullBrand
}

export const activeBrandId = state => {
  return state.auth.activeBrandId || 0
}
