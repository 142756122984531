<template>
  <div v-if="isVisible" data-testid="TheTrialPeriodWarning">
    <div class="p-a-m has-background-primary is-flex">
      <i18n class="is-size-4" path="components.the_trial_period_warning.heading">
        <span slot="time">{{ timeFromNow(subscription.trialEndsAt) }}</span>
      </i18n>
      <v-button
        v-if="firstOrganisation.applicableDiscount"
        class="is-black m-l-a"
        data-testid="FreeTrialDialogTrigger"
        @click="isFreeTrialModalVisible = true"
      >
        <coupon-data-provider
          :coupon="firstOrganisation.applicableDiscount"
          :value="subscription.commitmentAmount"
        >
          <template slot-scope="{ percentage }">
            <i18n path="components.the_trial_period_warning.button_content">
              <span slot="amount">{{ percentageFormat(percentage) }}</span>
            </i18n>
          </template>
        </coupon-data-provider>
      </v-button>
    </div>
    <free-trial-upgrade-dialog
      v-if="firstOrganisation.applicableDiscount"
      :visible.sync="isFreeTrialModalVisible"
      :coupon="firstOrganisation.applicableDiscount"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CouponDataProvider from '@/components/billing/CouponDataProvider'
import FreeTrialUpgradeDialog from '@/components/billing/FreeTrialUpgradeDialog'
import TimeFromNowMixin from '@hypefactors/shared/js/mixins/TimeFromNowMixin'
import PercentageMixin from '@hypefactors/shared/js/mixins/PercentageMixin'

/**
 * Generates a warning of the account is on a trial and has no card or not
 * @module TheTrialPeriodWarning
 */
export default {
  name: 'TheTrialPeriodWarning',

  components: { FreeTrialUpgradeDialog, CouponDataProvider },

  mixins: [TimeFromNowMixin, PercentageMixin],

  data () {
    return {
      isFreeTrialModalVisible: false
    }
  },
  computed: {
    ...mapGetters(['subscription', 'firstOrganisation']),

    /**
     * Returns true if there is no card and the organisation is on a trial
     * @return {boolean}
     */
    isVisible () {
      return this.subscription.isOnTrial
    }
  }
}
</script>
