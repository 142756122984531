<template>
  <el-select
    ref="select"
    v-model="computedValue"
    v-bind="attributes"
    @change="(v) => $emit('change',v)"
  >
    <el-option
      v-for="item in values"
      :key="$attrs['value-key'] ? item[$attrs['value-key']] : (keyProp ? item[keyProp]: item)"
      :label="labelProp ? item[labelProp] : item"
      :data-test-key="$attrs['value-key'] ? item : (valueProp ? item[valueProp] : item)"
      :value="$attrs['value-key'] ? item : (valueProp ? item[valueProp] : item)"
    >
      <slot :item="item" />
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'BasicSelect',
  inheritAttrs: false,
  props: {
    values: {
      type: [Array, Object],
      default: () => ([])
    },
    valueProp: {
      type: String,
      default: ''
    },
    labelProp: {
      type: String,
      default: ''
    },
    keyProp: {
      type: String,
      default: ''
    },
    value: {
      type: [Number, String, Object, Array],
      default: null
    }
  },
  computed: {
    attributes () {
      return {
        filterable: true,
        ...this.$attrs
      }
    },
    computedValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    focus () {
      this.$refs.select.focus()
    }
  }
}
</script>
