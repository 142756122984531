import _isEqual from 'lodash/isEqual'
import _get from 'lodash/get'

/**
 * Generates a watcher function
 * @param {string|function} method
 * @returns {Function} - a watcher handler function
 * @param {Boolean} passParams - should it pass the parameters to the method or not
 */
export function generateUniqueWatcher (method, passParams = true) {
  return function (current, previous) {
    if (_isEqual(current, previous)) return
    if (typeof method === 'string') {
      this[method].apply(this, passParams ? [current, previous] : [])
    } else if (typeof method === 'function') {
      method.apply(this, passParams ? [current, previous] : [])
    }
  }
}

/**
 * Creates a computed getter/setter configuration
 * @param {string} [valueName] - The value to fetch data from
 * @param {string} [event] - The event to emit the changed data to.
 * @returns {{get:function, set: function}}
 */
export function generateComputedSetterWithEmit (valueName = 'value', event = 'input') {
  return {
    get () {
      return _get(this, valueName)
    },
    set (value) {
      this.$emit(event, value)
    }
  }
}

/**
 * Generates a computer setter that uses a provided method.
 * @param getterName
 * @param setterName
 * @return {*}
 */
export function generateComputedSetterWithMethod (getterName, setterName) {
  return {
    get () {
      return _get(this, getterName)
    },
    set (value) {
      this[setterName](value)
    }
  }
}

/**
 * Generates an array of unique elements, based on the provided filters.
 * Used as a helper for ElementSelect component.
 * @param {[]} sourceArray - The source array to filter from
 * @param {{}[]} filterAgainstArray - The array to filter against
 * @param {string} [key] - The key to look for in both arrays
 * @param {string} defaultKey - The default key to filter by if Key is not provided
 * @param {boolean} [include=false] - Should it include or exclude the items
 * @return {[]}
 */
export function generateUniqueElementSelectList (sourceArray, filterAgainstArray, key, defaultKey, include = false) {
  if (!filterAgainstArray.length) return sourceArray
  return sourceArray.filter(sourceElement => {
    const elementFound = filterAgainstArray.find(filteredAgainstElement => {
      return key
        // if there is a key, use it
        ? sourceElement[key] === filteredAgainstElement[key]
        // otherwise use the defaultKey to search in the source
        : sourceElement[defaultKey] === filteredAgainstElement
    })
    return include ? elementFound : !elementFound
  })
}
