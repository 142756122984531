<template>
  <div
    v-clickoutside="handleClickOutside"
    :class="{'is-active': isVisible, 'has-checkboxes': hasCheckboxes, 'is-selected': isSelected }"
    class="dropdown DropdownList"
    data-testid="DropdownList"
  >
    <div
      class="dropdown-trigger"
      @click.stop="isVisible = !isVisible"
    >
      <slot
        name="trigger"
        :is-selected="isSelected"
      />
    </div>

    <div
      :class="menuClasses"
      class="dropdown-menu"
      role="menu"
    >
      <div class="dropdown-content has-text-left">
        <div
          v-if="filterBy"
          class="p-a-s"
        >
          <input
            v-model="input"
            :placeholder="filterPlaceholder"
            type="text"
            class="input"
          >
        </div>

        <div
          v-if="$isEmpty(filteredElements)"
          data-testid="DropdownList__no-items"
          class="dropdown-item has-text-grey-light"
        >
          {{ $t('components.dropdown_list.no_items_in_list') }}
        </div>
        <template v-else>
          <a
            v-for="element in filteredElements"
            :key="element[keyBy]"
            :class="{'is-active': isElementSelected(element)}"
            class="dropdown-item"
            :data-testid="`DropdownList__Item__${element[keyBy]}`"
            @click.stop="toggleSelected(valueKey ? element[valueKey] : element)"
          >
            <span
              v-if="hasCheckboxes"
              class="icon"
            >
              <i class="hf hf-fw" />
            </span>

            <slot :element="element">
              {{ labelKey ? element[labelKey] : element }}
            </slot>
          </a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { orderBy } from 'lodash'

export default {
  name: 'DropdownList',

  props: {
    menuClasses: {
      type: String,
      default: ''
    },
    filterPlaceholder: {
      type: String,
      default: function () {
        return this.$t('components.dropdown_list.filter_placeholder')
      }
    },
    keyBy: {
      type: String,
      default: 'id'
    },
    filterBy: {
      type: String,
      default: ''
    },
    orderBy: {
      type: String,
      default: null
    },
    elements: {
      type: Array,
      required: true
    },
    hasCheckboxes: {
      type: Boolean,
      default: false
    },
    selectedElements: {
      type: Array,
      default: () => ([])
    },
    valueKey: {
      type: String,
      default: ''
    },
    isActiveProp: {
      type: String,
      default: ''
    },
    handler: {
      type: Function,
      default: null
    },
    labelKey: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      isVisible: false,
      input: ''
    }
  },

  computed: {
    filteredElements () {
      let elements = this.elements

      if (this.orderBy) {
        elements = orderBy(elements, this.orderBy)
      }

      if (!this.filterBy) {
        return elements
      }

      const normalizedSearch = this.input.toLowerCase().trim()

      return elements
        .filter(v => {
          return (String(v[this.filterBy])).toLowerCase().indexOf(normalizedSearch) !== -1
        }) // filter out by name
    },

    isSelected () {
      return !!Object.keys(this.selectedElements).length
    }
  },

  methods: {
    toggleSelected (value) {
      if (this.handler) return this.handler(value)

      let selectedElements = this.selectedElements.slice(0)

      const indexInSelected = selectedElements.findIndex(v => {
        const vk = this.valueKey

        if (vk) {
          return v === value
        }

        return v[this.keyBy] === value[this.keyBy]
      })

      if (indexInSelected !== -1) {
        selectedElements.splice(indexInSelected, 1)
      } else {
        selectedElements.push(value)
      }

      this.$emit('update:selectedElements', selectedElements)
      this.$emit('change', selectedElements)
    },

    isElementSelected (element) {
      return this.selectedElements.includes(this.isActiveProp ? element[this.isActiveProp] : element)
    },

    handleClickOutside () {
      this.isVisible = false
      this.input = ''
    }
  }
}
</script>
