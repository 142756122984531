/**
 * Gives money helper functions
 * Must have {@see setMoneyHelpers} called first to set convertCurrency and getCurrentSymbol
 */

import numeral from 'numeral'
import { abbr } from './numberUtils'

let convertCurrency = () => 1
let getCurrencySymbol = () => ''

/**
 * Sets the money formatter helpers in the ES module's scope
 * @param {function} currencyConvertFunction - The converter from one to another currency. {@see CurrencyConverter#convertCurrency}
 * @param {function} currentSymbolFetcher - Fetches the currently active currency. {@see module:currencySymbol}
 */
export function setMoneyHelpers (currencyConvertFunction, currentSymbolFetcher) {
  convertCurrency = currencyConvertFunction
  getCurrencySymbol = currentSymbolFetcher
}

/**
 * Returns the provided number in an abbreviated format with currency at the end
 * @param {number} value - Number to transform
 * @param {string} [symbol] - Currency symbol to append and convert it
 * @param {boolean} [convert=true] - Should it convert or not
 * @param {string} [symbolFrom=DKK] - Currency the value is coming in
 * @param {boolean} [addSymbol=true] - Whether the currency symbol should be added
 * @return {string} - returns in format '22mil DKK'
 */
export function moneyAbbrFormat (value, symbol, convert = true, symbolFrom = 'DKK', addSymbol = true) {
  symbol = (symbol || getCurrencySymbol()).toUpperCase()
  symbolFrom = symbolFrom.toUpperCase()

  if (convert) {
    value = convertCurrency(value, symbolFrom, symbol)
  }

  return !addSymbol ? value : `${abbr(value)} ${symbol}` // add the currency symbol behind
}

/**
 * Returns money in a formatter manner.
 * @param {number} value - Number to transform
 * @param {string} symbol - Currency symbol to append and convert ot
 * @param {boolean} convert - Should it convert or not
 * @param {string} [symbolFrom=DKK] - Currency the value is coming in
 * @param {boolean} [addSymbol=true] - Should the symbol be appended or not
 * @return {*} - returns in format '22mil DKK'
 */
export function moneyFormat (value, symbol, convert = true, symbolFrom = 'DKK', addSymbol = true) {
  symbol = (symbol || getCurrencySymbol()).toUpperCase()
  symbolFrom = symbolFrom.toUpperCase()

  if (convert) {
    value = convertCurrency(value, symbolFrom, symbol)
  }
  value = numeral(value).format('0')

  return !addSymbol ? value : `${value} ${symbol}`
}

/**
 * Returns the provided number in an abbreviated html string with appended currency symbol.
 * Mostly used for cases when the currency symbol needs extra styling
 * @param {number} value - Number to transform
 * @param {string} symbol - Currency symbol to append and convert ot
 * @return {string} - returns in format '22mil DKK'
 */
export function moneyAbbrFormatHtml (value, symbol) {
  symbol = (symbol || getCurrencySymbol()).toUpperCase()

  value = convertCurrency(value, 'DKK', symbol)

  return `<span class="currency-value">${abbr(value)}</span> <span class="currency-symbol">${symbol}</span>` // add the currency symbol behind
}
