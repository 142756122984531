<template>
  <div
    :class="{ 'has-wrap': shouldWrap, 'has-error': hasAnyErrors }"
    class="FormField field"
  >
    <slot name="label">
      <div class="is-flex">
        <label
          v-if="label && !hideLabel"
          :class="[{ 'has-error': hasAnyErrors }, labelClasses]"
          class="label FormFieldLabel"
        >
          <span class="is-size-6 m-l-a">
            {{ label }}

            <span
              v-show="!isRequired"
              :data-testid="`${attribute}-validation-rule`"
              class="is-lowercase has-text-grey-light"
            >
              {{ $t('forms.optional') }}
            </span>
          </span>
        </label>

        <el-tooltip
          v-if="tooltip"
          placement="top"
        >
          <span class="m-l-a is-size-4-mobile">
            <i class="hf hf-question-circle" />
          </span>

          <div slot="content" v-html="tooltip" />
        </el-tooltip>
      </div>
    </slot>

    <slot
      :errors="activeErrors"
      :has-errors="hasAnyErrors"
      :first-error-message="firstErrorMessage"
      :validator="preferredValidator"
      name="control"
    >
      <div
        :class="additionalClasses"
        class="control"
      >
        <slot
          :errors="activeErrors"
          :has-errors="hasAnyErrors"
          :first-error-message="firstErrorMessage"
          :classes="computedInputClasses"
          :validator="preferredValidator"
          :placeholder="resolvedAttribute"
          :attrs="{ name, class: computedInputClasses }"
        />

        <span
          v-if="leftIcon || $slots.leftIcon"
          :class="[{ 'has-text-danger': hasAnyErrors }, iconClass]"
          class="icon is-small is-left"
        >
          <slot name="leftIcon">
            <v-icon :type="leftIcon" />
          </slot>
        </span>

        <span
          v-if="rightIcon"
          :class="{ 'has-text-danger': hasAnyErrors }"
          class="icon is-small is-right"
        >
          <i :class="rightIcon" class="hf" />
        </span>
      </div>
    </slot>

    <slot
      :errors="activeErrors"
      :has-errors="hasAnyErrors"
      :first-error-message="firstErrorMessage"
      name="errors"
    >
      <template v-if="hasErrors">
        <p
          v-if="showSingleError"
          class="help is-danger"
        >
          {{ firstErrorMessage }}
        </p>

        <template v-if="!showSingleError">
          <p
            v-for="error in activeErrors"
            :key="error.validationKey"
            :data-validation-attr="error.validationKey"
            class="help is-danger"
          >
            {{ getErrorMessage(error.validationKey, error.params) }}
          </p>
        </template>
      </template>

      <template v-if="serverErrors">
        <template v-if="Array.isArray(serverErrors)">
          <p
            v-for="error in serverErrors"
            :key="error"
            class="help is-danger"
          >
            {{ error.detail || error }}
          </p>
        </template>

        <p v-else class="help is-danger">
          {{ serverErrors }}
        </p>
      </template>
    </slot>
  </div>
</template>

<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor'

export default {
  name: 'FormField',
  mixins: [singleErrorExtractorMixin],
  props: {
    leftIcon: {
      type: String,
      default: ''
    },
    rightIcon: {
      type: String,
      default: ''
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    serverErrors: {
      type: [Array, String],
      default: () => []
    },
    tooltip: {
      type: String,
      default: ''
    },
    forceError: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    labelClasses: {
      type: [String, Object],
      default: ''
    },
    iconClass: {
      type: String,
      default: ''
    }
  },

  computed: {
    additionalClasses () {
      return {
        'has-icons-left': this.leftIcon || this.$slots.leftIcon,
        'has-icons-right': this.rightIcon
      }
    },

    isRequired () {
      return this.required || Object.keys(this.preferredValidator)
        .some(key => key.includes('required'))
    },

    hasAnyErrors () {
      return (
        this.forceError ||
        this.hasErrors ||
        (this.serverErrors && this.serverErrors.length > 0)
      )
    },

    shouldWrap () {
      return Object.keys(this.preferredValidator).length > 5
    },

    computedInputClasses () {
      return {
        'is-danger': this.hasAnyErrors
      }
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.FormField {
  .FormFieldLabel {
    text-transform: uppercase;
    font-weight: normal;
    color: $grey;
    font-size: $hf__font-label;
    margin-bottom: 0;
  }

  &.has-error {
    .FormFieldLabel {
      color: $hf__color-danger;
    }

    .el-input__inner {
      border-color: $hf__color-danger;
    }
  }

  &.has-addons {
    &.has-wrap {
      flex-wrap: wrap;

      .input {
        min-width: 0;
      }

      .is-expanded {
        width: 10%;
      }
    }

    .button {
      height: 3rem;
      border-radius: 2px;

      &.is-static-white {
        color: $grey;
        pointer-events: none;
        cursor: default;
        background: $white;
        border-color: $hf__input-border-color;
        border-left: 0;
      }
    }
  }
}
</style>
