import { Invoice } from '@/models/Invoice'
import { Organisation } from '@hypefactors/shared/js/models/Organisation'
import { handleError } from '@hypefactors/shared/js/utils'
import { createMappedClassSetter, createSetter } from '@hypefactors/shared/js/utils/vuexUtilities'
import { PusherInstance } from '@hypefactors/shared/js/services/SocketService'

import {
  LOGOUT,
  STORE_AUTH_TOKEN,
  STORE_USER_DETAILS,
  STORE_BRANDS,
  SET_ACTIVE_BRAND_ID,
  STORE_USER_ORGANISATIONS,
  STORE_USER_BRAND_PERMISSIONS,
  CLEAR_USER_BRAND_PERMISSIONS,
  CLEAR_USER_ORGANISATION_PERMISSIONS,
  STORE_USER_ORGANISATION_PERMISSIONS
} from '../mutations'

import { persist, forget, getUrlToDomain, load } from '@/utils'
import { CrossAuth } from '@/services/CrossStorage'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import { fallbackRolePermission, userFactory } from '@hypefactors/shared/js/factories/user'

import { BillingApiService } from '@/services/api/BillingApiService'
import { NoBrandsError } from '@hypefactors/shared/js/errors/NoBrandsError'
import { UserApiService } from '@hypefactors/shared/js/services/api/UserApiService'
import { LocationService } from '@hypefactors/shared/js/services/LocationService'

const state = {
  token: '',
  brands: [],
  activeBrandId: null,
  user: userFactory(),
  token_before_impersonation: load('TOKEN_BEFORE_IMPERSONATION', ''),
  /** @type {Organisation[]} */
  organisations: [],
  permissions: {},
  organisation_permissions: {},
  social_networks: {},
  temporaryUseDemoData: false,
  /** @type {HF_Invoice[]} */
  invoices: [],
  isFetchingInvoices: false
}

const getters = {
  authToken (state) {
    return state.token
  },

  currentUser (state) {
    return state.user
  },

  signedIn (state) {
    return !!state.token
  },

  authUserId (state) {
    return state.user.id
  },

  brands (state) {
    return state.brands
  },

  currentLocation (state, getters, rootState, rootGetters) {
    const country = _get(state, 'user.country.data')
    if (_isEmpty(country)) return rootGetters.countries[0]
    return country
  },

  neighbouringLocations (state) {
    return _get(state, 'user.country.data.neighbouringCountries.data', [])
  },

  organisations (state) {
    return state.organisations
  },

  /**
   * @returns HF_Organisation
   */
  firstOrganisation (state) {
    return state.organisations.length ? state.organisations[0] : new Organisation()
  },

  /**
   * Returns the Organisations active Subscription
   * @returns {?HF_Subscription}
   */
  subscription (state, getters) {
    return getters.firstOrganisation.subscription
  },

  /**
   * @type {HF_StripeCard}
   */
  activeCard (state, getters) {
    return getters.firstOrganisation.card
  },

  /**
   * Whether the organisation has a credit card at all
   * @return {boolean}
   */
  hasCard (state, getters) {
    return !!getters.activeCard
  },

  isStaffMember (state) {
    return state.user.is_staff_member
  },

  /**
   * Returns boolean whether a user's organisation plan is freemium or not
   * Uses the firstOrganisation's subscription for now
   * @return {boolean}
   */
  isFreemiumUser (state, getters) {
    return getters.subscription && getters.subscription.planSlug === 'freemium'
  },

  fullName (state, getters) {
    return getters.currentUser.full_name || 'HF User'
  },

  userEmailSettings (state, getters) {
    return getters.currentUser.email_settings
  },

  userOrganisationPermission: state => (permission) => {
    return state.organisation_permissions[permission] || fallbackRolePermission()
  },

  userHasBrandPermission: state => (permission, brandId = state.activeBrandId) => {
    if (!brandId) return false
    const roleForBrand = state.permissions[brandId]
    if (!roleForBrand) return false
    return roleForBrand['permissions'].hasOwnProperty(permission)
  },

  userBrandPermission: (state, getters) => (permission, brandId = state.activeBrandId) => {
    if (!getters.userHasBrandPermission(permission, brandId)) {
      return fallbackRolePermission()
    }
    return state.permissions[brandId]['permissions'][permission]
  },

  /**
   * Returns the permission object for the provided brand and permission path
   * @param {Boolean, String} permission
   * @param {!String} brandId
   * @returns {Object}
   */
  authorize: (state, getters) => (permission, brandId) => {
    /* Allow staff members everything */
    if (getters.isStaffMember) return { can: true }

    /* allow sending a boolean for edge cases */
    if (typeof permission === 'boolean') {
      const result = {
        can: permission
      }
      if (permission === true) return result
      return {
        ...result,
        reason: {
          cause: 'bool',
          type: 'bool'
        }
      }
    }
    return getters.userHasBrandPermission(permission, brandId)
      ? getters.userBrandPermission(permission, brandId)
      : getters.userOrganisationPermission(permission)
  },

  authorizeBool: (state, getters) => (permission, brandId) => {
    return getters.authorize(permission, brandId)['can']
  },

  requestDemoData (state, getters, rootState, rootGetters) {
    return getters.isFreemiumUser || state.temporaryUseDemoData
  },

  getBrand: state => brandId => state.brands.find(b => b.id === brandId),

  impersonated: state => !!state.token_before_impersonation
}

const actions = {
  async logout ({ commit, dispatch }, token) {
    commit(LOGOUT)
    commit(SET_ACTIVE_BRAND_ID, null)
    commit(CLEAR_USER_BRAND_PERMISSIONS)
    commit(CLEAR_USER_ORGANISATION_PERMISSIONS)
    commit('SET_TEMPORARY_USE_DEMO_DATA', false)
    commit('SET_TOKEN_BEFORE_IMPERSONATION', '')
    commit('STORE_INVOICES', [])
    // reset the app_data_loaded boolean in app.js
    commit('SET_APP_DATA_LOADED', false, { root: true })
    await dispatch('clearFilters')
    await dispatch('dashboard/clearActiveDashboardBrands', undefined, { root: true })
    PusherInstance.config.auth.headers['Authorization'] = ''

    forget('access_token')
    forget('refresh_token')
    forget('active_brand')
  },

  storeAuthToken ({ commit }, tokens) {
    commit(STORE_AUTH_TOKEN, tokens.accessToken)
    PusherInstance.config.auth.headers['Authorization'] = `Bearer ${tokens.accessToken}`
    persist('access_token', tokens.accessToken)
    persist('refresh_token', '') // we dont have it at this endpoint
  },

  async fetchUser ({ dispatch, getters, commit }, { forcePermissions = false } = {}) {
    try {
      await dispatch('fetchUserRequest')
      /* Remove journalists from hypefactors */
      if (getters.currentUser.type === 'reader') {
        LocationService.assign(getUrlToDomain('/', 'hypenews'))
        return
      }
      if (!getters.currentUser.onboarded_at) {
        LocationService.assign(`/onboarding/${getters.currentUser.id}`)
        return
      }
      await Promise.all([
        dispatch('fetchUserBrandsRequest'),
        dispatch('fetchUserOrganisationsRequest')
      ])
      await dispatch('fetchUserBrandPermissions', {
        forceFetch: forcePermissions
      })
    } catch (err) {
      if (err instanceof NoBrandsError) {
        handleError(err)
      } else {
        throw err
      }
    }
  },

  async setActiveBrand ({ commit, dispatch }, id) {
    await dispatch('fetchUserBrandPermissions', { brandId: id })
    await dispatch('storeActiveBrandId', id)
  },

  async storeActiveBrandId ({ commit, dispatch }, id) {
    await dispatch('setFilter', {
      name: 'brand_scope', value: id
    })

    commit(SET_ACTIVE_BRAND_ID, id)
  },

  fetchUserRequest ({ commit }) {
    return UserApiService.fetchUser({ params: { include: ['country.neighbouringCountries'] } })
      .then(userResponse => {
        commit(STORE_USER_DETAILS, userResponse)
        commit(STORE_USER_ORGANISATION_PERMISSIONS, userResponse)
      })
  },

  async fetchUserBrandsRequest ({ commit, state, dispatch, getters }) {
    const brands = await UserApiService.fetchBrands({ params: { include: 'country' } })
    if (brands.length === 0) {
      commit(STORE_BRANDS, [])
      await dispatch('storeActiveBrandId', null)
      throw new NoBrandsError()
    }
    /* istanbul ignore next */
    commit(STORE_BRANDS, brands)

    // set the first brand as active if the currently active brand is not one of the fetched brands
    if (!state.activeBrandId || (brands.findIndex(org => org.id === state.activeBrandId) === -1)) {
      return dispatch('storeActiveBrandId', brands[0].id)
    }
  },

  fetchUserOrganisationsRequest ({ commit }) {
    return UserApiService.fetchOrganisations({ params: { include: 'country,subscription,card,accountManager' } })
      .then(organisations => {
        commit(STORE_USER_ORGANISATIONS, organisations)
      })
  },

  /**
   * Fetch user permissions for the given brand
   * @param {string} brandId - The brand id to fetch permissions for
   * @param {boolean} forceFetch
   * @return {Promise}
   */
  fetchUserBrandPermissions ({ commit, getters, state }, { brandId = state.activeBrandId, forceFetch = false } = {}) {
    if (!forceFetch && state.permissions[brandId]) return Promise.resolve()

    return UserApiService.fetchUserBrandPermissions(brandId).then((permissions) => {
      commit(STORE_USER_BRAND_PERMISSIONS, { permissions, brandId })
    })
  },

  async syncDownGlobalAuth ({ dispatch }) {
    try {
      const tokens = await CrossAuth.getAuthTokens()
      if (tokens) {
        return dispatch('storeAuthToken', tokens)
      } else {
        return dispatch('logout')
      }
    } catch (err) { }
  },

  async syncUpGlobalAuth ({ dispatch }, tokens) {
    try {
      await CrossAuth.setAuthTokens(tokens)
    } catch (err) {
      console.log(err)
    }
    return dispatch('storeAuthToken', tokens)
  },

  async logoutGlobally ({ dispatch }) {
    await CrossAuth.delAuthTokens().catch((error) => {
      // continue, no matter that crossAuth did not succeed
      console.log(error)
    })
    return dispatch('logout')
  },

  async fetchInvoices ({ getters, commit }, organisationId = getters.firstOrganisation.id) {
    commit('SET_FETCHING_INVOICES', true)
    try {
      const invoices = await BillingApiService.fetchInvoices(organisationId)
      commit('STORE_INVOICES', invoices)
    } finally {
      commit('SET_FETCHING_INVOICES', false)
    }
  },

  async updateBrandsOrder ({ commit }, brands) {
    await UserApiService.updateBrandsOrder({
      brands: brands.map(b => b.id)
    })
    commit(STORE_BRANDS, brands)
  },

  async updateUser ({ commit }, userForm) {
    const user = await UserApiService.updateUser(userForm, {
      params: {
        include: ['country.neighbouringCountries']
      }
    })
    commit(STORE_USER_DETAILS, user)
  },

  incPermissionLimits ({ dispatch, getters, state }, { permission, brand = state.activeBrandId }) {
    const value = getters.authorize(permission, brand).current + 1
    return dispatch('updatePermissionLimits', { permission, value, brand })
  },

  decPermissionLimits ({ dispatch, getters, state }, { permission, brand = state.activeBrandId }) {
    const value = getters.authorize(permission, brand).current - 1
    return dispatch('updatePermissionLimits', { permission, value, brand })
  },

  updatePermissionLimits ({ commit, getters }, { permission, value, brand }) {
    if (getters.isStaffMember) return
    if (!getters.userHasBrandPermission(permission, brand)) return
    commit('UPDATE_PERMISSION_LIMIT', { permission, value, brand })
  },

  /**
   * Impersonate as a new user.
   * Only accessible to Staff section members
   * @param accessToken
   * @return {Promise<void>}
   */
  async impersonateUser ({ dispatch, getters, commit }, accessToken) {
    commit('SET_INITIAL_LOADING', true, { root: true })
    let currentAuthToken = getters.authToken
    try {
      await dispatch('logout')
      commit('SET_TOKEN_BEFORE_IMPERSONATION', currentAuthToken)
      await dispatch('syncUpGlobalAuth', { accessToken })
      await dispatch('fetchUser', { forcePermissions: true })
    } catch (e) {
      console.log('Impersonation failed')
    } finally {
      commit('SET_INITIAL_LOADING', false, { root: true })
    }
  },

  async deImpersonateUser ({ commit, dispatch, state }) {
    commit('SET_INITIAL_LOADING', true, { root: true })
    const accessToken = state.token_before_impersonation
    try {
      await dispatch('logout')
      if (accessToken) {
        await dispatch('syncUpGlobalAuth', { accessToken })
        await dispatch('fetchUser', { forcePermissions: true })
      }
    } catch (error) {
      LocationService.reload()
    } finally {
      commit('SET_INITIAL_LOADING', false, { root: true })
      commit('SET_TOKEN_BEFORE_IMPERSONATION', '')
    }
  }
}

const mutations = {
  [LOGOUT] (state) {
    state.token = null
    state.brands = []
    state.user = userFactory()
  },

  [STORE_AUTH_TOKEN] (state, token) {
    state.token = token
  },

  [STORE_USER_DETAILS] (state, user) {
    state.user = user
  },

  [CLEAR_USER_ORGANISATION_PERMISSIONS] (state) {
    state.organisation_permissions = {}
  },

  [STORE_BRANDS] (state, brands) {
    state.brands = brands
  },

  [SET_ACTIVE_BRAND_ID] (state, id) {
    state.activeBrandId = id
    persist('active_brand', id)
  },

  [STORE_USER_ORGANISATIONS]: createMappedClassSetter('organisations', Organisation),

  [STORE_USER_BRAND_PERMISSIONS] (state, { permissions, brandId }) {
    state.permissions[brandId] = permissions
  },

  [STORE_USER_ORGANISATION_PERMISSIONS] (state, user) {
    state.organisation_permissions = user.organisationPermissions.data
  },

  [CLEAR_USER_BRAND_PERMISSIONS] (state) {
    state.permissions = {}
  },

  SET_TEMPORARY_USE_DEMO_DATA: createSetter('temporaryUseDemoData'),

  STORE_INVOICES: createMappedClassSetter('invoices', Invoice),

  SET_FETCHING_INVOICES: createSetter('isFetchingInvoices'),

  SET_TOKEN_BEFORE_IMPERSONATION (state, token) {
    state.token_before_impersonation = token
    persist('TOKEN_BEFORE_IMPERSONATION', token)
  },

  UPDATE_PERMISSION_LIMIT (state, { permission, value, brand }) {
    const permObj = state.permissions[brand].permissions[permission]
    if (permObj.max < value) return
    permObj.can = permObj.max !== value
    permObj.current = value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
