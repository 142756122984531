<template>
  <el-select
    v-model="computedValue"
    :popper-class="popperClass"
    :placeholder="placeholder"
    v-bind="$attrs"
    filterable
    @change="(v) => $emit('change',v)"
  >
    <el-option
      v-for="item in currencies"
      :key="item.code"
      :label="`${item.code} - ${item.name}`"
      :value="$attrs['value-key'] ? item : item.code"
    >
      <slot :currency="item" />
    </el-option>
  </el-select>
</template>

<script>
import BasicSelect from '@hypefactors/shared/js/components/core/BasicSelect.vue'
import { mapGetters } from 'vuex'

export default {
  extends: BasicSelect,
  props: {
    popperClass: {
      type: String,
      default: 'currencyModal'
    },
    placeholder: {
      type: String,
      default () { return this.$t('components.currency_picker.choose_your_currency') }
    }
  },
  computed: {
    ...mapGetters(['currencies'])
  }
}
</script>
