import Vue from 'vue'
import VuelidateErrorExtractor, { templates } from 'vuelidate-error-extractor'
import FormField from '@hypefactors/shared/js/components/forms/FormField'

Vue.use(VuelidateErrorExtractor, {
  i18n: 'validations',
  i18nAttributes: {
    __default: 'form_attributes',
    copytext: 'pages.story_edit.copytext',
    headline: 'pages.story_edit.headline',
    subheadline: 'pages.story_edit.subheadline',
    contact: 'pages.story_edit.contact_label',
    boilerplate: 'pages.story_edit.boilerplate',
    language: 'forms.language',
    email: 'forms.email',
    message: 'forms.message',
    subject: 'forms.subject',
    password: 'forms.password',
    first_name: 'forms.first_name',
    last_name: 'forms.last_name',
    organisation_name: 'forms.organisation_name',
    phone: 'general.phone_number',
    role: 'forms.role_position',
    countries: 'forms.countries',
    languages: 'forms.languages'
  }
})

Vue.component('BaseMultiError', templates.multiErrorExtractor.baseMultiErrorExtractor)
Vue.component(templates.FormWrapper.name, templates.FormWrapper)
Vue.component(FormField.name, FormField)
