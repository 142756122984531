<script>
export default {
  name: 'VButton',
  functional: true,
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'button',
      validation: v => ['button', 'a'].includes(v)
    }
  },
  render: (h, context) => h(context.props.tag,
    {
      ...context.data,
      class: [
        context.data.class,
        {
          button: true,
          'is-loading': context.props.loading
        }
      ]
    },
    context.children)
}
</script>
