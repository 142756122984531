import { StripeCard } from './StripeCard'
import { Subscription } from './Subscription'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import { croppieDataFactory } from '@hypefactors/shared/js/factories/croppie'
import { Coupon } from './Coupon'

/**
 * The Organisation model.
 * An organisation can have many Brands
 * Users can have many brands.
 * @alias HF_Organisation
 */
export class Organisation {
  constructor (org = {}) {
    /** @type {string} */
    this.id = org.id || ''
    /** @type {string} */
    this.slug = org.slug || ''
    /** @type {string} */
    this.name = org.name || ''
    /** @type {string} */
    this.vatNumber = org.vat_number || ''
    /** @type {string} */
    this.currencyCode = org.currency_code || ''
    /** @type {croppieData} */
    this.logo = org.logo || croppieDataFactory()
    /** @type {string|null} */
    this.createdAt = org.created_at || null
    /** @type {string|null} */
    this.updatedAt = org.updated_at || null
    /** @type {Object} */
    this.country = _get(org, 'country.data', null)
    /** @type {Number} */
    this.lockout_time = org.lockout_time || 0
    /** @type {HF_Subscription} [subscription] */
    const subscription = _get(org, 'subscription.data')
    if (subscription) {
      this.subscription = new Subscription(subscription)
    }

    const card = _get(org, 'card.data')
    this.card = !_isEmpty(card) ? new StripeCard(card) : null

    const coupon = _get(org, 'applicableDiscount.data', null)
    this.applicableDiscount = !_isEmpty(coupon) ? new Coupon(coupon) : null

    const accountManager = _get(org, 'accountManager.data', null)
    this.accountManager = !_isEmpty(accountManager) ? accountManager : null
  }
}
