import { createGetter, createSetter } from '@hypefactors/shared/js/utils/vuexUtilities'
import { LocationService } from '@hypefactors/shared/js/services/LocationService'
import { persist } from '@hypefactors/shared/js/utils'
import { IdleVisibiltyService } from '@hypefactors/shared/js/services/IdleVisibiltyService'
import MessageBox from 'element-ui/lib/message-box'

const COMMIT_HASH = process.env.VUE_APP_COMMIT_REF

export default function updateChecker ({ Api, Trans }) {
  const state = () => ({
    hash: '',
    isOutdated: false
  })

  const getters = {
    isOutdated: createGetter('isOutdated')
  }

  const mutations = {
    STORE_HASH: createSetter('hash'),
    SET_OUTDATED: createSetter('isOutdated', true)
  }

  const actions = {
    /**
     * Inits the idle detection.
     * Ran once just before the app is booted in `main.js`
     */
    init ({ commit, dispatch }) {
      // if the hash is not available, bail
      if (process.env.VUE_APP_ENV === 'development' || !COMMIT_HASH) return
      // store the hash at build time
      commit('STORE_HASH', COMMIT_HASH)

      // set the idle time to 2 hours
      IdleVisibiltyService.on('wakeup', () => {
        // go back updating data
        dispatch('checkFreshness')
      })
      // Check the freshness at start
      dispatch('checkFreshness')
    },
    /**
     * Fetches the current hash
     * @return {Promise<String>}
     */
    async fetchHash () {
      const { data } = await Api.get('/deployment.json', {
        params: { stamp: Date.now() },
        baseURL: null
      })
      return data
    },
    /**
     * Checks if the currently saved commit hash is outdated
     * @return {Promise<void>}
     */
    async checkFreshness ({ dispatch, state }) {
      try {
        const hash = await dispatch('fetchHash')
        if (hash && state.hash !== hash) {
          dispatch('handleOutdatedVersion')
        }
      } catch (err) {
        console.log(err)
      }
    },
    /**
     * Handles the app when it is outdated
     * @return {Promise<void>}
     */
    async handleOutdatedVersion ({ commit, state }) {
      // if we are already outdated, we dont need to ask the user again.
      if (state.isOutdated) return

      commit('SET_OUTDATED')
      // Alert the user to refresh immediately
      try {
        // If they click OK, refresh
        await MessageBox.confirm(Trans.t('warnings.new_version_available', { client: process.env.VUE_APP_NAME }))
        persist('FORCE_RELOAD', false)
        LocationService.reload()
      } catch (e) {
        console.log(e)
        // if they clicked cancel, we refresh on next page navigation
        persist('FORCE_RELOAD', true)
      }
    }
  }

  return {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
  }
}
