<template>
  <div
    :class="{ 'is-gapless is-marginless': gapless }"
    class="Heading columns"
  >
    <div class="column">
      <component
        :is="computedTag"
        :class="extraTagClasses"
        class="title"
      >
        <slot>[Heading]</slot>
      </component>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Heading',
  props: {
    size: {
      type: [Number, String],
      default: 3,
      validator: (val) => {
        const value = Number(val)
        return value >= 1 && value <= 8
      }
    },
    tagClass: {
      type: String,
      default: ''
    },
    serif: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'black'
    },
    gapless: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: ''
    },
    weight: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {
    extraTagClasses () {
      return [
        this.tagClass,
        `is-${this.size}`,
        this.color ? `has-text-${this.color}` : '',
        this.serif && 'has-text-serif',
        this.weight ? `has-text-weight-${this.weight}` : ''
      ]
    },
    computedTag () {
      return this.tag || 'h' + this.size
    }
  }
}
</script>
