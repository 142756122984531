<template>
  <div class="StripeCardDisplay card">
    <div class="card-content">
      <div class="is-flex border-b p-v-xs">
        <b class="is-expanded">{{ $t('components.billing.stripe_card_display.card_holder') }}:</b> {{ card.name }}
      </div>
      <div class="is-flex border-b p-v-xs">
        <b class="is-expanded">{{ $t('components.billing.stripe_card_display.last_digits') }}:</b> {{ card.last4 }}
      </div>
      <div class="is-flex border-b p-v-xs">
        <b class="is-expanded">{{ $t('components.billing.stripe_card_display.expiration') }}:</b> {{ card.expMonth }} / {{ card.expYear }}
      </div>
      <div class="is-flex border-b p-v-xs">
        <b class="is-expanded">{{ $t('components.billing.stripe_card_display.type') }}:</b> {{ card.brand }}
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @module StripeCardDisplay
 */
export default {
  name: 'StripeCardDisplay',
  props: {
    /**
     * @type HF_StripeCard
     */
    card: {
      type: Object,
      required: true
    }
  }
}
</script>
