import Vue from 'vue'

/**
 * Renderless component that loads a layout component and emits the appropriate event, for the App.vue to render it.
 * @module Layout
 * @see module:App to see how it is implemented.
 * @see "/layouts" for all possible layouts to load
 */
export default {
  name: 'Layout',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  created () {
    // Check if the layout component
    // has already been registered.
    if (!Vue.options.components[this.name]) {
      Vue.component(
        this.name,
        () => import(`@/router/layouts/${this.name}.vue`)
      )
    }

    this.$parent.$emit('update:layout', this.name)
  },
  render () {
    return this.$slots.default[0]
  }
}
