<template>
  <el-select
    v-model="computedValue"
    :filter-method="filterMethod"
    :popper-class="popperClass"
    :placeholder="placeholder"
    v-bind="$attrs"
    filterable
    @change="(v) => $emit('change',v)"
    @visible-change="clearOnClose"
  >
    <el-option
      v-for="item in filteredCountries"
      :key="item.id"
      :label="item.name"
      :data-test-key="$attrs['value-key'] ? item['value-key'] : item[valueProp]"
      :value="$attrs['value-key'] ? item : item[valueProp]"
    >
      <slot :country="item">
        <country-flag :country="item.iso_alpha2" />
        {{ item.name }}
      </slot>
    </el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BasicSelect from '@hypefactors/shared/js/components/core/BasicSelect.vue'
import { generateUniqueElementSelectList } from '../../utils/componentUtilities'

export default {
  name: 'CountryPicker',

  extends: BasicSelect,

  props: {
    popperClass: {
      type: String,
      default: 'countryModal'
    },
    placeholder: {
      type: String,
      default () { return this.$t('components.country_picker.choose_your_country') }
    },
    allowed: {
      type: Array,
      default: () => []
    },
    exclude: {
      type: Array,
      default: () => []
    },
    include: {
      type: Array,
      default: () => []
    },
    valueProp: {
      type: String,
      default: 'iso_alpha2'
    }
  },

  data () {
    return {
      query: ''
    }
  },

  computed: {
    ...mapGetters(['countries']),

    /**
     * Returns A filtered list of Countries
     * that are not in the excluded list.
     * @returns {Array}
     */
    listOfCountries () {
      const vkey = this.$attrs['value-key']
      const shouldIncludeElements = this.$options.propsData.hasOwnProperty('include')
      const filterBy = shouldIncludeElements ? this.include : this.exclude
      return generateUniqueElementSelectList(this.countries, filterBy, vkey, 'iso_alpha2', shouldIncludeElements)
    },

    /**
     * Filters the not excluded countries by the current query search parameter in the select
     * @returns {Array}
     */
    filteredCountries () {
      let countries = this.listOfCountries

      if (this.allowed.length > 0) {
        // Mapping the items to uppercase, since they can be passed as lowercase...
        const allowed = this.allowed.map(item => item.toUpperCase())

        countries = countries.filter((country) => allowed.includes(country.iso_alpha2))
      }

      if (this.query) {
        countries = countries.filter((country) => country.compoundIndex.includes(this.query))
      }

      return countries
    }
  },

  mounted () {
    this.fetchCountries()
  },

  methods: {
    ...mapActions(['fetchCountries']),
    filterMethod (data) {
      this.query = data.toLowerCase().trim()
    },
    async clearOnClose (isVisible) {
      if (isVisible) return
      this.query = ''
    }
  }
}
</script>
