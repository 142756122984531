const state = {
  social_networks: {}
}

const getters = {
  userSocialNetworks (state) {
    return state.social_networks
  },

  activeBrandSocialNetworks (state, getters, rootState, rootGetters) {
    return state.social_networks[rootGetters.activeBrandId]
  },

  getSocialProviderData: (state, getters) => (provider) => {
    const data = getters.activeBrandSocialNetworks[provider]
    return (data && data.length) ? data[0] : false
  },

  isSocialConnectedToBrand: (state, getters) => (provider) => {
    const network = getters.getSocialProviderData(provider)
    return !!network
  }
}

const mutations = {

  SET_SOCIAL_NETWORKS (state, brands) {
    state.social_networks = brands.reduce((all, brand) => {
      all[brand.id] = brand.social_networks.data
      return all
    }, {})
  },

  UPDATE_SOCIAL_NETWORKS (state, { brandId, data }) {
    state.social_networks[brandId] = data
  },

  CLEAR_SOCIAL_NETWORKS (state) {
    state.social_networks = {}
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
