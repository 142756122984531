<template>
  <div class="ResponsiveTable" :class="{ 'ResponsiveTable--active': isApplicable, 'ResponsiveTable--expanded': expanded }">
    <div ref="table" class="responsive-table">
      <slot />
      <ResizeObserver @notify="handleResize" />
    </div>
    <VButton
      v-if="isApplicable"
      class="ResponsiveTable--button is-fullwidth is-grey is-plain is-small is-hidden-tablet"
      @click="expanded = !expanded"
    >
      <div class="icon">
        <VIcon :type="!expanded ? 'caret-down': 'caret-up'" size="2x" />
      </div>
      <span>Expand</span>
    </VButton>
  </div>
</template>

<script>
import { ResizeObserver } from 'vue-resize'
import 'vue-resize/dist/vue-resize.css'

import VButton from '@hypefactors/shared/js/components/core/VButton'
import VIcon from '@hypefactors/shared/js/components/core/VIcon'

import { debounceMixin } from '../../mixins/debounceMixin'

/**
 * @module ResponsiveTable
 */
export default {
  name: 'ResponsiveTable',
  components: {
    ResizeObserver,
    VButton,
    VIcon
  },
  mixins: [debounceMixin(['handleResize'])],
  data () {
    return {
      expanded: false,
      isApplicable: false
    }
  },
  methods: {
    handleResize () {
      const height = this.$safeGet(this.$refs, 'table.children.0.clientHeight', 0)
      this.isApplicable = height > 150
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

@include mobile {
  .ResponsiveTable {
    position: relative;
  }
  .ResponsiveTable--active {
    .responsive-table {
      max-height: 150px;
    }

    &.ResponsiveTable--expanded .responsive-table {
      max-height: 1000px;
    }
  }
  .ResponsiveTable--button {
    position: absolute !important;
    bottom: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 65%, rgba(255, 255, 255, 0) 100%) !important;

    .ResponsiveTable--expanded & {
      bottom: -5px;
    }
  }
}
</style>
