import EventEmitter from 'events'

import Api from '@/services/Api'

class ApiStatusChecker extends EventEmitter {
  constructor () {
    super()

    this.currentStatus = 200

    this.pollTime = 5000
  }

  async start () {
    await this.checkStatus()

    return this
  }

  async checkStatus () {
    let statusCode = 200
    let statusMessage = null

    await Api.get('/health').then(response => {
      statusCode = response.status
    }).catch(error => {
      if (error.response) {
        statusCode = error.response.status
        statusMessage = error.response.data.message
      }
    })

    this.emit('status', this.currentStatus, statusCode, statusMessage)

    this.currentStatus = statusCode

    setTimeout(() => {
      this.checkStatus()
    }, this.pollTime)
  }
}

export default new ApiStatusChecker()
