<template>
  <el-tooltip
    :content="brand.name"
    class="item"
    effect="dark"
    placement="right"
  >
    <div
      :class="{ 'is-active': isActive, 'is-loading': isLoading, disabled }"
      class="Brands-sidebar__item"
      data-testid="BrandChangeTrigger"
      @click="select"
    >
      <round-avatar
        :src="brand.logo.cropped || brand.logo.original"
        :acronym-fallback="brand.name"
        size="small"
      />
    </div>
  </el-tooltip>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    brand: {
      type: Object,
      required: true
    },
    loading: {
      type: [Boolean, String],
      default: false
    }
  },

  computed: {
    isActive () {
      return this.brand.id === this.activeBrandId
    },
    disabled () {
      return this.loading && this.loading !== this.brand.id
    },
    isLoading: {
      get () {
        return this.loading === this.brand.id
      },
      set (value) {
        this.$emit('update:loading', value)
      }
    }
  },

  methods: {
    ...mapActions(['setActiveBrand']),

    async select () {
      if (this.loading) return
      this.isLoading = this.brand.id
      try {
        await this.setActiveBrand(this.brand.id)
        this.isLoading = false
      } catch (err) {
        this.isLoading = false
        this.$displayRequestError(err)
      }
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.Brands-sidebar__item {
  margin: $margin-small;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .Avatar {
    border: 3px solid transparent;
    transition: border .15s ease-in;
  }

  &:hover {
    cursor: pointer;

    .Avatar {
      border-color: $hf__color-primary !important;
    }
  }

  &.is-active {
    position: relative;

    &:after {
      content: $hf-caret-left;
      font-family: Hypefactors-Icon-Font;
      position: absolute;
      right: 0;
      top: 50%;
      color: $hf__color-white;
      transform: translateY(-50%);
      font-size: $size-3;
    }

    .Avatar {
      border: 3px solid $hf__color-white;
      background-color: $hf__color-primary--alternative !important;
    }
  }

  &.is-loading .Avatar {
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      border: 3px solid $white;
      border-top-color: $hf__color-primary;
      border-radius: 100%;
      animation: spinAround 1.5s linear infinite;
    }
  }

  &.disabled {
    opacity: .3;
    cursor: not-allowed;
  }

  @include tablet {
    margin: $margin auto;
  }
}
</style>
