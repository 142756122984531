/**
 * @typedef {{points: Array, zoom: number|null}} croppedInformation
 */

/**
 * @typedef {{original: string, cropped: string, cropped_information: croppedInformation}} croppieData
 */

/**
 * Returns a croppie data object
 * @returns {croppieData}
 */
export function croppieDataFactory () {
  return {
    original: '',
    cropped: '',
    cropped_information: {
      orientation: 1,
      points: [],
      zoom: null
    }
  }
}

export function croppieDefaultSettings ($width = 90, $height = 90, $type = 'circle') {
  return {
    viewport: { width: $width, height: $height, type: $type },
    boundary: { width: $width, height: $height },
    cropOptions: {
      size: { width: $width * 2, height: $height * 2 }
    }
  }
}

/**
 * Merges the provided Croppie data with a set of defaults
 * @param destination
 * @param source
 * @returns {croppieData}
 */
export function mergeCroppieData (destination, source = {}) {
  return Object.assign(source, croppieDataFactory(), destination)
}
