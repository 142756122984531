/**
 * Shades a hex color
 * @param {String} col - color to share
 * @param {Number} amt - amount to shade. Negative makes it darker
 * @return {string}
 */
export function shadeColor (hex, lum) {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '')
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  lum = lum || 0

  // convert to decimal and change luminosity
  var rgb = '#'
  var c
  var i
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16)
    c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16)
    rgb += ('00' + c).substr(c.length)
  }

  return rgb
}

/**
 * Detect if the supplied color is dark
 * @param {String} bgColor - Color to test
 * @return {Boolean}
 */
export function isColorDark (bgColor) {
  if (!bgColor) return false
  let colorNoHash = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor
  const color = (colorNoHash.length === 3 && (colorNoHash.charAt(0) === colorNoHash.charAt(1) && colorNoHash.charAt(1) === colorNoHash.charAt(2))) ? colorNoHash + colorNoHash : colorNoHash
  const r = parseInt(color.substring(0, 2), 16) // hexToR
  const g = parseInt(color.substring(2, 4), 16) // hexToG
  const b = parseInt(color.substring(4, 6), 16) // hexToB
  return (((r * 0.299) + (g * 0.587) + (b * 0.114)) < 186)
}
