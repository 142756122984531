import store from '@/store'
import router from '@/router'
import { IdleVisibiltyService } from '@hypefactors/shared/js/services/IdleVisibiltyService'
import { watch } from '@vue/composition-api'

export const LockScreen = {
  /**
   * Returns the lockout time for organisation and user
   * @return {number}
   */
  lockoutTime () {
    // the time is in minutes, so we need to convert to seconds
    return (store.getters.firstOrganisation.lockout_time || store.getters.currentUser.lockout_time) * 60
  },
  /**
   * Initializes the lock screen.
   * When the app goes idle, it locks the screen.
   * On each change of the lockTime, we update the detection time.
   */
  init () {
    IdleVisibiltyService.on('idle', LockScreen.redirectToLockScreen)
    watch(LockScreen.lockoutTime, LockScreen.setIdleDetectionToLockoutTime)
  },
  /**
   * Redirect to the lockout page
   */
  redirectToLockScreen () {
    if (LockScreen.lockoutTime() && store.getters.signedIn && IdleVisibiltyService.isIdle()) {
      router.push({ name: 'lock-out', query: { redirect: router.currentRoute.fullPath } })
    }
  },
  /**
   * Set the idle duration to be the same as the lockout time
   */
  setIdleDetectionToLockoutTime () {
    IdleVisibiltyService.setDuration(LockScreen.lockoutTime())
  }
}
