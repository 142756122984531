<template>
  <div
    class="StripeCardForm"
    data-testid="StripeCardForm"
  >
    <horizontal-field>
      <template slot="label">
        {{ $t('forms.credit_card') }}
      </template>
      <form-field
        :attribute="$t('forms.credit_card')"
        :server-errors="stripeErrors"
        required
      >
        <stripe-card-element
          ref="StripeCardElement"
          class="credit-card"
          data-testid="StripeCardForm__CardElement"
          @error="stripeErrors = $event"
        />
      </form-field>
    </horizontal-field>
    <horizontal-field>
      <template slot="label">
        {{ $t('general.name') }}
      </template>
      <form-field
        :validator="$v.form.name"
        :attribute="$t('general.name')"
      >
        <input
          v-model="form.name"
          :placeholder="$t('general.name')"
          class="input"
          type="text"
          name="name"
          data-testid="StripeCardForm__Name"
          @input="$v.form.name.$touch()"
        >
      </form-field>
    </horizontal-field>
    <horizontal-field>
      <template slot="label">
        {{ $t('general.country') }}
      </template>
      <form-field
        :validator="$v.form.address.country"
        :attribute="$t('general.country')"
        class="is-width-1/2-desktop is-narrow"
      >
        <country-picker
          v-model="form.address.country"
          class="StripeCardCountryPicker"
          data-testid="StripeCardForm__Country"
          @change="$v.form.address.country.$touch"
        />
      </form-field>
    </horizontal-field>
    <horizontal-field v-if="form.address.country === 'US'">
      <template slot="label">
        {{ $t('forms.state_province') }}
      </template>
      <form-field
        :validator="$v.form.address.state"
        :attribute="$t('forms.state_province')"
      >
        <input
          v-model="form.address.state"
          :placeholder="$t('forms.state_province')"
          class="input"
          type="text"
          name="state"
          data-testid="StripeCardForm__StateProvince"
          @input="$v.form.address.state.$touch()"
        >
      </form-field>
    </horizontal-field>
    <horizontal-field>
      <template slot="label">
        {{ $t('forms.address_1') }}
      </template>
      <form-field
        :validator="$v.form.address.line1"
        :attribute="$t('forms.address_1')"
      >
        <input
          v-model="form.address.line1"
          :placeholder="$t('forms.address_1')"
          class="input"
          type="text"
          name="line1"
          data-testid="StripeCardForm__AddressLine1"
          @input="$v.form.address.line1.$touch()"
        >
      </form-field>
    </horizontal-field>
    <horizontal-field>
      <template slot="label">
        {{ $t('forms.address_2') }}
      </template>
      <form-field
        :validator="$v.form.address.line2"
        :attribute="$t('forms.address_2')"
      >
        <input
          v-model="form.address.line2"
          :placeholder="$t('forms.address_2')"
          class="input"
          type="text"
          name="line2"
          data-testid="StripeCardForm__AddressLine2"
          @input="$v.form.address.line2.$touch()"
        >
      </form-field>
    </horizontal-field>
    <horizontal-field>
      <template slot="label">
        {{ $t('forms.city') }}
      </template>
      <form-field
        :validator="$v.form.address.city"
        :attribute="$t('forms.city')"
      >
        <input
          v-model="form.address.city"
          :placeholder="$t('forms.city')"
          class="input"
          type="text"
          name="city"
          data-testid="StripeCardForm__City"
          @input="$v.form.address.city.$touch()"
        >
      </form-field>
    </horizontal-field>
    <horizontal-field>
      <template slot="label">
        {{ $t('forms.zip') }}
      </template>
      <form-field
        :validator="$v.form.address.postal_code"
        :attribute="$t('forms.zip')"
      >
        <input
          v-model="form.address.postal_code"
          :placeholder="$t('forms.zip')"
          class="input"
          type="text"
          name="postal_code"
          data-testid="StripeCardForm__Zip"
          @input="$v.form.address.postal_code.$touch()"
        >
      </form-field>
    </horizontal-field>
  </div>
</template>

<script>
import StripeCardElement from '@/components/billing/StripeCardElement'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { StripeCard } from '@hypefactors/shared/js/models/StripeCard'

/**
 * Shows a form with Extra Card fields and the StripeCardElement it self.
 * @module StripeCardForm
 */
export default {
  name: 'StripeCardForm',

  components: { StripeCardElement },

  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    /** @type HF_StripeCard */
    card: {
      type: StripeCard,
      default: null
    }
  },

  data () {
    return {
      form: {
        name: '',
        address: {
          city: '',
          country: '',
          line1: '',
          line2: '',
          postal_code: '',
          state: ''
        }
      },
      stripeErrors: ''
    }
  },

  mounted () {
    if (this.card) {
      this.form.name = this.card.name
      this.form.address = {
        ...this.form.address,
        ...this.card.address
      }
    }
    if (!this.form.name) this.form.name = this.user.full_name
    if (!this.form.address.country) this.form.address.country = this.$safeGet(this.user, 'country.data.iso_alpha2')
  },

  validations () {
    return {
      form: {
        name: { required },
        address: {
          city: { required },
          country: { required },
          line1: { required },
          line2: {},
          postal_code: { required },
          state: {
            required: requiredIf(nestedModel => nestedModel.country === 'US')
          }
        }
      }
    }
  },

  methods: {
    /**
     * Fetches a Token from the {@see module:StripeCardElement}.
     * If errors are returned, set them as manual errors that are later displayed on the FormField wrapping the StripeCardElement.
     */
    async getToken () {
      this.stripeErrors = ''
      this.$v.form.$touch()

      if (this.$v.form.$error) return { error: true }

      const response = await this.$refs.StripeCardElement.getToken(this.form)

      // store the errors to display them later
      if (response.error) {
        this.stripeErrors = response.error.message
      }

      return response
    }
  }
}
</script>
