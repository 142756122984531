export const LOGOUT = 'LOGOUT'
export const SET_URLS = 'SET_URLS'
export const STORE_AUTH_TOKEN = 'STORE_AUTH_TOKEN'
export const STORE_USER_DETAILS = 'STORE_USER_DETAILS'
export const STORE_USER_ORGANISATIONS = 'STORE_USER_ORGANISATIONS'
export const STORE_BRANDS = 'STORE_BRANDS'
export const STORE_USER_BRAND_PERMISSIONS = 'STORE_USER_BRAND_PERMISSIONS'
export const STORE_USER_ORGANISATION_PERMISSIONS = 'STORE_USER_ORGANISATION_PERMISSIONS'
export const CLEAR_USER_BRAND_PERMISSIONS = 'CLEAR_USER_BRAND_PERMISSIONS'
export const CLEAR_USER_ORGANISATION_PERMISSIONS = 'CLEAR_USER_ORGANISATION_PERMISSIONS'
export const SET_ACTIVE_BRAND_ID = 'SET_ACTIVE_BRAND_ID'
export const ADD_FILTER = 'ADD_FILTER'
export const SET_FILTER = 'SET_FILTER'
export const REMOVE_FILTER = 'REMOVE_FILTER'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'
