export const blue = '#2BA7B4'
export const charcoal = '#4F4F4F'
export const grey = '#A9AEAF'
export const brick = '#AB7974'
export const salmon = '#EE6557'
export const green = '#00b67a'

export const lightBlue = '#75ebeb'
export const lightCharcoal = '#a6a4a5'
export const lightGrey = '#d1d7d8'
export const lightBrick = '#eba8a3'
export const lightSalmon = '#d28f91'
export const lightGreen = '#90c48e'

export const lighterColors = [
  lightBlue, lightCharcoal, lightGrey, lightBrick, lightSalmon, lightGreen
]

export const defaultColors = [
  blue, charcoal, grey, brick, salmon, green, // original
  ...lighterColors // 10% lighter
]

export const lightSocialColors = [
  lightSalmon, lightBlue, lightGrey, lightBrick, lightCharcoal
]
export const defaultSocialColors = [
  salmon, blue, grey, brick, charcoal,
  ...lightSocialColors
]
