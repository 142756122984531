/**
 * This module is used share an Api instance,
 * across shared Api Services
 */

let api

const setApi = (apiInstance) => {
  api = apiInstance
}

export {
  api,
  setApi
}
