import { api } from './ApiInstanceInjector'

let activeBrandId = () => ('')
let LambdaApi

export const StoryApiService = {
  setDependencies (options = {}) {
    LambdaApi = options.LambdaApi
    activeBrandId = options.getActiveBrandId || (() => (''))
  },

  /**
   * Fetch all stories of current brand
   * @param brandId
   * @param params
   */
  fetchStories (params = {}, brandId = activeBrandId()) {
    return api.get('stories', { params: { brands: brandId }, ...params })
  },

  /**
   * Fetch story from API
   * @param {String} id - Story ID
   * @param {Object} params - additional params
   * @return {AxiosPromise}
   */
  fetchStory (id, params = {}) {
    return api.getData(`stories/${id}`, params)
  },

  deleteStory (storyId) {
    return api.delete(`stories/${storyId}`)
  },

  createStory (payload, params) {
    return api.postData('stories', payload, params)
  },

  updateStory (storyId, payload, options) {
    return api.patchData(`stories/${storyId}`, payload, options)
  },

  /**
   * Publishes the story
   * @param {String} id - Story ID
   * @param {Object} params - Additional params to pass
   * @param {!String} date
   * @return {Promise<any>}
   return api.postData(`stories/${id}/unpublish`, {}, { params })
   */
  publishStory (id, date, params = { include: ['categories', 'countries', 'tags'] }) {
    return api.postData(`stories/${id}/publish`, { date }, { params })
  },

  /**
   * Unpublish a story
   * @param {String} id - Story ID
   * @param {Object} params - Additional params to pass
   * @return {AxiosPromise}
   */
  unpublishStory (id, params = { include: ['categories', 'countries', 'tags'] }) {
    return api.postData(`stories/${id}/unpublish`, {}, { params })
  },

  createContact (payload) {
    return api.postData('contacts', payload)
  },

  updateContact (contactId, payload) {
    return api.putData(`contacts/${contactId}`, payload)
  },

  fetchRelatedStories (storyId, params) {
    return api.getData(`/stories/${storyId}/related`, params)
  },

  fetchBrandContacts (brandId = activeBrandId()) {
    return api.getData(`brands/${brandId}/contacts`)
  },

  fetchBrandFiles (params, brandId = activeBrandId()) {
    return api.getData(`brands/${brandId}/files`, { params: { sort: 'newest' }, ...params })
  },

  /**
   * Fetches suggested tags for this brand
   * @param {number} brandId
   */
  fetchSuggestedTags (brandId = activeBrandId()) {
    return api.getData(`brands/${brandId}/suggested-tags`)
  },

  /**
   * Detects the language of a story.
   * Calls a lambda function
   * @param {String} text
   * @returns {Promise<!String>}
   */
  detectLanguage (text) {
    return LambdaApi.post('/detect-language', { text })
      .then(({ data }) => {
        return data.languages ? data.languages[0].code : null
      })
  }
}
