import _uniq from 'lodash/uniq'
import _flatMap from 'lodash/flatMap'

/**
 * @typedef {{id: number, depth: number, name: string, parent_id: number}} HF_Category
 */

/**
 * @typedef {{id: number, path: HF_Category[]}} HF_CategoryPath
 */

/**
 * Flat Array of categories
 * @type {HF_Category[]}
 */
let flatCategories = []

/**
 * Sets a reference to the the Flat categories
 * @param {HF_Category[]} categories
 * @private
 */
function _setFlatCategories (categories) {
  flatCategories = categories
}

/**
 * Picks out only the top level (2) categories and returns an array of HF_CategoryPath
 * @param {HF_Category[]} categories
 * @returns {HF_CategoryPath[]}
 */
function transformForEditing (categories) {
  return categories.filter(cat => cat.depth === 2)
    .map(({ id }) => getCategoryWithPath(id))
}

/**
 * Returns a top level Category's id and its path (array of parents)
 * @param id
 * @returns {HF_CategoryPath}
 */
function getCategoryWithPath (id) {
  const path = _getCategoryPath(flatCategories, id).reverse()
  return { id, path }
}

/**
 * Returns the categories by path
 * @param {HF_Category[]} categoriesFlat
 * @param {number} id
 * @param {array} [data]
 * @return {HF_Category[]}
 */
function _getCategoryPath (categoriesFlat, id, data) {
  data = data || []

  const node = categoriesFlat.find(item => item.id === id)

  if (node) {
    data.push(node)

    const parent = categoriesFlat.find((item) => item.id === node.parent_id)

    if (parent) {
      return _getCategoryPath(categoriesFlat, parent.id, data)
    }

    return data
  }

  return data
}

/**
 * Transforms a set of category paths to a flat array of unique id's.
 * @param {HF_CategoryPath[]} categories
 * @returns {number[]}
 */
function transformForSaving (categories) {
  return _uniq(_flatMap(categories,
    (category) =>
      category.path.map(cat => cat.id)))
}

export default {
  _setFlatCategories,
  transformForEditing,
  getCategoryWithPath,
  transformForSaving
}
