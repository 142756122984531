import { Coupon } from '@hypefactors/shared/js/models/Coupon'

export default {
  name: 'CouponDataProvider',
  props: {
    value: {
      type: Number,
      required: true
    },
    coupon: {
      type: Coupon,
      required: true
    }
  },
  computed: {
    isPercentage () {
      return this.coupon.type === 'percentage'
    },
    discount () {
      if (this.isPercentage) {
        return this.value - (this.value * this.coupon.value)
      }
      return this.coupon.value
    },
    percentage () {
      if (this.isPercentage) return this.coupon.value
      return this.coupon.value / this.value
    }
  },
  render (h) {
    return h('div', [this.$scopedSlots.default({
      currency: this.coupon.currency,
      isPercentage: this.isPercentage,
      value: this.coupon.value,
      discount: this.discount,
      id: this.coupon.id,
      percentage: this.percentage
    })])
  }
}
