import _isEmpty from 'lodash/isEmpty'
import _pickBy from 'lodash/pickBy'

/**
 * Transforms an object to a query param string
 * @param {Object} obj
 * @param {String} [url]
 * @returns {*}
 */
export function objectToQuerystring (obj, url = '') {
  const hasParams = url.trim().includes('?')
  return Object.entries(obj)
    .filter(([key, value]) => isTruthyQueryParameter(value))
    .reduce((queryString, [key, value], i) => {
      let delimiter
      let val
      delimiter = (i === 0 && !hasParams) ? '?' : '&'
      if (Array.isArray(value)) {
        key = encodeURIComponent(key)
        if (!key.includes('[')) key += '[]'
        const arrayVar = value
          .filter(v => isTruthyQueryParameter(v))
          .reduce((str, item) => {
            val = encodeURIComponent(item)
            return [str, key, '=', val, '&'].join('')
          }, '')
        return [queryString, delimiter, arrayVar.trim().slice(0, -1)].join('')
      } else {
        key = encodeURIComponent(key)
        val = encodeURIComponent(value)
        return [queryString, delimiter, key, '=', val].join('')
      }
    }, '')
}

/**
 * Builds a url with provided params object
 * @param {String} url
 * @param {Object} paramsObject
 * @returns {String}
 */
export function buildUrlWithParams (url, paramsObject) {
  return url + objectToQuerystring(paramsObject, url)
}

export function queryObjectToRouterQuery (params) {
  return Object.entries(params)
    .filter(([key, value]) => Boolean(value))
    .reduce((all, [key, value]) => {
      if (Array.isArray(value)) {
        key = key.includes('[') ? key : `${key}[]`
      }
      all[key] = value
      return all
    }, {})
}

export function removeSquareBrackets (paramsObject) {
  return Object.entries(paramsObject).reduce((all, [id, value]) => {
    all[id.replace('[]', '')] = value
    return all
  }, {})
}

export function removeFalsyValuesFormObject (paramsObject) {
  if (typeof paramsObject !== 'object') return Boolean(paramsObject)

  return Array.isArray(paramsObject)
    ? paramsObject.filter(removeFalsyValuesFormObject)
    : _pickBy(paramsObject, hasAnyValue)
}

const hasAnyValue = (value) => typeof value === 'object' ? !_isEmpty(value) : isTruthyQueryParameter(value)
const isTruthyQueryParameter = (value) => (!!value || value === 0)
