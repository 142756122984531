<template>
  <div class="StripeCardManager">
    <stripe-card-display
      v-if="!isCardFormVisible"
      :card="card"
      :class="displayClasses"
    />
    <stripe-card-form
      v-else
      ref="stripeCardForm"
      :user="user"
      :card="replacingCardLocal ? card : null"
    />

    <div class="actions m-t-m">
      <v-button
        v-if="isCardFormVisible && hasActiveCard"
        key="cancel"
        type="button"
        class="is-plain is-dark"
        @click="replacingCardLocal = false"
      >
        {{ $t('components.billing.stripe_card_manager.cancel_card_replace') }}
      </v-button>
      <v-button
        v-if="!isCardFormVisible"
        key="replace"
        class="m-t-m"
        type="button"
        @click="replacingCardLocal = true"
      >
        {{ $t(`components.billing.stripe_card_manager.replace_credit_card`) }}
      </v-button>
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
import StripeCardDisplay from '@/components/billing/StripeCardDisplay'
import StripeCardForm from '@/components/billing/StripeCardForm'
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'

/**
 * Stripe card manager. Allows switching between the stripe card and a form to add a new card.
 * @module StripeCardManager
 */
export default {
  name: 'StripeCardManager',
  components: { StripeCardForm, StripeCardDisplay },
  props: {
    isReplacingCard: {
      type: Boolean,
      required: true
    },
    /**
     * @type {HF_StripeCard}
     */
    card: {
      type: Object,
      default: null
    },
    /**
     * Extra classes to apply to the DisplayCard component
     * @type {String}
     */
    displayClasses: {
      type: String,
      default: ''
    },
    user: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    replacingCardLocal: generateComputedSetterWithEmit('isReplacingCard', 'update:isReplacingCard'),
    isCardFormVisible () {
      return !this.hasActiveCard || this.replacingCardLocal
    },
    hasActiveCard () {
      return !!this.card
    }
  },
  methods: {
    /**
     * Fetches the Token from the {@see module:StripeCardForm.methods.getToken}
     * @returns {StripeToken}
     */
    getToken () {
      if (!this.isCardFormVisible) return { error: true }
      return this.$refs.stripeCardForm.getToken()
    }
  }
}
</script>
