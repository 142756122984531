<template>
  <div
    class="BackgroundImage"
    :class="{ 'is-cover-image': cover }"
    :style="{ backgroundImage: image }"
  >
    <slot />
  </div>
</template>

<script>
/**
 * @module BackgroundImage
 */
export default {
  name: 'BackgroundImage',
  props: {
    shadow: {
      type: Boolean,
      default: false
    },
    src: {
      type: String,
      default: ''
    },
    cover: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    image () {
      const shadow = this.shadow
        ? ['linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(255, 255, 255, 0) 150%)']
        : []
      return shadow.concat([`url("${this.src}")`]).join(', ')
    }
  }
}
</script>
