import startOfToday from 'date-fns/start_of_today'
import endOfToday from 'date-fns/end_of_today'
import subDays from 'date-fns/sub_days'
import startOfMonth from 'date-fns/start_of_month'
import startOfYear from 'date-fns/start_of_year'

export function getTimestampFromPredefinedSlug (day) {
  let date = startOfToday()
  const dateMap = {
    end_of_today: endOfToday(),
    today: startOfToday(),
    two_weeks: subDays(startOfToday(), 14),
    month: startOfMonth(new Date()),
    year: startOfYear(new Date())
  }
  if (day && dateMap[day]) {
    date = dateMap[day]
  }
  return date
}
