import Vue from 'vue'
import VueI18n from 'vue-i18n'

import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE } from '@hypefactors/shared/js/constants/trans'
import { VueI18nFixer } from '@hypefactors/shared/js/plugins/VueI18nFixer'

import en from '@/lang/en'

Vue.use(VueI18n)
Vue.use(VueI18nFixer)

export const i18n = new VueI18n({
  locale: DEFAULT_LANGUAGE, // set locale
  fallbackLocale: FALLBACK_LANGUAGE,
  messages: { en }, // set locale messages
  silentTranslationWarn: true
})
