<template>
  <div class="NavigationMegaMenu">
    <h4 class="title is-4 has-text-grey-dark">
      {{ links.title }}
    </h4>
    <div class="columns">
      <div
        v-for="megamenuColumn in links.children"
        :key="megamenuColumn.title"
        class="column NavigationMegaMenu-Column"
      >
        <div class="NavigationMegaMenu-Column__title m-b-m">
          {{ megamenuColumn.title }}
        </div>
        <div class="NavigationMegaMenu-Rows">
          <div
            v-for="megamenuRow in megamenuColumn.children"
            :key="megamenuRow.label"
            class="NavigationMegaMenu-Row m-b-m"
          >
            <router-link
              :to="megamenuRow.to"
              class="NavigationMegaMenu-Row__link"
              @click.native="$emit('link-selected')"
            >
              <i class="hf hf-circle m-r-s bullet" /> {{ megamenuRow.label }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationMegaMenu',
  props: {
    links: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang='scss'>
@import "~utils";

.NavigationMegaMenu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1001;
  width: 100%;
  height: auto;
  padding: $padding-medium;
  padding-bottom: $padding-large*1.2;
  background: $grey-lighter;

  a {
    color: $grey-dark;
  }

  .bullet {
    margin-left: $margin-small;
    font-size: .45em;
    opacity: .5;
    vertical-align: middle;
  }

  .NavigationMegaMenu-Column__title {
    font-size: 1.15rem;
    font-weight: bold;
    color: $primary;
    text-transform: uppercase;
    margin-bottom: $margin;
  }

  .NavigationMegaMenu-Row__link:hover {
    color: $primary;
  }

  .router-link-exact-active {
    color: $hf__color-primary--alternative;
  }
}
</style>
