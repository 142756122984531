import api from '@/services/Api'

export const PREPARE_CLIPPING_URL = 'dataprep/clippings'
export const RESTFUL_CLIPPING_URL = id => `clippings/${id}`

export const ClippingsApi = {
  fetchClipping (id, params) {
    return api.getData(RESTFUL_CLIPPING_URL(id), params)
  },
  deleteClipping (id) {
    return api.delete(RESTFUL_CLIPPING_URL(id))
  },
  bulkDelete (brandId, clippings) {
    return api.post(`/brands/${brandId}/clippings/bulk-delete`, { clippings })
  },
  exportClipping (id) {
    // these params are needed for downloading the exported clipping
    const params = { responseType: 'arraybuffer' }
    return api.get(RESTFUL_CLIPPING_URL(id) + '/pdf', params)
  },
  exportClippings (payload) {
    return api.post('clippings/export', payload)
  },
  bulkUpdateTags (brandId, payload) {
    return api.post(`/brands/${brandId}/clippings/bulk-update-tags`, payload)
  }
}
