import Errors from './Errors'
import _clone from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'

/**
 * @class Form
 */
export default class Form {
  /**
   * Create a new Form instance.
   * @param {object} data
   */
  constructor (data) {
    this._api = {
      get: () => {}
    }
    // create the originalData and revision objects
    this.originalData = {}
    this.revision = {}
    // Fill it with data
    this.updateOriginalData(data)
    this.payloadTransformer = (payload) => (payload)

    for (let field in data) {
      this[field] = _clone(data[field].value)
    }

    /** @type {Errors} */
    this.errors = new Errors()
    /** @type {boolean} */
    this.isLoading = false
  }

  /**
   * Fetch all relevant data for the form.
   * @returns Object
   */
  data () {
    let data = {}

    for (let property in this.originalData) {
      /* istanbul ignore next */
      if (this.originalData.hasOwnProperty(property)) {
        data[property] = this[property]
      }
    }

    return data
  }

  /**
   * Fetch all relevant data for the form, filtering for only changed data
   * always includes categories, country and topics
   * @returns {Object}
   */
  changedData () {
    let data = {}

    for (let property in this.originalData) {
      /* istanbul ignore next */
      if (this.originalData.hasOwnProperty(property) && this.revision[property].value !== this[property]) {
        data[property] = this[property]
      }
    }

    return data
  }

  /**
   * Returns the validation rules object
   * @return {Object}
   */
  rules () {
    let rules = {}

    for (let property in this.originalData) {
      /* istanbul ignore next */
      if (this.originalData.hasOwnProperty(property) && this.originalData[property].hasOwnProperty('rules')) {
        rules[property] = this.originalData[property].rules
      }
    }

    return rules
  }

  /**
   * Merges the passed data with the local and updates the revisions
   * @param {Object} data
   */
  merge (data) {
    for (let prop in data) {
      /* istanbul ignore next */
      if (this.originalData.hasOwnProperty(prop)) {
        // update this.prop
        this[prop] = _clone(data[prop])
        // update the revision as well
        this.revision[prop].value = _clone(data[prop])
      }
    }
  }

  /**
   * Reset the form fields.
   */
  reset () {
    for (let field in this.originalData) {
      /* istanbul ignore next */
      if (this.originalData.hasOwnProperty(field)) {
        this[field] = _clone(this.originalData[field].value)
      }
    }

    this.updateRevision()
    this.errors.clear()
    this.isLoading = false
  }

  /**
   * Resets the properties to the last revision
   */
  revertToRevision () {
    for (let field in this.revision) {
      /* istanbul ignore next */
      if (this.revision.hasOwnProperty(field)) {
        this[field] = _clone(this.revision[field].value)
      }
    }
  }

  /**
   * Send a POST request to the given endpoint.
   * @param {string} endpoint
   * @param {object} options
   */
  post (endpoint, options = {}) {
    return this.submit('post', endpoint, options)
  }

  /**
   * Send a PUT request to the given endpoint.
   * @param {string} endpoint
   * @param {object} options
   */
  put (endpoint, options = {}) {
    return this.submit('put', endpoint, options)
  }

  /**
   * Send a PATCH request to the given endpoint.
   * @param {string} endpoint
   * @param {object} options
   */
  patch (endpoint, options = {}) {
    return this.submit('patch', endpoint, options)
  }

  /**
   * Send a DELETE request to the given endpoint.
   * @param {string} endpoint
   * @param {object} options
   */
  delete (endpoint, options = {}) {
    return this.submit('delete', endpoint, options)
  }

  /**
   * Submit the form.
   *
   * @param {string|function} requestTypeOrMethod
   * @param {string} [endpoint]
   * @param {object} [options={}]
   * @return {Promise}
   */
  submit (requestTypeOrMethod, endpoint, options = {}) {
    if (this.isLoading) return Promise.reject(new Error('Previous process is still in progress'))

    this.isLoading = true
    const payload = this.payloadTransformer(this.data(), this.changedData())
    const promise = typeof requestTypeOrMethod === 'function'
      ? requestTypeOrMethod(payload)
      : this._api[requestTypeOrMethod](
        endpoint,
        payload,
        options)

    return promise
      .then(response => {
        this.onSuccess()

        return response ? (response.data || response) : {}
      })
      .catch(error => {
        const errorResponse = error.response ? error.response.data : error

        this.onFail(errorResponse)
        throw error
      })
  }

  /**
   * Sets the payload transforming function.
   *
   * @param {Function} transformer
   */
  setPayloadTransformer (transformer) {
    this.payloadTransformer = transformer
  }

  /**
   * Handle a successful form submission.
   */
  onSuccess () {
    this.isLoading = false
    this.updateRevision()
  }

  /**
   * Handle a failed form submission.
   *
   * @param {object} errors
   */
  onFail (errors) {
    this.isLoading = false
    this.errors.record(errors)
  }

  /**
   * Updates the originalData property with provided data
   * Defaults to this.data()
   * @param {String} data - property to update
   */
  updateOriginalData (data) {
    for (let prop in data) {
      if (data.hasOwnProperty(prop)) {
        if (!data[prop].hasOwnProperty('value')) throw new Error(`Value missing from ${prop}. You must provide a value key for each property.`)
        this.originalData[prop] = _clone(data[prop])
        this.revision[prop] = _clone(data[prop])
      }
    }
  }

  /**
   * Updates just the values of the revision
   * @param {Object} [data]
   */
  updateRevision (data = this.data()) {
    for (let prop in data) {
      if (data.hasOwnProperty(prop)) {
        this.revision[prop].value = _clone(data[prop])
      }
    }
  }

  /**
   * Get the current revision's values
   * @returns {{}}
   */
  getRevisionValues () {
    return Object.entries(this.revision).reduce((all, [key, values]) => {
      all[key] = values.value
      return all
    }, {})
  }

  /**
   * Checks if the form has unsaved changes
   * @return {boolean}
   */
  hasChanges () {
    return !_isEqual(this.getRevisionValues(), this.data())
  }

  hasPropertyChanged (property) {
    if (!property) return false
    return this[property] !== this.revision[property].value
  }
}
