const SNAPSHOTTER_URL = process.env.VUE_APP_SNAPSHOTTER_URL

/**
 * Builds the snapshotter URL by adding hash and scheme
 * @param {String} endpoint - the path to the dedicated endpoint to call
 * @param {String} snapshotTargetURL - the URL to snapshot
 * @param {String} hash - add a hash
 * @returns {string}
 */
function buildURL (endpoint, snapshotTargetURL, hash = '') {
  const { scheme, url } = prepareTarget(snapshotTargetURL)
  let compiledUrl = []
  // skip the hash if not required
  hash && compiledUrl.push(hash)
  compiledUrl = compiledUrl.concat([scheme, url])
  compiledUrl = compiledUrl.join('/')
  return `${SNAPSHOTTER_URL}/${endpoint}/${compiledUrl}`
}
/**
 * Prepares the URL Target by extracting necessary elements and encoding the rest.
 * @param target - target URL to snapshot
 * @returns {{scheme: (string), url: string}}
 */
function prepareTarget (target) {
  const { protocol, pathname, search, host } = new URL(target)
  const scheme = protocol === 'https' ? 's' : 'h'
  const url = encodeURIComponent(host + pathname + search)
  return {
    scheme,
    url
  }
}

export const SnapshotterService = {
  /**
   * Takes a snapshot of the Page
   * @param url
   * @returns {string}
   */
  screenshot (url) {
    return buildURL('screenshot', url)
  },
  /**
   * Takes a PDF snapshot of the page
   * @param url
   * @returns {string}
   */
  pdf (url) {
    return buildURL('report/pdf', url)
  },
  generatePrStoryPDF (url, hash) {
    return buildURL('pr_story/pdf', url, hash)
  },
  generatePrStoryImage (url, hash) {
    return buildURL('pr_story/image', url, hash)
  }
}
