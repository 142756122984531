/**
 * Formats a component name
 * @param {Component} vm - Vue instance
 */
export function formatComponentName (vm) {
  if (!vm) return '<Anonymous>'
  if (vm.$root === vm) return '<Root>'
  const options = typeof vm === 'function' && vm.cid != null
    ? vm.options
    : vm._isVue
      ? vm.$options || vm.constructor.options
      : vm || {}
  let name = options.name || options._componentTag
  const file = options.__file
  if (!name && file) {
    const match = file.match(/([^/\\]+)\.vue$/)
    name = match && match[1]
  }

  return (
    (name ? ('<' + (classify(name)) + '>') : '<Anonymous>') +
    (file ? (' at ' + file) : '')
  )
}

// taken and reworked from Vue.js source
function classify (str) {
  return str.replace(/(?:^|[-_])(\w)/g, c => c.toUpperCase()).replace(/[-_]/g, '')
}
