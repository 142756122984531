<template>
  <el-select
    v-model="computedValue"
    :popper-class="popperClass"
    :placeholder="placeholder"
    v-bind="$attrs"
    filterable
    @change="(v) => $emit('change',v)"
  >
    <languages-provider
      #default="{ languages }"
      :exclude="exclude"
      :show-supported-only="showSupportedOnly"
      :show-language-variations="showLanguageVariations"
    >
      <div>
        <el-option
          v-for="item in languages"
          :key="item.id"
          :label="item.name"
          :data-test-key="$attrs['value-key'] ? item : item.code"
          :value="$attrs['value-key'] ? item : item.code"
        >
          <slot :language="item" />
        </el-option>
      </div>
    </languages-provider>
  </el-select>
</template>

<script>
import BasicSelect from '@hypefactors/shared/js/components/core/BasicSelect.vue'
import LanguagesProvider from './LanguagesProvider'

export default {
  components: { LanguagesProvider },
  extends: BasicSelect,

  props: {
    popperClass: {
      type: String,
      default: 'languageModal'
    },
    placeholder: {
      type: String,
      default () {
        return this.$t('forms.choose_language')
      }
    },
    exclude: {
      type: Array,
      default: () => []
    },
    showSupportedOnly: {
      type: Boolean,
      default: false
    },
    showLanguageVariations: {
      type: Boolean,
      default: false
    }
  }
}
</script>
