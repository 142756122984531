<template>
  <div class="VPanel">
    <div class="VPanel__Heading p-a-m has-background-info">
      <slot name="heading" />
    </div>
    <div class="VPanel__Body has-background-grey-lighter p-a-m">
      <slot />
    </div>
  </div>
</template>

<script>
/**
 * @module VPanel
 */
export default {
  name: 'VPanel'
}
</script>
