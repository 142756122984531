export function userFactory () {
  return {
    id: null,
    first_name: '',
    last_name: '',
    email: '',
    country: {
      data: {
        name: '',
        iso_alpha2: '',
        neighbouringCountries: { data: [] }
      }
    },
    lockout_time: 0,
    avatar: {
      original: null
    },
    phone: '',
    is_staff_member: false,
    referrer: [],
    email_settings: null,
    onboarded_at: null,
    metadata: {}
  }
}

export function fallbackRolePermission () {
  return {
    can: false,
    reason: {
      cause: 'role',
      type: 'bool'
    }
  }
}
