import { CrossStorageClient } from 'cross-storage'

/**
 * @class CrossAuth
 */
class CrossAuth {
  constructor (url) {
    this.CrossStorageInstance = new CrossStorageClient(url, {
      timeout: 5000,
      frameId: 'storageFrame'
    })
  }

  /**
   * Gets a value from CrossStorage
   * @param {string} name
   * @return {Promise<*>}
   */
  async get (name) {
    await this.CrossStorageInstance.onConnect()
    const token = await this.CrossStorageInstance.get(name)
    if (typeof token === 'string') {
      return JSON.parse(token)
    }
    return null
  }

  /**
   * Sets a value in CrossStorage
   * @param {string} name
   * @param {*} value
   * @return {Promise<any>}
   */
  set (name, value) {
    return this.CrossStorageInstance.onConnect().then(() => this.CrossStorageInstance.set(name, JSON.stringify(value)))
  }

  /**
   * Deletes a value from CrossStorage
   * @param {string} name
   * @return {Promise<any>}
   */
  delete (name) {
    return this.CrossStorageInstance.onConnect().then(() => this.CrossStorageInstance.del(name))
  }

  /**
   * Gets the auth tokens
   * @return {Promise<*>}
   */
  async getAuthTokens () {
    return this.get('auth_tokens')
  }

  /**
   * Sets the auth tokens
   * @param tokens
   * @return {Promise<any>}
   */
  setAuthTokens (tokens) {
    return this.set('auth_tokens', tokens)
  }

  /**
   * Deletes auth tokens
   * @return {Promise<any>}
   */
  delAuthTokens () {
    return this.delete('auth_tokens')
  }
}

export default CrossAuth
