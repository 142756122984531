import { Coupon } from '@hypefactors/shared/js/models/Coupon'
import _get from 'lodash/get'

/**
 * @alias HF_Invoice
 * @description Invoice returned from the API
 */
export class Invoice {
  constructor (props) {
    /** @type {String} id */
    this.id = props.id || ''
    /** @type {Boolean} paid */
    this.paid = props.paid || false
    /** @type {Number} total */
    this.total = props.total || 0
    /** @type {Number} subtotal */
    this.subtotal = props.subtotal || 0
    /** @type {String} plan */
    this.plan = props.plan || ''
    /** @type {String} commitment */
    this.commitment = props.commitment || ''
    /** @type {String} date */
    this.date = props.date || ''
    /**  @type {String} currency */
    this.currency = props.currency || ''
    const coupon = _get(props, 'discount')
    /** @type ?Coupon */
    this.coupon = coupon ? new Coupon(coupon) : null
  }
}
