import Store from '@/store/index'
import _get from 'lodash/get'

/**
 * @module currencySymbol
 * @return {string} the currently active currency
 */
export const currencySymbol = () => {
  if (_get(Store, 'state.route.name', null) === 'dashboard.dashboard') {
    return _get(Store, 'getters.firstOrganisation.currencyCode', 'DKK')
  }

  return _get(Store, 'getters.activeBrand.currency_code', 'EUR')
}
