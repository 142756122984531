<template>
  <div :class="{ 'is-active': active }" class="Square">
    <div class="Square__wrap">
      <div :class="[size]" class="Square__inner has-no-overflow">
        <div class="Square__head">
          <div class="Square__title">
            <slot name="title">
              {{ title }}
            </slot>
          </div>
          <div class="Square__subtitle">
            {{ subtitle }}
          </div>
        </div>
        <div class="Square__body">
          <slot />
        </div>
        <div :class="{'is-hidden-touch is-hidden-desktop-only': !!footer}" class="Square__footer">
          <slot name="footer">
            {{ footer }}
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    footer: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'small',
      validate (size) {
        return ['small', 'medium', 'large', 'xl'].includes(size)
      }
    },
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang='scss'>
@import "~utils";

.Square {
  transition: 0.3s;
  border-bottom: 3px solid transparent;

  &:not(.Square--uncollored) {
    background: $grey-lighter;
    border-color: $grey-lighter;
  }

  &.is-active {
    @include customPropertyFallback(color, --THEME__COLOR-PRIMARY-INVERT, $primary-invert);
    @include customPropertyFallback(background, --THEME__COLOR-PRIMARY, $primary);
    @include customPropertyFallback(border-color, --THEME__COLOR-PRIMARY, $primary);

    .markets-list {
      .aplus .icon {
        background: $hf__color-primary--alternative;
      }
    }
  }

  &.is-transparent {
    background: none;
  }

  &.is-cursor-pointer:hover {
    border-bottom: 3px solid currentColor;
  }
}

.Square__wrap {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  @include tablet-only {
    font-size: .7rem;
  }
  @include desktop {
    font-size: 0.8rem;
  }
  @include fullhd {
    font-size: 1rem;
  }

  .Square__inner {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    padding: $padding-small;
    flex-flow: column nowrap;

    &.small {
      font-size: 0.75em;
    }

    &.medium {
      font-size: 1em;
      padding: $padding;
    }

    &.large {
      font-size: 1.25em;
      padding: $padding-small;
    }

    &.xl {
      font-size: 1.35em;
      padding: $padding*1.2;
    }
  }

  .Square__body {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    overflow: hidden;
  }

  .Square__footer {
    line-height: 1;
    width: 100%;
  }

  .Square__title {
    font-weight: $hf__font-weight-bolder;
  }

  .Square__value-main {
    font-size: 2.5em;
    font-weight: 900;
    line-height: 1;

    .currency-symbol {
      font-size: 0.6em;
    }

    &.small {
      font-size: 2em;
      line-height: 1.6;
      vertical-align: bottom;
    }
  }

  .Square__value-diff {
    font-size: 1.5em;
    font-weight: 900;
    line-height: 1;
    display: flex;
    align-items: center;

    &::before {
      font-family: Hypefactors-Icon-Font;
      font-size: 1.3em;
      line-height: 1;
      margin-right: .2em
    }

    &.inc::before {
      color: lighten($success, 10%);
      content: $hf-caret-up;
    }

    &.dec::before {
      color: $danger;
      content: $hf-caret-down;

      .is-active & {
        color: $dark;
      }
    }
  }
}
</style>
