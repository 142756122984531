<template>
  <div class="Inline-field" @click="startEditing">
    <form v-if="isEditing" v-loading="isSubmitting" @submit.prevent="done">
      <div class="field has-addons">
        <div class="control">
          <input
            v-model="edited"
            :placeholder="placeholder"
            class="input"
            name="name"
            type="text"
          >
        </div>
        <div class="control">
          <button type="submit" class="button is-success">
            {{ $t('forms.save') }}
          </button>
          <button class="button is-text" @click.prevent.stop="cancel">
            {{ $t('forms.cancel') }}
          </button>
        </div>
      </div>
    </form>

    <slot v-else />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    submit: {
      type: Function,
      default: value => (value)
    },
    success: {
      type: Function,
      default: value => (value)
    },
    fail: {
      type: Function,
      default: function (err) {
        this.$displayRequestError(err)
      }
    }
  },

  data: () => ({
    isSubmitting: false,
    isEditing: false,
    original: null,
    edited: null
  }),

  watch: {
    value (value) {
      this.original = value
    }
  },

  mounted () {
    this.original = this.value
  },

  methods: {
    startEditing () {
      if (this.isEditing) return

      this.edited = this.original
      this.isEditing = true
    },

    async done () {
      this.$emit('done', this.edited)

      this.isSubmitting = true

      try {
        await this.submit(this.edited)

        this.success(this.edited)
        this.close()
      } catch (err) {
        this.fail(err)
        this.cancel()
      }

      this.isSubmitting = false
    },

    cancel () {
      this.$emit('cancel', this.original)
      this.close()
    },

    close () {
      this.isEditing = false
      this.edited = null
    }
  }
}
</script>

<style lang="scss">
@import '~utils';

.Inline-field {
  > *:not(form) {
    cursor: pointer;
    border-bottom: 1px dotted rgba($grey, 0.25);
    position: relative;
    @include transit(border-color);

    &:hover {
      border-color: $grey;
      @include transit(border-color);

      &::after {
        margin-left: $margin-small;
        line-height: 1;
        color: $grey-dark;
        font-family: Hypefactors-Icon-Font;
        font-size: $hf__font-default;
        content: $hf-pencil;
      }
    }
  }
}

</style>
