import categories from './categories'
import groups from './groups'
import connectRelationsActivitiesOrganiser from './activity/organiser'

export default {
  namespaced: true,

  modules: {
    categories,
    groups,
    'relations/activities/organiser': connectRelationsActivitiesOrganiser
  }
}
