<template>
  <div class="container has-text-centered m-t-xxl">
    <div class="pb-8">
      <img class="w-64" src="https://storage.hypefactors.com/images/branding/hf_logo_full_dark.png" alt="">
    </div>

    <h2 class="m-t-xl has-text-weight-semibold is-size-2">
      We're down for maintenance
    </h2>

    <div v-if="message" class="m-t-xl is-size-3" v-html="message" />
    <div v-else class="m-t-xl is-size-4">
      We'll be back shortly.
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      message: 'getMaintenanceModeMessage'
    })
  }
}
</script>
