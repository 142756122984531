import clippingsAdd from './clippings/add'
import CXPayment from './cx/payment'
import auth from './auth'
import connect from './connect'
import dashboard from './dashboard'
import filters from './filters'
import social from './social'
import newsroom from './newsroom'
import app from './app'
import loadmore from './loadmore'
import coverage from './coverage'
import updateChecker from './updateChecker'
import globalFilters from '@hypefactors/shared/js/vuex/modules/globalFilters'

export default {
  'clippings/add': clippingsAdd,
  'cx/payment': CXPayment,
  auth,
  connect,
  dashboard,
  globalFilters,
  filters,
  newsroom,
  social,
  app,
  loadmore,
  coverage,
  updateChecker
}
