<template>
  <div class="DelimitedList is-flex can-wrap">
    <div
      v-for="(val, key, index) in value"
      :key="key"
      :class="!isLast(index, key) && itemClass"
    >
      <slot v-bind="{ key, val, index }">
        <span>{{ property ? val[property]: val }}</span>
      </slot>
      <slot v-if="!isLast(index, key)" name="delimiter">
        <span>{{ delimiter }}</span>
      </slot>
    </div>
  </div>
</template>

<script>
/**
 * @module DelimitedList
 */
export default {
  name: 'DelimitedList',
  props: {
    value: {
      type: [Object, Array],
      required: true
    },
    delimiter: {
      type: String,
      default: ','
    },
    itemClass: {
      type: String,
      default: 'm-r-s'
    },
    property: {
      type: String,
      default: ''
    }
  },
  computed: {
    elementsCount () {
      return Object.keys(this.value).length - 1
    }
  },
  methods: {
    isLast (index, key) {
      return (index !== undefined ? index : key) === this.elementsCount
    }
  }
}
</script>
