<template>
  <div class="CXCart border-grey-light p-l-l p-r-l p-v-xl">
    <heading
      :serif="false"
      color="black"
      size="5"
    >
      {{ $t('pages.cx.payment.summary') }}
    </heading>

    <div class="columns is-mobile is-aligned-middle">
      <div class="column">
        {{ $t('pages.cx.payment.billing_cycle') }}:
      </div>

      <div class="column">
        <form-field>
          <basic-select
            v-model="chosenInterval"
            :disabled="intervalPickerDisabled"
            :values="intervalsMap"
            key-prop="slug"
            label-prop="label"
            value-prop="slug"
            data-testid="CartIntervalPicker"
            popper-class="CartIntervalPicker__Modal"
          />
        </form-field>
      </div>
    </div>

    <template v-if="plan && arePaymentPlansFetched">
      <v-separator fat darker />
      <div class="columns is-mobile">
        <div class="column">
          <div data-testid="CXCart__CurrentPlan">
            {{ $t('pages.cx.payment.plan') }}: {{ paymentPlansMapForInterval[plan].name }}
          </div>
          {{ $t('pages.cx.payment.users') }}: {{ users }} <br>
        </div>

        <div class="column is-narrow has-text-right" data-testid="CartTotalUsersPrice">
          {{ formatMoney(totalCostForUsersPerInstallment, true, 'EUR', currentPlanCurrency) }}
          <div>{{ $t('pages.cx.payment.per_user_per_month') }}</div>
        </div>
      </div>
    </template>

    <div v-if="amountOfReadOnlyUsers" class="columns is-mobile">
      <div class="column">
        {{ $t('pages.cx.payment.read_only_users') }}: <span data-testid="CartReadOnlyUsersCount">{{ amountOfReadOnlyUsers }}</span>
      </div>

      <div class="column is-narrow  has-text-right">
        <div data-testid="CartReadOnlyUsersPrice">
          {{ formatMoney(readOnlyUsersCost, true, 'EUR', currentPlanCurrency) }}
        </div>
        <div>{{ $t('pages.cx.payment.per_user_per_month') }}</div>
      </div>
    </div>

    <template v-if="hasCoupon">
      <v-separator fat darker />

      <div class="columns is-mobile is-gapless is-marginless">
        <div class="column">
          {{ $t('pages.cx.payment.coupon_applied') }}:
        </div>

        <div class="column is-narrow">
          <span data-testid="CouponValue">-{{ formatMoney(couponSavings, true, 'EUR', currentPlanCurrency) }}</span>
          <span v-if="couponType === 'percentage'" class="has-text-weight-semibold">({{ percentageInt(couponValue) }})</span>
        </div>
      </div>
    </template>

    <v-separator fat darker />

    <div class="columns is-mobile is-aligned-middle">
      <div class="column">
        <div class="has-text-weight-semibold">
          {{ $t('pages.cx.payment.total') }}
        </div>
        <div>
          +
          <i18n path="pages.cx.payment.tax_per_interval" data-testid="cartTotalInterval">
            <span slot="interval">{{ $t(`general.${interval}`) }}</span>
          </i18n>
        </div>
      </div>

      <div class="column is-narrow">
        {{ formatMoney(totalCost, true, 'EUR', currentPlanCurrency) }}
      </div>
    </div>

    <!-- Default slot to pass various action buttons -->
    <slot />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import FormatMoneyMixin from '@hypefactors/shared/js/mixins/FormatMoneyMixin'
import PercentageIntMixin from '@hypefactors/shared/js/mixins/PercentageIntMixin'

import { ucfirst } from '@hypefactors/shared/js/utils'
import { generateComputedSetterWithMethod } from '@hypefactors/shared/js/utils/componentUtilities'

const { mapState, mapMutations, mapGetters } = createNamespacedHelpers('cx/payment')

/**
 * @module CXCart
 */
export default {
  name: 'CXCart',

  mixins: [FormatMoneyMixin, PercentageIntMixin],

  props: {
    intervalPickerDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['interval', 'intervals', 'amountOfReadOnlyUsers', 'plan', 'users', 'couponType', 'couponValue']),
    ...mapGetters([
      'hasCoupon',
      'couponSavings',
      'readOnlyUsersCost',
      'totalCostForUsersPerInstallment',
      'totalCost',
      'currentPlanCurrency',
      'paymentPlansMapForInterval',
      'arePaymentPlansFetched'
    ]),
    /**
     * Transform the intervals array into an array of objects
     * Could be extracted as an Interval Picker
     * @return {{label: string, slug: string}[]}
     */
    intervalsMap () {
      return this.intervals.map(i => ({
        slug: i,
        label: ucfirst(this.$t(`general.${i}`))
      }))
    },
    chosenInterval: generateComputedSetterWithMethod('interval', 'SET_INTERVAL')
  },
  methods: {
    ...mapMutations(['SET_INTERVAL'])
  }
}
</script>
