/**
 * @typedef HF_StripeCardAddress
 * @property {string} city
 * @property {string} line1
 * @property {!string} line2
 * @property {!string} state
 * @property {string} country
 * @property {string} postal_code
 */

/**
 * @alias HF_StripeCard
 */
export class StripeCard {
  constructor (card) {
    /** @type {string} */
    this.expMonth = card.exp_month
    /** @type {string} */
    this.expYear = card.exp_year
    /** @type {string} */
    this.name = card.name
    /** @type {number} */
    this.last4 = card.last4
    /** @type {string} */
    this.brand = card.brand
    /** @type {HF_StripeCardAddress} */
    this.address = card.address
  }
}
