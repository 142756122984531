import _get from 'lodash/get'

/**
 * @alias HF_Subscription
 */
export class Subscription {
  constructor (sub = {}) {
    /** @type {?string} - the date when it ended */
    this.endedAt = sub.ended_at || null
    /** @type {string} - The Plan Slug (freemium, plus ...) */
    this.planSlug = _get(sub, 'plan.slug', '')
    /** @type {string} - The Plan Name (Freemium, Plus, Professional, Enterprise) */
    this.planName = _get(sub, 'plan.name', '')
    /** @type {string} - The Plan Commitment Slug (plus_monthly, plus_yearly) */
    this.commitmentSlug = _get(sub, 'commitment.slug', '')
    /** @type {string} - The Plan Commitment Name (Monthly, Yearly) */
    this.commitmentName = _get(sub, 'commitment.name', '')
    /** @type {number} The Plan Commitment Price */
    this.commitmentAmount = _get(sub, 'commitment.amount', 0)
    /** @type {string} The Plan Commitment Currency Code */
    this.commitmentCurrency = _get(sub, 'commitment.currency', 0)
    /** @type {string} - The Plan Commitment Interval (monthly, yearly) */
    this.commitmentInterval = _get(sub, 'commitment.interval', '')
    /** @type {Object.<string, HF_CommitmentLimitation>} - The Plan Commitment Limitations */
    this.commitmentLimitations = _get(sub, 'commitment.limitations', {})
    /** @type {boolean} - If the plan is freemium or not */
    this.isFreemium = this.planSlug === 'freemium'
    /** @type {string} - when the billing period starts */
    this.periodStartsAt = sub.period_starts_at || ''
    /** @type {string} - when the billing period ends */
    this.periodEndsAt = sub.period_ends_at || ''
    /** @type {?string} - When the trial started */
    this.trialStartsAt = sub.trial_starts_at || null
    /** @type {?string} - When the trial ends */
    this.trialEndsAt = sub.trial_ends_at || null
    /** @type {boolean} - If the plan is in trial period */
    this.isOnTrial = sub.is_on_trial || false
    /** @type {?string} - the date when it was cancelled */
    this.canceledAt = sub.canceled_at || ''
  }
}
