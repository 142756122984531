import Vue from 'vue'
import Vuex from 'vuex'

import modules from './modules'
import * as actions from './actions'
import * as getters from './getters'
import * as storageUtils from '@/utils/index'
import { STORE_AUTH_TOKEN, SET_ACTIVE_BRAND_ID } from '@/store/mutations'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  actions,
  getters,
  strict: false // hardcode false for now
})

store.commit(STORE_AUTH_TOKEN, storageUtils.load('access_token', ''))
store.commit(SET_ACTIVE_BRAND_ID, storageUtils.load('active_brand', null))

export default store
