<template>
  <div class="PageSection has-background-white p-a-s">
    <slot name="above-title" />
    <div v-if="!hideTitle" class="PageSection__Title" :class="{ 'm-t-s': $slots['above-title'] }">
      <slot name="title">
        <div class="is-flex is-aligned-middle m-b-m">
          <heading
            data-testid="pageTitle"
            :serif="false"
            size="3"
            color="black"
            weight="bold"
            class="m-b-none"
            gapless
          >
            <help-tooltip :content="pageTooltip">
              <slot name="tooltip-content" />
            </help-tooltip>
            {{ pageTitle }}
          </heading>
          <div class="m-l-a">
            <slot name="title-right" />
          </div>
        </div>
      </slot>
    </div>
    <slot />
  </div>
</template>

<script>
/**
 * @module PageSection
 */
export default {
  name: 'PageSection',
  props: {
    pageTooltip: {
      type: String,
      default: ''
    },
    pageTitle: {
      type: String,
      default: ''
    },
    hideTitle: {
      type: Boolean,
      default: false
    }
  }
}
</script>
