/**
 * Returns the total stats for a metric
 * @return {{previous: number, current: number, growth: number, growth_aplus: number, growth_bplus: number}}
 */
export function totalsStatFactory () {
  return {
    previous: 0,
    current: 0,
    growth: 0,
    growth_aplus: 0,
    growth_bplus: 0
  }
}

/**
 * Returns all stats for a metric
 * @return {{brands: {}, top_brand: {}, totals: {previous: number, current: number, growth: number, growth_aplus: number, growth_bplus: number}}}
 */
export function statFactory () {
  return {
    brands: {},
    top_brand: null,
    totals: totalsStatFactory()
  }
}

/**
 * Returns the combined stats for all metrics
 * @return {{clippings: {brands: Array, top_brand: {}, totals: {previous: number, current: number, growth: number, growth_aplus: number, growth_bplus: number}}, impressions: {brands: Array, top_brand: {}, totals: {previous: number, current: number, growth: number, growth_aplus: number, growth_bplus: number}}, hfValue: {brands: Array, top_brand: {}, totals: {previous: number, current: number, growth: number, growth_aplus: number, growth_bplus: number}}}}
 */
export function brandTotalsFactory () {
  return {
    clippings: statFactory(),
    impressions: statFactory(),
    hfValue: statFactory()
  }
}

/**
 * Returns the timeseries response for a metric
 * @return {{name: string, data: [number, number]}}
 */
export function timeseriesFactory () {
  return {
    name: '', // Name of the Brand or Country
    data: []
  }
}
