import { getTimestampFromPredefinedSlug } from '@hypefactors/shared/js/vuex/utils/dateRange'
import { toggleArrayElement } from '../../utils/arrayUtils'

const state = {}

const getters = {
  searchQuery (state, getters, globalState, globalGetters) {
    return globalGetters.getFilter('searchQuery', null)
  },

  selectedCountries (state, getters, globalState, globalGetters) {
    return globalGetters.getFilter('country', [])
  },

  selectedCategories (state, getters, globalState, globalGetters) {
    return globalGetters.getFilter('categories', [])
  },

  sortBy (state, getters, globalState, globalGetters) {
    return globalGetters.getFilter('sortBy', [])
  }
}

const mutations = {}

const actions = {
  setSearchQuery ({ dispatch, commit }, searchQuery) {
    return dispatch('setFilter', {
      name: 'searchQuery',
      value: searchQuery
    }, { root: true })
  },

  /**
   * Shortcut to set a value based on a shortcut timestamp slug
   * @param {string} timespan
   * @return {Promise<[]>}
   */
  setTimeFiltersSpanFromPreset ({ dispatch, commit }, timespan) {
    return Promise.all([
      dispatch('setTimeFilters', {
        name: 'start',
        value: getTimestampFromPredefinedSlug(timespan)
      }),
      dispatch('setTimeFilters', {
        name: 'end',
        value: getTimestampFromPredefinedSlug() // start of today
      })
    ])
  },

  /**
   * Sets the filter for start and end
   * @name setTimeFilters
   * @param {string} name - start or end
   * @param {Date} value
   * @return {*}
   */
  setTimeFilters ({ dispatch }, { name, value }) {
    return dispatch('setFilter', {
      name,
      value: Math.round(value.getTime() / 1000)
    }, { root: true })
  },

  async setDefaultFiltersTimeFiltersIfNeeded ({ dispatch, getters, rootGetters }, span = 'two_weeks') {
    if (!rootGetters.hasFilter('start') || !rootGetters.hasFilter('end')) {
      await dispatch('setTimeFiltersSpanFromPreset', span)
    }
  },

  /**
   * Toggles active dashboard countries on/off
   * @param {string} countryIso
   */
  toggleDashboardActiveCountries ({ commit, dispatch, getters }, countryIso) {
    const selectedCountries = toggleArrayElement(getters.selectedCountries, countryIso)
    return dispatch('setFilter', {
      name: 'country',
      value: selectedCountries
    }, { root: true })
  },

  /**
   * Toggles selected categories
   *
   * @param {string} category
   */
  toggleSelectedCategories ({ commit, dispatch, getters }, category) {
    const selectedCategories = toggleArrayElement(getters.selectedCategories, category)

    return dispatch('setFilter', {
      name: 'categories',
      value: selectedCategories
    }, { root: true })
  },

  /**
   * @param {string} sortingValue
   */
  toggleSortBy ({ commit, dispatch, getters }, sortingValue) {
    // TODO: At the moment only a single sorting value is allowed
    // Probably make this better to allow multiple values.
    const sortBy = [sortingValue]

    return dispatch('setFilter', {
      name: 'sortBy',
      value: sortBy
    }, { root: true })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
