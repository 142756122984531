import api from '@/services/Api'
import Vue from 'vue'

export const SET_GROUPS = 'SET_GROUPS'
export const CREATE_GROUP = 'CREATE_GROUP'
export const DELETE_GROUP = 'DELETE_GROUP'
export const UPDATE_GROUP = 'UPDATE_GROUP'

const state = {
  groups: []
}

const getters = {
  all (state) {
    return state.groups
  },

  findGroupById: (state, getters) => groupId => {
    return getters.all.find(group => group.id === groupId)
  }
}

const actions = {
  async fetch ({ commit }, brandUuid) {
    try {
      const response = await api.get('/connect/groups', {
        params: {
          brand: brandUuid,
          sort: 'a_z',
          limit: 100
        }
      })

      const groups = response.data.data

      commit(SET_GROUPS, groups)
    } catch (err) {
      throw err
    }
  },

  async delete ({ commit }, group) {
    try {
      await api.delete(`/connect/groups/${group.id}`)

      commit(DELETE_GROUP, group)
    } catch (err) {
      throw err
    }
  },

  rename ({ commit }, payload) {
    commit(UPDATE_GROUP, payload)
  },

  async addRelationsToGroup ({ commit }, payload) {
    try {
      await api.post(`/connect/groups/${payload.groupId}/relations`, {
        relations: payload.relations
      })
    } catch (err) {
      throw err
    }
  },

  async removeRelationsFromGroup ({ commit }, payload) {
    try {
      await api.delete(`/connect/groups/${payload.groupId}/relations`, {
        data: {
          relations: payload.relations
        }
      })
    } catch (err) {
      throw err
    }
  }
}

const mutations = {
  [SET_GROUPS] (state, groups) {
    state.groups = groups
  },

  [DELETE_GROUP] (state, group) {
    const index = state.groups.indexOf(group)

    state.groups.splice(index, 1)
  },

  [UPDATE_GROUP] (state, { name, groupId }) {
    const group = state.groups.find(g => g.id === groupId)
    const index = state.groups.indexOf(group)

    group.name = name

    Vue.set(state.groups, index, group)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
