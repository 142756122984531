import axios from 'axios'
import Api from '@hypefactors/shared/js/services/Api'

/**
 * Creates a new Axios instance with the Lambda URL as base
 * @type {AxiosInstance}
 */
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_LAMBDA_URL
})

const LambdaApi = new Api(axiosInstance)

export { LambdaApi }
