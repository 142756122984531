<template>
  <div :class="classes" />
</template>

<script>
export default {
  name: 'VSeparator',

  props: {
    fat: {
      type: Boolean,
      default: false
    },

    darker: {
      type: Boolean,
      default: false
    },

    color: {
      type: String,
      default: 'grey-dark',
      validator: v => !v || ['grey', 'red', 'grey-dark', 'black'].includes(v)
    },

    dashed: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes () {
      return {
        fat: this.fat,
        darker: this.darker,
        ['VSeparator--' + this.color]: this.color,
        VSeparator: true,
        'VSeparator--dashed': this.dashed
      }
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.VSeparator {
  margin: $margin 0;
  border: $hf__separator-border;
  border-width: 1px 0 0 0;

  &.fat {
    border-top-width: 2px;
  }

  &.darker {
    border-color: $grey-light;
  }

  &--red {
    border-color: $primary;
  }

  &--grey {
    border-color: $grey;
  }

  &--grey-dark {
    border-color: $grey-dark;
  }

  &--black {
    border-color: $black;
  }

  &--dashed {
    border-style: dashed;
  }
}
</style>
