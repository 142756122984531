import { distanceInWordsToNow, format } from 'date-fns'
import { Trans } from '@/services/TranslationService'

const locales = {
  'en': require('date-fns/locale/en/index.js')
}

export async function loadLocaleFile (locale) {
  try {
    // limit the imports to just top level index.js
    locales[locale] = await import(
      /* webpackChunkName: "date_fns-[request]" */
      /* webpackInclude: /locale\/(?:en|da|fr|es|it|pl|pt|sv|fi|no|pt_BR)\/index.js$/ */
      `date-fns/locale/${locale}/index.js`)
  } catch (err) {
    locales[locale] = locales.en
  }
}

export function wrapToLocalized (func, ...params) {
  const config = typeof params[params.length - 1] !== 'string' ? params.pop() : {}
  return func(...params, {
    locale: locales[Trans.currentLanguage],
    ...config
  })
}

export function localizedDateFormat (value, dateTimeFormat, config) {
  return wrapToLocalized(format, value, dateTimeFormat, config)
}

export function dateTimeAtTimezone (value, timeZone) {
  const options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: timeZone
  }
  return new Date(value).toLocaleString(Trans.currentLanguage, options)
}

export function localizedDistanceInWordsToNow (value, config) {
  return wrapToLocalized(distanceInWordsToNow, value, config)
}
