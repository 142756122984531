import { localStorageService } from '../services/LocalStorageService'
import { LocationService } from '../services/LocationService'
import { moneyAbbrFormat, moneyFormat } from '../utils/moneyFormatters'
import { minAbbr } from '../utils/numberUtils'

export { handleError, displayRequestError } from './handleErrors'

/**
 * Loads an item from the local storage by key.
 *
 * @export
 * @param {string} key
 * @param {*} [fallback]
 * @returns {*}
 */
export function load (key, fallback) {
  try {
    let item = localStorageService.getItem(key)
    return item ? (window.JSON && window.JSON.parse(item)) : fallback
  } catch (err) {
    return fallback
  }
}

/**
 * Persists a value in local storage.
 *
 * @export
 * @param {string} key
 * @param {*} [value={}]
 */
export function persist (key, value = {}) {
  localStorageService.setItem(key, window.JSON.stringify(value))
}

/**
 * Removes an item from the local storage by key.
 *
 * @export
 * @param {string} key
 */
export function forget (key) {
  localStorageService.removeItem(key)
}

/**
 * Checks if Ad Blocker script is active on the user's browser
 * @param {string|Node} element - The element to check
 * @param {boolean} replace - Whether to replace the content
 * @param {string} message - The message to replace with
 * @returns {boolean}
 */
export function checkForAddblocker (element, replace = true, message = 'You are using software that is blocking the generation of social plugins.') {
  const ad = typeof element === 'string' ? document.querySelector(element) : element
  if (ad && ad.innerHTML.replace(/\s/g, '').length === 0) {
    replace && (ad.innerHTML = message)
    return true
  }
  return false
}

/**
 * Load script async
 * @param {String} src
 * @param {String} id
 * @param {Object} options
 * @return {Promise<any>}
 */
export function loadScript (src, id, options = {}) {
  return new Promise(function (resolve, reject) {
    if (id && document.getElementById(id)) return resolve()
    const s = document.createElement('script')
    s.src = src
    s.id = id
    s.async = true
    s.onload = resolve
    s.onerror = reject
    let prop
    for (prop in options) {
      if (options.hasOwnProperty(prop)) {
        s[prop] = options[prop]
      }
    }
    document.head.appendChild(s)
  })
}

/**
 * Removes an element from the dom
 * @param {Element} element
 */
export function removeElement (element) {
  if (!element) return
  element.parentElement.removeChild(element)
}

/**
 * If value starts with HTTP/S or not
 * @param {String} value - Value to test
 * @return {boolean}
 */
export function hasHttp (value) {
  const regexHttpCheck = /(http(s?)):\/\//gi
  return regexHttpCheck.test(value)
}

/**
 * Strip HTML tags from string
 * @param {string} input - The text to strip from
 * @returns {string} - The stripped text
 */
export function stripHtml (input) {
  return (input || '').replace(/<(?:.|\n)*?>/gm, '')
}

/**
 * Returns the path to the hypefactors storage server
 * @param {String} path - path to file in storage
 * @return {String}
 */
export function storagePath (path) {
  return (process.env.VUE_APP_HYPEFACTORS_STORAGE_URL || '') + path
}

/**
 * Returns a url to one of our other apps on different domains
 * @param {string} url - url part we are generating
 * @param {string} domain - domain we want to get
 * @param {string|boolean} i18n - lang version we need
 * @return {string}
 */
export function getUrlToDomain (url = '', domain, i18n = 'en') {
  if (hasHttp(url)) return url
  const lang = (i18n ? '/' + i18n : '')
  let prepend = process.env.VUE_APP_HYPENEWS_URL
  switch (domain) {
    case 'hypenews':
      prepend = process.env.VUE_APP_HYPENEWS_URL + lang
      break
    case 'api':
      prepend = process.env.VUE_APP_API_URL
      break
    case 'hypefactors':
      prepend = process.env.VUE_APP_HYPEFACTORS_URL
      break
    case 'report':
      prepend = process.env.VUE_APP_REPORT_URL
      break
    case 'company':
      prepend = 'https://hypefactors.com' + lang
      break
  }
  prepend = prepend.endsWith('/') ? prepend.slice(0, -1) : prepend
  url = url.startsWith('/') ? url : '/' + url
  return prepend + url
}

/**
 * Extracts the Social Handle from a social URL
 * @param {String} domain - Social network domain to test for
 * @param {String} value - Value to test for
 * @return {{isSocialUrl: boolean, isHttp: boolean, urlPart: number, handleExists: boolean, handlePart: (*|null)}}
 */
export function extractHandleFromSocialUrl (domain, value = '') {
  const handle = value.toLowerCase() || ''
  const isSocialUrl = handle.includes(domain + '/')
  const isHttp = hasHttp(handle)
  const urlParts = handle.split('/')
  const urlPartIndex = urlParts.findIndex(p => p.includes(domain))
  let handlePart = urlParts[urlPartIndex + 1]
  let handleExists = false

  if (typeof handlePart !== 'undefined') {
    handlePart = handlePart.match(/^([^?]+).*/)
    handlePart = (handlePart && handlePart.length > 1) ? handlePart[1] : null
    handleExists = handlePart !== null
  }

  return {
    isSocialUrl,
    isHttp,
    handleExists,
    handlePart
  }
}

export function ucfirst (value) {
  if (typeof value !== 'string') return value
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export function isVideo (file) {
  return /^.*\.(qt|avi|wmv|flv|mpg|mp4|webm)$/.test(file)
}

export function isAudio (file) {
  return /^.*\.(mp3|ogg|mp4a|mpga|m4a)$/.test(file)
}

export function isImage (file) {
  return /^.*\.(jpeg|jpg|png|gif)$/.test(file)
}

/**
 * Creates an awaitable timeout
 * @param {number} timeout
 * @return {Promise<any>}
 */
export function asyncTimeout (timeout) {
  return new Promise(resolve => setTimeout(resolve, timeout))
}

export function removeElementChildren (element) {
  if (!element) return
  while (element.firstChild) {
    element.removeChild(element.firstChild)
  }
}

/**
 * Checks if a node is a child of another
 * @param parent
 * @param child
 * @return {boolean}
 */
export function isDescendant (parent, child) {
  let node = child ? child.parentNode : child
  while (node != null) {
    if (node === parent) {
      return true
    }
    node = node.parentNode
  }
  return false
}

/**
 * Strips special chars from strings.
 * @param {string} value
 * @return {string}
 */
export function stripSpecialChars (value = '') {
  if (typeof value !== 'string') return value
  return value.replace(/[^\w\s]/gi, '')
}

/**
 * Redirects the user to endpoint with delay
 * @param {String} to - Destination to redirect to
 * @param {Number} duration - Time to wait before redirecting
 * @param {String} newTab - Whether to open a new tab or not
 * @return {Promise<*>}
 */
export function $redirectTo (to, duration = 1500, newTab = '') {
  if (newTab && duration === 0) {
    window.open(to, newTab)
    return Promise.resolve()
  }

  return new Promise(function (resolve) {
    setTimeout(() => {
      resolve()
      if (newTab) {
        window.open(to, newTab)
      } else {
        LocationService.assign(to)
      }
    }, duration)
  })
}

export function dateToUnixTimestamp (value) {
  if (!value) return value
  return new Date(value).getTime() / 1000 // remove milliseconds
}

export function localToUtcTimestamp (seconds) {
  let date = new Date(seconds * 1000)
  return Math.round(Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  ) / 1000)
}

export function countWords (value = '') {
  return value
    .trim()
    // remove extra spaces
    .replace(/[ ]{2,}/gi, ' ')
    // remove new lines
    .replace(/\n /, '\n')
    // count words
    .split(' ').length
}

/**
 * Truncates the given value to it's max length.
 *
 * @param {string} value
 * @param {integer} maxLength
 *
 * @return {string}
 */
export function truncate (value, maxLength = 150, ending) {
  if (!value || typeof value !== 'string' || value.length < maxLength) {
    return value
  }

  if (ending == null) {
    ending = '...'
  }

  return value.substring(0, maxLength) + ending
}

export function impressions (value, min = 100, cutoff = 1000000) {
  return minAbbr(value, min, cutoff)
}

// TODO: Probably remove this and the "FormatNumberMixin" file too
// export function number (value) {
//   return Number(value).toLocaleString('ru-RU') // Use ru-RU to have spaces as separators
// }

export function money (value, convert, symbolTo, symbolFrom, addSymbol) {
  return moneyFormat(value, symbolTo, convert, symbolFrom, addSymbol)
}

export function abbrMoney (value, convert, symbolTo, symbolFrom, addSymbol) {
  return moneyAbbrFormat(value, symbolTo, convert, symbolFrom, addSymbol)
}
