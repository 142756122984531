import Vue from 'vue'

import scrollbar from 'element-ui/lib/scrollbar'
import SocialSharing from 'vue-social-sharing'

import VPanel from '@/components/core/VPanel'
import DefaultLayout from '@/router/layouts/Default'
import Authorize from '@/components/core/Authorize'
import RoundIcon from '@/components/core/RoundIcon'
import PageSection from '@/components/core/PageSection'
import InlineField from '@/components/forms/InlineField'
import Layout from '@hypefactors/shared/js/router/Layout'
import BrandsPicker from '@/components/core/BrandsPicker'
import LoadMore from '@/components/pagination/LoadMore.vue'
import CurrencyPicker from '@/components/core/CurrencyPicker'
import BandedSection from '@/components/dashboard/BandedSection'
import VIcon from '@hypefactors/shared/js/components/core/VIcon'
import RestrictedButton from '@/components/core/RestrictedButton'
import VImage from '@hypefactors/shared/js/components/core/VImage'
import Tier from '@hypefactors/shared/js/components/clippings/Tier'
import VButton from '@hypefactors/shared/js/components/core/VButton'
import Square from '@hypefactors/shared/js/components/squares/Square'
import AuthorizedFallback from '@/components/core/AuthorizedFallback'
import DataCard from '@hypefactors/shared/js/components/core/DataCard'
import Heading from '@hypefactors/shared/js/components/core/Heading.vue'
import VSeparator from '@hypefactors/shared/js/components/core/VSeparator'
import HelpTooltip from '@hypefactors/shared/js/components/core/HelpTooltip'
import CountryFlag from '@hypefactors/shared/js/components/core/CountryFlag'
import Subheading from '@hypefactors/shared/js/components/core/Subheading.vue'
import DropdownList from '@hypefactors/shared/js/components/core/DropdownList'
import DelimitedList from '@hypefactors/shared/js/components/core/DelimitedList'
import RoundAvatar from '@hypefactors/shared/js/components/core/RoundAvatar.vue'
import CountryPicker from '@hypefactors/shared/js/components/core/CountryPicker'
import LanguagePicker from '@hypefactors/shared/js/components/core/LanguagePicker'
import BackgroundImage from '@hypefactors/shared/js/components/core/BackgroundImage'
import HorizontalField from '@hypefactors/shared/js/components/forms/HorizontalField'
import ClippingTypeIcon from '@hypefactors/shared/js/components/clippings/ClippingTypeIcon'
import DashboardFiltersV2 from '@hypefactors/shared/js/components/filters/DashboardFiltersV2'
import DashboardFiltersIcon from '@hypefactors/shared/js/components/filters/DashboardFiltersIcon'
import DashboardFiltersV2Item from '@hypefactors/shared/js/components/filters/DashboardFiltersV2Item'
import ResponsiveTable from '@hypefactors/shared/js/components/tables/ResponsiveTable'

Vue.use(SocialSharing)

Vue.component('Tier', Tier)
Vue.component('VIcon', VIcon)
Vue.component('Square', Square)
Vue.component('VImage', VImage)
Vue.component('heading', Heading)
Vue.component('v-button', VButton)
Vue.component('DataCard', DataCard)
Vue.component('load-more', LoadMore)
Vue.component('authorize', Authorize)
Vue.component('round-icon', RoundIcon)
Vue.component('subheading', Subheading)
Vue.component('v-separator', VSeparator)
Vue.component('el-scrollbar', scrollbar)
Vue.component('RoundAvatar', RoundAvatar)
Vue.component('HelpTooltip', HelpTooltip)
Vue.component('inline-field', InlineField)
Vue.component('country-flag', CountryFlag)
Vue.component('BandedSection', BandedSection)
Vue.component('DelimitedList', DelimitedList)
Vue.component(DropdownList.name, DropdownList)
Vue.component('country-picker', CountryPicker)
Vue.component('currency-picker', CurrencyPicker)
Vue.component('language-picker', LanguagePicker)
Vue.component('BackgroundImage', BackgroundImage)
Vue.component('restricted-button', RestrictedButton)
Vue.component('clipping-type-icon', ClippingTypeIcon)
Vue.component('authorized-fallback', AuthorizedFallback)
Vue.component(HorizontalField.name, HorizontalField)
Vue.component(PageSection.name, PageSection)
Vue.component('FroalaWrapper', () => import(/* webpackChunkName: "FroalaWrapper" */ '@/components/forms/FroalaWrapper'))
Vue.component('ClippingPreviewModal', () => import(/* webpackChunkName: "ClippingPreviewModal" */ '@hypefactors/shared/js/components/clippings/ClippingPreviewModal.vue'))
Vue.component('CroppieWrapper', () => import(/* webpackChunkName: "CroppieWrapper" */ '@hypefactors/shared/js/components/core/CroppieWrapper.vue'))
Vue.component('FakeDataRibbon', () => import(/* webpackChunkName: "FakeDataRibbon" */ '@hypefactors/shared/js/components/core/FakeDataRibbon'))
Vue.component('UpgradeNowNotice', () => import(/* webpackChunkName: "UpgradeNowNotice" */ '@/components/core/UpgradeNowNotice'))
Vue.component('basic-select', () => import(/* webpackChunkName: "BasicSelect" */ '@hypefactors/shared/js/components/core/BasicSelect'))
Vue.component('FakeDataImagePlaceholder', () => import(/* webpackChunkName: "FakeDataImagePlaceholder" */ '@/components/core/FakeDataImagePlaceholder'))
Vue.component('FileUploader', () => import(/* webpackChunkName: "FileUploader" */ '@hypefactors/shared/js/components/forms/FileUploader'))

Vue.component(Layout.name, Layout)
Vue.component(DefaultLayout.name, DefaultLayout)
Vue.component(VPanel.name, VPanel)
Vue.component(BrandsPicker.name, BrandsPicker)
Vue.component(DashboardFiltersIcon.name, DashboardFiltersIcon)
Vue.component(DashboardFiltersV2Item.name, DashboardFiltersV2Item)
Vue.component(DashboardFiltersV2.name, DashboardFiltersV2)
Vue.component(ResponsiveTable.name, ResponsiveTable)
