<template>
  <div class="DashboardFiltersV2 is-flex is-aligned-middle can-wrap p-a-s has-background-dotted">
    <div class="DashboardFiltersV2__Primary is-width-full-mobile">
      <slot name="primary" />
    </div>
    <div class="DashboardFiltersV2__Secondary" :class="{ 'p-r-none': !$slots.tertiary }">
      <slot name="secondary" />
    </div>
    <template v-if="$slots.tertiary">
      <div class="is-narrow">
        <VButton
          ref="tertiaryToggler"
          class="is-unstyled is-hidden-desktop is-size-5"
          type="button"
          @click="toggleVisibility"
        >
          <span>{{ $t('components.main_dashboard.dashboard_filters.refine') }}</span>
          <span class="icon">
            <VIcon type="levels" size="lg" />
          </span>
        </VButton>
      </div>
      <div
        v-click-away="handleClickAway"
        class="DashboardFiltersV2__Tertiary"
        :class="{ isVisible: isExpandedOnMobile }"
      >
        <div class="DashboardFiltersV2__TertiaryMeta is-flex is-aligned-justify is-hidden-desktop">
          <Heading size="3" :serif="false">
            {{ $t('components.main_dashboard.dashboard_filters.extra_options') }}
          </Heading>
          <VButton
            class="is-unstyled"
            @click.prevent="toggleVisibility(false)"
          >
            <span class="icon">
              <VIcon type="times-thin" />
            </span>
          </VButton>
        </div>
        <slot name="tertiary" />
      </div>
    </template>
  </div>
</template>

<script>
import Heading from '@hypefactors/shared/js/components/core/Heading'
import VButton from '@hypefactors/shared/js/components/core/VButton'
import VIcon from '@hypefactors/shared/js/components/core/VIcon'

export default {
  name: 'DashboardFiltersV2',
  components: {
    Heading,
    VButton,
    VIcon
  },
  data () {
    return {
      isExpandedOnMobile: false
    }
  },
  methods: {
    toggleVisibility (state = !this.isExpandedOnMobile) {
      this.isExpandedOnMobile = state
    },
    handleClickAway (event) {
      if (this.$refs.tertiaryToggler.contains(event.target)) return
      this.toggleVisibility(false)
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~utils';

.DashboardFiltersV2 {
  &__Primary {
    // we set a large flex here, so the tertiary can go to a new row and expand to a full width via flex 1.
    flex: 100 0 auto;
  }

  &__Secondary {
    display: flex;
    align-items: center;
    flex: 1 0 auto;

    @include tablet {
      flex: 0 0 auto;
      padding: 0 $padding-medium 0 $padding-small;
      @include rtl {
        padding: 0 $padding-small 0 $padding-medium;
      }
    }
  }

  &__Tertiary {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $hf__color-gray--light;
    z-index: 11;
    transform: translateX(120%);
    @include transit();

    &.isVisible {
      transform: translateX(0);
    }

    &Meta {
      padding: $padding;
    }

    @include tablet-only {
      left: auto;
      width: 350px;
      box-shadow: 1px 0 7px $hf__color-black;
    }
    @include desktop {
      position: static;
      transform: translate(0) !important;
      background: transparent;
      padding: 0;
      flex: 1 1 auto;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
    }
  }
}
</style>
