<template>
  <el-popover
    placement="bottom"
    trigger="hover"
    width="250"
    :disabled="!$slots.default && !content"
  >
    <VIcon slot="reference" type="question-balloon-o" />
    <slot>
      {{ content }}
    </slot>
  </el-popover>
</template>

<script>
import VIcon from '@hypefactors/shared/js/components/core/VIcon'

export default {
  name: 'HelpTooltip',
  components: {
    VIcon
  },
  props: {
    content: {
      type: String,
      default: ''
    }
  }
}
</script>
