<template>
  <div class="">
    <the-header />
    <div class="App">
      <brands-sidebar />
      <div class="App__content-wrapper">
        <div class="App__content container">
          <div class="columns is-variable-grid is-1">
            <div class="column is-9">
              <slot />
            </div>
            <div class="column is-3">
              <streams-sidebar v-show="authorizeBool('streams.list')" />
              <template v-if="!authorizeBool('streams.list')">
                <streams-sidebar-upgrade-now />
                <fake-data-ribbon size="small">
                  <fake-data-image-placeholder
                    type="streams_sidebar"
                    show-only="mobile"
                  />
                </fake-data-ribbon>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <clipping-preview-modal />
    <the-no-brands-dialog v-if="$store.getters.brands.length === 0" />
    <video-dialog />
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader'
import BrandsSidebar from '@/components/sidebars/BrandsSidebar'
import TheNoBrandsDialog from '@/components/TheNoBrandsDialog'
import VideoDialog from '../../components/core/VideoDialog'

const StreamsSidebar = () => import(/* webpackChunkName: "StreamsSidebar" */ '@/components/sidebars/StreamsSidebar')
const StreamsSidebarUpgradeNow = () => import(/* webpackChunkName: "StreamsSidebarUpgradeNow" */ '@/components/sidebars/StreamsSidebarUpgradeNow')

/**
 * Renders the default Hypefactors layout with Sidebar and Navigation
 * @module Default
 */
export default {
  name: 'Default',

  components: {
    VideoDialog,
    TheNoBrandsDialog,
    StreamsSidebarUpgradeNow,
    TheHeader,
    BrandsSidebar,
    StreamsSidebar
  }
}
</script>
