import { api } from './ApiInstanceInjector'

export const UserApiService = {
  fetchUser (options) {
    return api.getData('user', options)
  },
  fetchOrganisations (options) {
    return api.getData('user/organisations', options)
  },
  fetchBrands (options) {
    return api.getData('user/brands', options)
  },
  fetchUserBrandPermissions (brandId, options) {
    return api.getData(`user/brands/${brandId}/permissions`, options)
  },
  updateBrandsOrder (brands, options) {
    return api.postData('user/brands/update-order', brands, options)
  },
  removeSelfFormBrand (brandId, options) {
    return api.postData(`user/brands/${brandId}/detach`, options)
  },
  updateUser (userData, options) {
    return api.patchData('user', userData, options)
  },
  fetchAvailableStreams (options) {
    return api.getData('user/available-streams', options)
  }
}
