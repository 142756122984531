import Vue from 'vue'
import Vuelidate from 'vuelidate'
import VueClipboards from 'vue-clipboards'
import YouTubeEmbed from 'vue-youtube-embed'
import VueMultianalytics from 'vue-multianalytics'
import VueComposition from '@vue/composition-api'

import { sync } from 'vuex-router-sync'
import { VLazyImagePlugin } from 'v-lazy-image'

import { EchoInstance } from '@hypefactors/shared/js/services/SocketService'
import { VueEcho } from '@hypefactors/shared/js/plugins/echo'

import store from '@/store'
import router from '@/router'

sync(store, router)

// Vue.use(VueObserveVisibility)
Vue.use(Vuelidate)
Vue.use(VueClipboards)
Vue.use(VLazyImagePlugin)
Vue.use(YouTubeEmbed)
Vue.use(VueComposition)
Vue.use(VueEcho, EchoInstance)

/* Enable analytics only for none CI env */
if (!['ci', 'development'].includes(process.env.VUE_APP_ENV)) {
  const gaConfig = {
    appName: 'Hype.news', // Mandatory
    appVersion: '1', // Mandatory
    trackingId: 'UA-68682856-4' // Mandatory
  }

  const facebookConfig = {
    token: '1723714561184387'
  }

  Vue.use(VueMultianalytics, {
    modules: {
      ga: gaConfig,
      facebook: facebookConfig,
      segment: {
        token: process.env.VUE_APP_SEGMENT_KEY,
        debug: process.env.VUE_APP_ENV !== 'production'
      }
    },
    routing: {
      vueRouter: router
    }
  })
} else {
  Vue.prototype.$ma = {
    trackEvent: () => {},
    setUsername: () => {},
    reset: () => {}
  }
}
