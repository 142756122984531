import {
  ADD_FILTER,
  SET_FILTER,
  REMOVE_FILTER,
  CLEAR_FILTERS
} from '../mutations'

const state = {
  filters: [],
  filtersPresets: {
    dashboard: ['start', 'end', 'brands', 'country'],
    story: ['newsrooms', 'languages'],
    coverage: ['start', 'end', 'tier', 'type', 'sentiment', 'tag', 'country', 'search'],
    newsroom: ['countries'],
    newsroomFacts: ['start', 'end']
  }
}

function filterToObject (filters) {
  return filters.reduce((all, current) => {
    all[current.name] = current.value
    return all
  }, {})
}

function filterToQueryString (filters) {
  return filters.map(filter => {
    // Workaround for tags, where these should be sent as an array
    if (filter.name === 'tag') {
      return filter.value.map(tag => `tag[]=${tag}`).join('&')
    }

    return `${filter.name}=${encodeURIComponent(filter.value)}`
  })
    .join('&')
}

const getters = {
  hasFilter: state => value => {
    return state.filters.some(f => f.name === value)
  },

  hasFilters (state) {
    return !!state.filters.length
  },

  filters (state) {
    return state.filters
  },

  filtersPreset: state => type => {
    return state.filtersPresets[type] || []
  },

  filterFilters: state => (include, exclude) => {
    return state.filters
      .filter(filter => {
        if (include && Array.isArray(include)) return include.includes(filter.name)

        if (exclude && Array.isArray(exclude)) return !exclude.includes(filter.name)

        return true
      })
  },

  filtersQuery (state) {
    return filterToQueryString(state.filters)
  },

  filtersQueryFiltered: (state, getters) => (include, exclude) => {
    return filterToQueryString(getters.filterFilters(include, exclude))
  },

  filtersQueryObject: (state, getters) => (include, exclude) => {
    return filterToObject(getters.filterFilters(include, exclude))
  },

  /**
   * Returns a filter object or query string by a predefined preset
   * @param {String} preset
   * @param {boolean} [returnString = true]
   * @return {object | string}
   */
  filtersQueryFor: (state, getters) => (preset, returnString = true) => {
    const filters = getters.filterFilters(getters.filtersPreset(preset))
    return returnString ? filterToQueryString(filters) : filterToObject(filters)
  },

  getFilter: (state, getters) => (value, fallback) => {
    return getters.hasFilter(value) ? state.filters.find(f => f.name === value)['value'] : fallback
  }
}

const actions = {
  addFilter ({ commit }, filter) {
    commit(ADD_FILTER, filter)
  },

  setFilter ({ commit }, filter) {
    commit(SET_FILTER, filter)
  },

  removeFilter ({ commit }, filter) {
    commit(REMOVE_FILTER, filter)
  },

  clearFilters ({ commit }) {
    commit(CLEAR_FILTERS)
  }
}

const mutations = {
  [ADD_FILTER] (state, filter) {
    // add only if the value is proper and it's not an empty array
    if (filter.value && !(Array.isArray(filter.value) && !filter.value.length)) {
      state.filters.push(filter)
    }
  },

  [SET_FILTER] (state, filter) {
    mutations[REMOVE_FILTER](state, filter.name)
    mutations[ADD_FILTER](state, filter)
  },

  [REMOVE_FILTER] (state, filterName) {
    state.filters = state.filters
      .filter(entry => entry.name !== filterName)
  },

  [CLEAR_FILTERS] (state) {
    state.filters = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
