<template>
  <el-dialog
    :visible.sync="isVisible"
    class="VideoDialog el-dialog--is-paddingless"
    width="400"
    @close="$refs.player.player.stopVideo()"
  >
    <youtube
      ref="player"
      :video-id="videoId"
      :player-height="400"
      :player-vars="{ autoplay: 1 }"
    />
  </el-dialog>
</template>

<script>
/**
 * @module VideoDialog
 */
export default {
  name: 'VideoDialog',
  data () {
    return {
      isVisible: false,
      videoId: ''
    }
  },
  mounted () {
    this.$bus.$on('VideoDialog:open', this.openAndPlay)
    this.$on('hook:beforeDestroy', () => this.$bus.$off('VideoDialog:open'))
  },
  methods: {
    openAndPlay (id) {
      this.videoId = id
      this.isVisible = true
    }
  }
}
</script>
<style lang="scss">
@import "~utils";

.VideoDialog {
  .el-dialog__body {
    min-height: 400px;
  }

  .el-dialog__headerbtn {
    top: -30px;

    .el-dialog__close {
      color: $hf__color-white !important;
    }
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
