import Echo from 'laravel-echo'

function subscribeTo (channel) {
  if (channel.startsWith('private:')) {
    return this.$echo.private(channel.replace('private:', ''))
  } else if (channel.startsWith('presence:')) {
    return this.$echo.join(channel.replace('presence:', ''))
  }
  return this.$echo.channel(channel)
}

export const VueEcho = {
  install (Vue, options) {
    if (!options) {
      throw new Error('[Vue-Echo] cannot locate options')
    }

    if (typeof options !== 'object') {
      throw new Error('[Vue-Echo] cannot initiate options')
    }

    if (typeof options.socketId === 'function') {
      Vue.prototype.$echo = options
    } else {
      Vue.prototype.$echo = new Echo(options)
    }

    Vue.mixin({
      created () {
        let channels = this.$options['channels']
        if (!channels) return
        if (typeof channels === 'function') {
          channels = channels.call(this)
        }
        // save the channel names on the instance. We use them to destroy later
        this.$_echoChannelNames = Object.keys(channels)
        // Transform to array
        channels = Object.entries(channels)
        this.$channels = {}
        channels.forEach(([channelName, events]) => {
          const channel = subscribeTo.call(this, channelName)
          this.$channels[channelName] = channel
          // loop the events
          Object.entries(events).forEach(([key, method]) => {
            // listen to each event
            channel.listen(key, (payload) => {
              // if its a string, its a method on the instance
              if (typeof method === 'string') {
                this[method](payload)
                // if its a function, call it
              } else if (typeof method === 'function') {
                method.call(this, payload)
              }
            })
          })
        })
      },
      beforeDestroy () {
        let channels = this.$_echoChannelNames

        if (!channels) return

        channels.forEach((channel) => {
          channel = channel.replace(/^(private:|presence:)/, '')
          this.$echo.leave(channel)
        })
      }
    })
  }
}
