import Highcharts from 'highcharts'
import noData from 'highcharts/modules/no-data-to-display'
import _merge from 'lodash/merge'
import { defaultColors } from '../DefaultChartColors'

// Adds the noData message display
noData(Highcharts)

export default class {
  constructor (ref, series) {
    this.ref = ref
    this.series = series
  }

  init () {
    this.instance = Highcharts.chart(
      this.ref,
      this.mergeOptions(this.defaultOptions(), this.options)
    )
  }

  /**
   * Updates the chart instance's series, title and categories
   * @param {Array} series - series to update
   * @param {String | null} [title]
   * @param {Array} [categories]
   */
  update (series, title = null, categories) {
    // remove all the series
    while (this.instance.series.length > 0) {
      this.instance.series[0].remove(false)
    }

    // check if the title needs to be updated
    if (title !== null) {
      this.instance.setTitle({ text: title }, false)
    }

    // add all the new series, without redrawing
    series.forEach(set => {
      this.instance.addSeries(set, false)
    })

    // Add the categories, without redrawing
    if (categories) {
      this.instance.xAxis[0].setCategories(categories, false)
    }

    // Finally redraw the whole instance
    this.instance.redraw()
  }

  destroy () {
    this.instance.destroy()
  }

  setOptions (options) {
    this.options = options
  }

  defaultOptions () {
    return {
      title: {
        style: { fontSize: '2rem', fontWeight: '900', fontFamily: '"Open Sans", sans-serif' }
      },
      colors: defaultColors,
      series: this.series,
      credits: { enabled: false },
      plotOptions: {
        series: {
          minPointLength: 3,
          marker: {
            radius: 3
          },
          animation: false
        }
      },
      lang: {
        noData: this.defaultNoDataMessage()
      }
    }
  }

  defaultNoDataMessage () {
    return 'No Data To Display'
  }

  mergeOptions (defaults, overrides) {
    return _merge(defaults, overrides)
  }
}
