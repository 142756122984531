<template>
  <div class="Authorize">
    <template v-if="hasAuthorization['can']">
      <slot />
    </template>
    <template v-else-if="!noFallback">
      <slot name="fallback">
        <authorized-fallback :permission="hasAuthorization" />
      </slot>
    </template>
  </div>
</template>

<script>

export default {
  props: {
    permission: {
      type: [String, Boolean],
      required: true
    },
    noFallback: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasAuthorization () {
      return this.authorize(this.permission)
    }
  }
}
</script>
