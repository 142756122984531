import api from '@/services/Api'

export const CANCEL_ENDPOINT = organisationId => `/billing/${organisationId}/subscription/cancel`
export const RESUME_ENDPOINT = organisationId => `/billing/${organisationId}/subscription/resume`
export const UPGRADE_ENDPOINT = organisationId => `/billing/${organisationId}/subscription/swap-plan`
export const APPLY_COUPON_ONLY_ENDPOINT = organisationId => `/billing/${organisationId}/subscription/apply-coupon`

export const ATTACH_PAYMENT_METHOD_ENDPOINT = organisationId => `/billing/${organisationId}/payment-method/attach`
export const DETACH_PAYMENT_METHOD_ENDPOINT = organisationId => `/billing/${organisationId}/payment-method/detach`

export const FETCH_INVOICES_ENDPOINT = organisationId => `/billing/${organisationId}/invoices`

export const CHECK_COUPON_VALIDITY_ENDPOINT = organisationId => `/billing/${organisationId}/check-coupon-validity`

export const FETCH_ENTERPRISE_REQUESTS_ENDPOINT = () => '/staff/billing/enterprise-enquiries'
export const UPDATE_ENTERPRISE_REQUEST_ENDPOINT = (requestId) => `/staff/billing/enterprise-enquiries/${requestId}`
export const FETCH_PLAN_FEATURES_ENDPOINT = '/billing/features'
export const GET_PLANS_ENDPOINT = '/billing/plans'
export const ENTERPRISE_INQUIRY_ENDPOINT = '/billing/enterprise-enquiry'
export const SETUP_INTENT_ENDPOINT = '/billing/start-session'

let cancelToken

export const BillingApiService = {
  cancel (organisationId, payload) {
    return api.post(CANCEL_ENDPOINT(organisationId), payload)
  },
  upgrade (organisationId, payload) {
    return api.post(UPGRADE_ENDPOINT(organisationId), payload)
  },
  fetchPlans (params) {
    return api.get(GET_PLANS_ENDPOINT, params).then(response => response.data.data)
  },
  updateCard (orgId, payload) {
    return api.post(ATTACH_PAYMENT_METHOD_ENDPOINT(orgId), payload)
  },
  deleteCard (orgId, payload) {
    return api.delete(DETACH_PAYMENT_METHOD_ENDPOINT(orgId), payload)
  },
  fetchInvoices (orgId, payload) {
    return api.get(FETCH_INVOICES_ENDPOINT(orgId), payload).then((response) => response.data.data)
  },
  reactivate (organisationId) {
    return api.post(RESUME_ENDPOINT(organisationId))
  },
  fetchEnterpriseRequests (params) {
    return api.get(FETCH_ENTERPRISE_REQUESTS_ENDPOINT(), params).then(r => r.data.data)
  },
  updateEnterpriseRequest (requestId, payload) {
    return api.put(UPDATE_ENTERPRISE_REQUEST_ENDPOINT(requestId), payload)
  },
  fetchPlanFeatures () {
    return api.get(FETCH_PLAN_FEATURES_ENDPOINT).then(r => r.data.data)
  },
  /**
   * Checks if a coupon is valid.
   * Cancels previous call
   * @param organisation
   * @param coupon
   * @return {Promise<any>}
   */
  checkCouponValidity (organisation, coupon) {
    if (cancelToken) cancelToken.cancel()
    cancelToken = api.cancelToken()
    return api.post(CHECK_COUPON_VALIDITY_ENDPOINT(organisation), { coupon }, { cancelToken: cancelToken.token })
      .then(r => r.data.data)
      .catch(error => {
        // eslint-disable-next-line no-throw-literal
        if (api.isCancelToken(error)) throw 'cancel'
        throw error
      })
  },
  applyCouponToPlan (organisation, coupon) {
    return api.post(APPLY_COUPON_ONLY_ENDPOINT(organisation), { coupon })
  },
  initiateSetupIntent () {
    return api.postData(SETUP_INTENT_ENDPOINT).then(r => r.client_secret)
  }
}
