import store from '@/store'
import { load, persist, handleError, getUrlToDomain } from '@/utils'
import _safeGet from 'lodash/get'
import { addActiveCampaignTracking, removeActiveCampaingTracking } from '@hypefactors/shared/js/utils/trackers'
import { getFallbackNextUrl } from '@hypefactors/shared/js/utils/routerUtilities'

/**
 * Reloads the browser if deployment has occurred while a user is online and a route is not blacklisted.
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
export function reloadOnDeploy (to, from, next) {
  // check if FORCE_RELOAD is set in localStorage to `true`
  const forceReload = load('FORCE_RELOAD', false)
  if (!forceReload) {
    // Continue as usual if not
    return next()
  }
  // Check if route is blacklisted
  const blackListed = to.matched.slice(0).reverse().some(route => _safeGet(route, 'meta.blacklisted', false))
  // If blacklisted, then we don't want a refresh, so we continue as normal
  if (blackListed) return next()
  persist('FORCE_RELOAD', false)
  window.location = to.fullPath
}

export function allowStaffOnly (to, from, next) {
  if (store.getters.isStaffMember) return next()
  return next(getFallbackNextUrl(from))
}

/**
 * Stops navigation to route if user has no permission to enter.
 * Infers the permission from the matched route meta.
 * If no permission is found, it passes.
 * @param {string} [permission]
 * @return {Function}
 */
export const authorizeFor = (permission) => (to, from, next) => {
  permission = permission || to.meta.permission
  if (permission && !store.getters.authorizeBool(permission)) {
    return next(getFallbackNextUrl(from))
  }
  next()
}

export const fetchAppBootData = async (to, from, next) => {
  // Vue-Router will re-fetch data if coming from a cancelled navigation.
  if (store.state.app.appDataLoaded) {
    return next()
  }

  if (!store.state.app.isOnMaintenanceMode) {
    // TODO: this could be a part of login procedure in the future
    store.commit('SET_INITIAL_LOADING', true)
    try {
      await Promise.all([
        store.dispatch('fetchUser'),
        store.dispatch('bootstrapAppData')
      ])
      store.commit('SET_INITIAL_LOADING', false)
      store.commit('SET_APP_DATA_LOADED', true)
      return next()
    } catch (err) {
      store.commit('SET_INITIAL_LOADING', false)
      // report the issue and logout the user
      handleError(err)
      next('/logout?reason=errored_on_app_bootstrap')
    }
  }
}

export const activeCampaignHook = (to, from) => {
  if (process.env.VUE_APP_ENV !== 'production') return
  removeActiveCampaingTracking()
  addActiveCampaignTracking(
    store.getters.user && store.getters.user.email,
    getUrlToDomain(from.fullPath, 'hypefactors', false),
    getUrlToDomain(to.fullPath, 'hypefactors', false)
  )
}
