import { createGetter, createSetter } from '@hypefactors/shared/js/utils/vuexUtilities'
import { SnapshotterService } from '@hypefactors/shared/js/services/SnapshotterApiService'
import { getUrlToDomain } from '@hypefactors/shared/js/utils'

const state = () => ({
  // Dialogs
  PRPreviewDialogVisibility: false,
  sendToJournalistDialogVisibility: false,
  // State for SentToJournalistDialog
  storyHasFiles: false,
  // PR Preview state
  storyId: '',
  storyUpdatedAt: ''
})

const getters = {
  PRStoryPreviews: createGetter('PRStoryPreviews'),
  PRPreviewDialogVisibility: createGetter('PRPreviewDialogVisibility'),
  sendToJournalistDialogVisibility: createGetter('sendToJournalistDialogVisibility'),
  storyHasFiles: createGetter('storyHasFiles'),
  storyId: createGetter('storyId'),
  prStoryHypenewsUrl (state) {
    return getUrlToDomain(`/story_press_releases/${state.storyId}`, 'hypenews', false)
  },
  prPreviewPDF (state, getters) {
    return SnapshotterService.generatePrStoryPDF(getters.prStoryHypenewsUrl, state.storyUpdatedAt)
  },
  prPreviewImage (state, getters) {
    return SnapshotterService.generatePrStoryImage(getters.prStoryHypenewsUrl, state.storyUpdatedAt)
  }
}

const mutations = {
  // Dialog setters
  SET_PR_PREVIEW_DIALOG_VISIBILITY: createSetter('PRPreviewDialogVisibility'),
  SET_SEND_TO_JOURNALIST_DIALOG_VISIBILITY: createSetter('sendToJournalistDialogVisibility'),
  // SendToJournalistDialog Setters
  SET_STORY_HAS_FILES: createSetter('storyHasFiles'),
  // PR Preview Setters
  SET_STORY_ID: createSetter('storyId'),
  SET_STORY_UPDATED_AT: createSetter('storyUpdatedAt')
}

const actions = {
  setPrPreviewData ({ commit }, { storyId, storyUpdatedAt }) {
    commit('SET_STORY_ID', storyId)
    commit('SET_STORY_UPDATED_AT', storyUpdatedAt)
  },
  openSendToJournalistDialog ({ commit, dispatch }, { storyId, storyHasFiles, storyUpdatedAt }) {
    dispatch('setPrPreviewData', { storyId, storyUpdatedAt })
    commit('SET_STORY_HAS_FILES', storyHasFiles)
    commit('SET_SEND_TO_JOURNALIST_DIALOG_VISIBILITY', true)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
