<template>
  <VIcon :type="computedType" :fallback="fallback" />
</template>

<script>
import VIcon from '@hypefactors/shared/js/components/core/VIcon'

import { MEDIA_TYPE_ICONS } from '@hypefactors/shared/js/constants/mediaTypes'

export default {
  name: 'ClippingTypeIcon',

  components: {
    VIcon
  },

  props: {
    type: {
      type: String,
      required: true
    },

    fallback: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    computedType () {
      const type = this.type

      return MEDIA_TYPE_ICONS[type] || type
    }
  }

}
</script>
