import { createSetter } from '@hypefactors/shared/js/utils/vuexUtilities'
import { BillingApiService } from '@/services/api/BillingApiService'
import { PaymentPlan as PaymentPlanModel } from '@/models/PaymentPlan'

const state = {
  /**
   * Currently chosen plan slug
   */
  plan: '',
  /**
   * @type HF_PaymentPlan[]
   */
  paymentPlans: [],
  fetchingPaymentPlans: false,
  /**
   * Number of licenses/users
   */
  users: 1,
  /**
   * @type {string<year|month>}
   */
  interval: 'year', // or month
  intervals: ['year', 'month'],

  couponId: null,
  couponType: null, // percentage or value
  couponValue: 0,
  validatingCoupon: false,
  /** Read Only Users **/
  amountOfReadOnlyUsers: 0,
  /**
   * TODO: still not implemented on API. Will change in the future.
   */
  readOnlyUserPrice: {
    year: 41.58,
    month: 49
  }
}

const getters = {
  /* prices */
  /**
   * Returns the amount of installments for the interval
   * @return {number}
   */
  installment (state) {
    return state.interval === 'month' ? 1 : 12
  },
  /**
   * Returns the price for the plan per interval
   * @return {number}
   */
  currentPlanPrice (state, getters) {
    return getters.getCurrentPlanCommitment ? getters.getCurrentPlanCommitment.amount : 0
  },
  currentPlanCurrency (state, getters) {
    return getters.getCurrentPlanCommitment ? getters.getCurrentPlanCommitment.currency : ''
  },
  /**
   * Returns the cost of a single read only user for the intervla
   * @return {number}
   */
  singleReadOnlyUserCost (state) {
    return state.readOnlyUserPrice[state.interval]
  },
  /**
   * Returns the cost of a single read only user for all installments
   * @return {number}
   */
  readOnlyUserCostForInterval (state, getters) {
    return getters.singleReadOnlyUserCost * getters.installment
  },
  /**
   * Returns the cost of the current plan just for one installment
   * @return {number}
   */
  currentPlanPricePerInstallment (state, getters) {
    // The price is currently returned as is from the API
    return getters.currentPlanPrice / getters.installment
  },
  /**
   * Returns the total cost for all read only users
   * @return {number}
   */
  readOnlyUsersCost (state, getters) {
    return state.amountOfReadOnlyUsers * getters.readOnlyUserCostForInterval
  },
  /**
   * Returns the price of the current plan for one installment for all user
   * @param state
   * @param getters
   * @return {number}
   */
  totalCostForUsersPerInstallment (state, getters) {
    return getters.currentPlanPricePerInstallment * state.users
  },
  /**
   * Returns the total cost for all users
   * @return {number}
   */
  totalCostForUsers (state, getters) {
    return getters.currentPlanPrice * state.users
  },
  /**
   * Returns whether we have a coupon or not
   * @param state
   * @param getters
   * @return {boolean}
   */
  hasCoupon (state, getters) {
    return !!state.couponId
  },
  /**
   * Return the amount of money saved from applying the coupon
   * @param state
   * @param getters
   * @return {number}
   */
  couponSavings (state, getters) {
    if (state.couponType === 'percentage') return (getters.totalCostWithoutDiscounts * state.couponValue / 100)
    if (state.couponType === 'value') return state.couponValue
    return 0
  },
  /**
   * Returns the total cost of the plan for the chosen users without any discounts
   * @param state
   * @param getters
   * @return {number}
   */
  totalCostWithoutDiscounts (state, getters) {
    return getters.totalCostForUsers + getters.readOnlyUsersCost
  },
  /**
   * Returns the total cost for the plan for all users and readonly users with discounts applied
   * @return {number}
   */
  totalCost (state, getters) {
    return getters.totalCostWithoutDiscounts - getters.couponSavings
  },

  /* plans */
  /**
   * Returns a boolean whether the plans are fetched or not
   * @return {boolean}
   */
  arePaymentPlansFetched (state) {
    return !!state.paymentPlans.length
  },
  /**
   * Returns plans with just the current interval commitment
   * @param state
   * @return {HF_PaymentPlanTransformed[]}
   */
  paymentPlansForInterval (state) {
    return state.paymentPlans
      .map(plan => {
        const commitment = plan.commitments.find(c => c.interval === state.interval)
        return {
          name: plan.name,
          slug: plan.slug,
          commitment: commitment || plan.commitments[0]
        }
      })
  },
  /**
   * Returns only the plans, that are actually billable - Pro and Plus
   * @return {HF_PaymentPlanTransformed[]}
   */
  billablePaymentPlansForInterval (state, getters) {
    return getters.paymentPlansForInterval.filter(plan => plan.commitment && plan.commitment.amount)
  },
  /**
   * Returns a hash map of Plans
   * @returns {Object.<HF_PaymentPlanTransformed>} - List of Transformed payment plans
   */
  paymentPlansMapForInterval (state, getters) {
    return getters.paymentPlansForInterval
      .reduce((all, current) => {
        all[current.slug] = current
        return all
      }, {})
  },
  /**
   * Returns the commitment of the chosen plan for the current interval
   * @param {string} plan - the plan to search for
   * @return {Function<HF_PaymentPlanCommitment>}
   */
  getPlanCommitmentFor: (state, getters) => (plan) => {
    if (!getters.arePaymentPlansFetched) return null
    const fetchedPlan = getters.paymentPlansMapForInterval[plan]
    return fetchedPlan ? fetchedPlan.commitment : null
  },
  /**
   * Returns the commitment for the currently chosen plan
   * @return {HF_PaymentPlanCommitment}
   */
  getCurrentPlanCommitment (state, getters) {
    return getters.getPlanCommitmentFor(state.plan)
  }
}

const mutations = {
  SET_PAYMENT_PLAN: createSetter('plan'),
  SET_INTERVAL: createSetter('interval'),
  SET_USERS (state, value) {
    if (isNaN(value) || value < 1) return
    state.users = Math.round(value)
  },
  SET_READ_ONLY_USERS (state, value) {
    if (isNaN(value) || value < 0) return
    state.amountOfReadOnlyUsers = value
  },
  SET_PAYMENT_PLANS (state, value) {
    state.paymentPlans = value.map(prod => new PaymentPlanModel(prod))
  },
  SET_FETCHING_PLANS: createSetter('fetchingPaymentPlans'),
  /**
   * Sets the coupon from the Api response
   * @param state
   * @param {{ id: string, percent_off: ?number, amount_off: ?number }} value
   * @constructor
   */
  SET_COUPON (state, value) {
    state.couponId = value.id
    state.couponType = value.percent_off ? 'percentage' : 'value'
    state.couponValue = value.percent_off || value.amount_off
  },
  /**
   * Clears out a coupon
   * @param state
   * @constructor
   */
  CLEAR_COUPON (state) {
    state.couponId = null
    state.couponType = null
    state.couponValue = 0
  },
  SET_VALIDATING_COUPON: createSetter('validatingCoupon')
}

const actions = {
  fetchPlans ({ commit, state }) {
    if (state.paymentPlans.length) return Promise.resolve()
    commit('SET_FETCHING_PLANS', true)
    return BillingApiService.fetchPlans().then((response) => {
      commit('SET_PAYMENT_PLANS', response)
    }).finally(() => {
      commit('SET_FETCHING_PLANS', false)
    })
  },
  /**
   * Checks if a coupon is valid.
   * If so, saves it automatically,
   * otherwise clears the coupon and re-throws error
   * @param {string} coupon
   * @return {*}
   */
  checkCoupon ({ commit, state, rootGetters }, coupon) {
    if (coupon === state.couponId) return
    // clear the coupon if we remove it from the input
    if (!coupon) return commit('CLEAR_COUPON')
    commit('SET_VALIDATING_COUPON', true)
    return BillingApiService.checkCouponValidity(rootGetters.firstOrganisation.id, coupon)
      .then((couponResponse) => {
        commit('SET_COUPON', couponResponse)
      })
      .catch((error) => {
        commit('CLEAR_COUPON')
        throw error
      })
      .finally(() => {
        commit('SET_VALIDATING_COUPON', false)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
