<script>
import { storagePath } from '@hypefactors/shared/js/utils/index'

/**
 * Generates an Hypefactors Icon
 * @module VIcon
 */
export default {
  name: 'VIcon',
  functional: true,
  props: {
    type: {
      type: String,
      required: true
    },
    even: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: ''
    },
    fallback: {
      type: Boolean,
      default: false
    }
  },
  render: (h, { data, props, parent }) => {
    const icon = h('i', {
      ...data,
      class: [
        'hf',
        data.class,
        data.staticClass,
        {
          'hf-fw': props.even,
          ['hf-' + props.size]: props.size,
          ['hf-' + props.type]: props.type
        }
      ]
    })

    const imageSrc = storagePath('/images/icons/hf_font/' + props.type + '.svg')
    const image = h('img', {
      ...data,
      attrs: { ...data.attrs, src: imageSrc },
      class: [
        'v-icon-fallback',
        data.class
      ]
    })

    return props.fallback ? image : icon
  }
}
</script>
