import { round } from '../utils/numberUtils'

/**
 * @typedef {{ id: number, name: string, code: string, rate: number }} Currency
 */

/**
 * @typedef {Currency[]} CurrencyList
 */

/**
 * Gives basic currency conversion functionality.
 * Currencies are usually set post instantiation when they are fetched from API
 * @class
 */
export default class BaseCurrencyConverter {
  /**
   * @param {CurrencyList} list
   * @param {function(*): String} currencySymbol
   */
  constructor (list, currencySymbol = () => 'EUR') {
    this.list = list
    this.currencies = this._parseList(list)
    this.currencySymbol = currencySymbol
  }

  /**
   * Get the original collection
   * @returns {CurrencyList}
   */
  all () {
    return this.list
  }

  /**
   * Finds currency by the code.
   *
   * @param {string} code
   * @return {{name: (string| null), rate: (number|null)}}
   */
  find (code) {
    if (!this.currencies.hasOwnProperty(code)) {
      return { name: null, rate: null }
    }

    return this.currencies[code]
  }

  /**
   * Finds the latest currency rate by the code.
   *
   * @param {string} code
   * @returns {number}
   */
  findRate (code) {
    return this.find(code).rate
  }

  /**
   * Converts the amount from one currency to another.
   *
   * @param {number} amount
   * @param {string} from
   * @param {string} to
   * @returns {number}
   */
  convert (amount, from, to) {
    const fromRate = this.findRate(from)
    const toRate = this.findRate(to)

    if (!fromRate || !toRate) {
      return null
    }

    return round((amount / fromRate) * toRate)
  }

  /**
   * Method to auto infer the from and to currencies
   * @param {number} amount - amount to convert
   * @param {string} from - from currency. Usually DKK (form api)
   * @param {string} to - Current currency. Usually the active brand's currency
   * @return {number}
   */
  convertCurrency (amount, from, to) {
    from = from || 'DKK'
    to = to || this.currencySymbol()

    return this.convert(amount, from, to)
  }

  /**
   * Parses the input collection into a code keyed object.
   * @param {CurrencyList} list
   * @returns {Object.<string, Currency>}
   */
  _parseList (list) {
    return list.reduce((carry, currency) => {
      carry[currency.code] = currency

      return carry
    }, {})
  }

  /**
   * Sets the currencies
   * @param {CurrencyList} currencies
   */
  updateCurrencies (currencies) {
    this.currencies = this._parseList(currencies)
    this.list = currencies
  }
}
