import Store from '@/store'
import { load, localToUtcTimestamp } from '@hypefactors/shared/js/utils'
import { LocationService } from '@hypefactors/shared/js/services/LocationService'

export function attachAccessToken (config) {
  const accessToken = load('access_token', '')

  if (accessToken) {
    config.headers.common['Authorization'] = `Bearer ${accessToken}`
  }

  return config
}

export function unauthorizedInterceptor (err) {
  if (err.response && err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
    // temporary solution until refresh tokens are available
    const currentLoc = window.location.pathname + window.location.search
    // redirect only if the current route is not logout or login
    // TODO: User router instance for this
    if (currentLoc.match(/logout|login/) === null) {
      LocationService.replace(`/logout?redirect=${encodeURIComponent(currentLoc)}&reason=401`)
      return Promise.reject(err)
    }

    return Promise.reject(err)
  }

  return Promise.reject(err)
}

export function attachDemoDataHeader (config) {
  if (Store.getters.requestDemoData) {
    // TODO: Dobri 15.01.2019 pass array of selected brands for dashboard only, else just active brand id
    config.headers.common['HF-Demo-Data'] = Store.getters.activeBrandId
  }
  return config
}

export function metricsDates (config) {
  if ((config.params && config.params.start && config.params.end)) {
    config.params = {
      ...config.params,
      start: localToUtcTimestamp(config.params.start),
      end: localToUtcTimestamp(config.params.end)
    }
  } else if (config.url.includes('start=')) {
    const url = new URL(config.url, config.baseURL)
    url.searchParams.set('start', localToUtcTimestamp(url.searchParams.get('start')))
    url.searchParams.set('end', localToUtcTimestamp(url.searchParams.get('end')))
    config.url = url.toString().replace(config.baseURL, '')
  }
  return config
}
