import { api } from './ApiInstanceInjector'

/**
 * Fetches the categories with nested children
 * @returns {Promise}
 */
export function getAll () {
  return api.getData('categories', {
    params: { include: 'children' }
  })
}

/**
 * Fetches the categories in a flat array
 * @returns {Promise}
 */
export function getAllFlat () {
  return api.getData('categories/flat')
}
