<template functional>
  <div
    :class="[data.class, data.staticClass]"
    class="Subheading"
  >
    <h2>
      <slot>[Subheading]</slot>
    </h2>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import "~utils";

.Subheading {
  padding-top: $padding;
  padding-bottom: $padding;
  &.is-gapless {
    padding: 0;
  }
  h2 {
    font-size: $hf__font-notice;
    font-weight: 600;
    padding-bottom: $padding-tiny;
  }

  &.is-bold {
    h2 {
      font-weight: bold;
    }
  }

  &.is-underlined {
    h2 {
      border-bottom: 2px solid $grey;
    }
  }

  &.is-small {
    h2 {
      font-size: $hf__font-default;
    }
  }

  &.is-highlighted {
    h2 {
      font-size: $hf__font-highlighted;
      font-weight: 600;
      text-transform: uppercase;
    }

    & + .Subheading h2 {
      padding-top: 0;
    }
  }
}
</style>
