import { ClippingsApi, PREPARE_CLIPPING_URL } from '@/services/api/ClippingsApiService'
import { ClippingService } from '@/services/ClippingService'
import _get from 'lodash/get'
import { isAudio, isVideo } from '@hypefactors/shared/js/utils'
import parse from 'date-fns/parse'

const state = () => ({
  type: null,
  thumbnail: null,
  headline: null,
  translated_headline: null,
  duration: null,
  publishedAt: null,
  suggestedMediaOutletId: null,
  suggestedCountryId: null,
  files: [],
  preparedData: {},

  redirect: null,

  preparedClipping: null,
  clipping: null
})

const getters = {
  type (state) {
    return state.type
  },

  thumbnail (state) {
    return state.thumbnail
  },

  hasVideo (state, getters) {
    if (!getters.hasFiles) return false
    return isVideo(getters.files[0].url)
  },

  hasAudio (state, getters) {
    if (!getters.hasFiles) return false
    return isAudio(getters.files[0].url)
  },

  files (state) {
    return state.files
  },

  hasFiles (state, getters) {
    return !!getters.files.length
  },

  headline (state) {
    return state.headline
  },

  translatedHeadline (state) {
    return state.translated_headline
  },

  duration (state) {
    return state.duration
  },

  publishedAt (state) {
    return state.publishedAt
  },

  suggestedMediaOutletId (state) {
    return state.suggestedMediaOutletId
  },

  suggestedCountryId (state) {
    return state.suggestedCountryId
  },

  clipping (state) {
    return state.clipping
  },

  redirect (state) {
    return state.redirect
  },

  preparedClipping (state) {
    return state.preparedClipping
  },

  preparedData (state) {
    return state.preparedData
  }
}

const actions = {
  prepareClippingData ({ commit }, form) {
    return form.post(PREPARE_CLIPPING_URL, {
      'axios-retry': {
        retries: 3,
        retryDelay: (retryCount) => retryCount * 1000,
        retryCondition: (error) => {
          const statusCode = error.response ? error.response.status : null

          if ([403, 404].includes(statusCode)) {
            return false
          }

          return true
        }
      }
    }).then(response => {
      commit('SET_PREPARED_CLIPPING', ClippingService.clippingDataPrepFromResponse(response.data))
    })
  },

  /**
   * Sets up a clipping to be edited. Called from the ClippingListItem component.
   * Must be passed the whole clipping object.
   * @param commit
   * @param clipping
   */
  prepareClippingForEdit ({ commit }, clipping) {
    commit('SET_PREPARED_CLIPPING', clipping)
    return clipping
  },

  createClipping ({ state, dispatch }, form) {
    return form.post('clippings?include=country,relations,stories,kpi_campaigns,tags')
      .then(response => {
        return dispatch('prepareClippingForEdit', response.data)
      })
  },

  updateClipping ({ state, dispatch }, form) {
    return form.put(`clippings/${state.preparedClipping.id}?include=country,relations,stories,kpi_campaigns,tags`)
      .then(response => {
        return dispatch('prepareClippingForEdit', response.data)
      })
  },

  /**
   * Used to fetch a clipping and set it as "prepared"
   * @return {Promise}
   */
  fetchClipping ({ dispatch, state }, { id = state.preparedClipping.id, cancelToken }) {
    if (!id) {
      return Promise.reject(new Error('No initial clipping data available'))
    }

    return ClippingsApi.fetchClipping(id, {
      params: {
        include: ['relations', 'stories', 'kpi_campaigns', 'tags']
      },
      cancelToken
    })
      .then(response => {
        return dispatch('prepareClippingForEdit', response)
      })
  },

  setRedirect ({ commit }, redirect) {
    commit('SET_REDIRECT', redirect)
  },

  reset ({ commit }) {
    commit('RESET')
  }
}

const mutations = {
  SET_PREPARED_CLIPPING (state, preparedClipping) {
    state.preparedClipping = preparedClipping
    state.type = preparedClipping.type || null
    state.thumbnail = _get(preparedClipping, 'thumbnail.0', null)
    state.files = preparedClipping.files || []
    state.headline = preparedClipping.data.headline || null
    state.translated_headline = preparedClipping.data.translated_headline || null
    state.duration = preparedClipping.data.duration_sec || null
    state.publishedAt = parse(preparedClipping.published_at)
    // TODO: saving data as we have no idea what data it could include, so to be safe.
    state.preparedData = preparedClipping.data || {}

    // TODO: Inconsistency as Dataprep returns just outlet, where as Clipping Object has a media_outlet relation
    if (preparedClipping.media_outlet) {
      state.suggestedMediaOutletId = preparedClipping.media_outlet.data.id || null
    } else if (preparedClipping.outlet) {
      state.suggestedMediaOutletId = preparedClipping.outlet
    }

    if (preparedClipping.country) {
      state.suggestedCountryId = preparedClipping.country.data.iso_alpha2 || null
    } else if (preparedClipping.country_code) {
      state.suggestedCountryId = preparedClipping.country_code || null
    }
  },

  SET_REDIRECT (state, redirect) {
    state.redirect = redirect
  },

  RESET (baseState) {
    Object.assign(baseState, state())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
