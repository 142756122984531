<template>
  <div
    v-clickoutside="() => isToggled = false"
    class="NavigationBarWrapper is-flex is-aligned-middle"
  >
    <div class="NavigationBarMobileTrigger is-hidden-tablet has-text-white p-l-m">
      <v-icon
        type="menu"
        size="2x"
        @click="toggleNavBar"
      />
    </div>
    <div class="NavigationBarHolder is-width-full">
      <div class="is-hidden-tablet has-text-white has-text-weight-bold p-l-m">
        {{ currentItem ? currentItem.label: 'Hypefactors' }}
      </div>
      <ul
        :class="{ 'is-toggled': isToggled, [`is-${type}`]: true }"
        class="NavigationBar"
      >
        <li
          v-for="link in links"
          :key="link.label"
        >
          <template v-if="!link.isHidden">
            <template v-if="link.disabled">
              <a
                class="is-nav is-inactive"
                @click.prevent
              >
                {{ link.label }}
              </a>
            </template>

            <template v-else-if="link.megamenu">
              <a
                :class="{
                  'router-link-opened': link.key === activeLink,
                  'router-link-active': activeOverride({name: link.key}, link.exact)
                }"
                class="is-nav"
                @click.prevent="$emit('menu-selected', link.key)"
              >
                {{ link.label }}
              </a>
            </template>

            <template v-else>
              <router-link
                v-if="link.to"
                :to="link.to"
                :class="{ 'router-link-exact-active': activeOverride(link.to, link.exact) }"
                :data-testid="`menuItem${link.label}`"
                class="is-nav"
                @click.native="link.callback && link.callback()"
              >
                {{ link.label }}
              </router-link>
              <a
                v-if="link.href"
                :href="link.href"
                :data-testid="`menuItem${link.label}`"
                :target="link.target || '_self'"
                class="is-nav"
              >
                {{ link.label }}
              </a>
              <a
                v-if="link.action"
                :href="link.href"
                :data-testid="`menuItem${link.label}`"
                class="is-nav"
                @click.prevent="link.action()"
              >
                {{ link.label }}
              </a>
            </template>
          </template>
        </li>
        <slot />
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
      required: true
    },
    activeLink: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'primary'
    }
  },

  data () {
    return {
      isToggled: false
    }
  },

  computed: {
    currentItem () {
      return this.links.find((link) => {
        if (!link.to) return
        return this.activeOverride(link.to, link.exact)
      })
    }
  },

  watch: {
    '$route.fullPath': function () {
      this.isToggled = false
    }
  },

  methods: {
    /**
     * Checks if any of the parents has active class override.
     * @param link - Link to compare against
     * @param {Boolean} exact - do we want an exact match
     * @return {boolean}
     */
    activeOverride (link, exact = false) {
      // if we want an exact match, compare directly
      if (exact === true) return link.name === this.$route.name

      return this.$route.matched.some(record => {
        const markAsActive = record.meta.markAsActive || ''
        // check if the link name is contained in one of the parents
        const match = link.name === record.name
        // if its not contained and we dont have a markAsActive meta field, just return
        // if (!match || !record.meta.markAsActive) {
        //   return false
        // }
        return match || markAsActive.includes(link.name)
      })
    },
    toggleNavBar () {
      this.isToggled = !this.isToggled
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.NavigationBarWrapper {
  background-color: $hf__color-gray-darker;
  position: relative;
  border-top: 1px solid $hf__color-gray--light;

  &:last-of-type {
    border-bottom: 1px solid $hf__color-gray--light;
  }
}

.NavigationBar {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
  width: 100%;

  @include mobile {
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    width: 100%;
    background-color: $hf__color-gray-darker;
    z-index: 99;
    display: none;

    &.is-toggled {
      display: flex;
    }
  }

  @include tablet {
    flex-flow: row;
    padding-left: $brands-sidebar-width;
    @include rtl {
      padding-left: unset;
      padding-right: $brands-sidebar-width;
    }
  }

  li {
    display: inline-block;
    width: 100%;

    @include tablet {
      width: auto;
    }

    a {
      display: block;
      padding: 0.7rem 0.85rem;
    }
  }

  .is-nav {
    font-size: 13px;
    font-weight: bold;
    color: $white;
    text-transform: uppercase;
    background-color: transparent;
    @include transit(color);

    &:hover {
      color: $hf__color-gray--light;
      @include transit(color);
    }

    &.is-inactive {
      color: $hf__color-gray !important;
      cursor: default;
    }
  }

  &.is-primary {
    .router-link-opened {
      color: $hf__color-primary--alternative;
    }

    .router-link-active {
      color: $hf__color-gray-darker;
      background: $hf__color-white;

      &:hover {
        color: $hf__color-gray;
      }
    }
  }

  &.is-secondary {
    .router-link-exact-active {
      color: $hf__color-gray-darker;
      background: $hf__color-white;

      &:hover {
        color: $hf__color-gray;
      }
    }
  }
}
</style>
