<template>
  <div class="DashboardFiltersIcon has-text-centered-desktop">
    <VIcon class="DashboardFiltersIcon__Icon" :type="icon" />
    <div class="DashboardFiltersIcon__Label is-flex is-aligned-middle">
      <div class="is-expanded m-r-xs">
        <slot name="label">
          {{ label }}
        </slot>
      </div>
      <VIcon v-if="isDropdown" :even="false" type="caret-down" />
    </div>
  </div>
</template>

<script>
import VIcon from '@hypefactors/shared/js/components/core/VIcon'

export default {
  name: 'DashboardFiltersIcon',
  components: {
    VIcon
  },
  props: {
    icon: {
      type: String,
      default: ''
    },
    isDropdown: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.DashboardFiltersIcon {
  display: flex;
  align-items: center;
  flex-flow: column;

  @include touch {
    // if inside the DashboardFiltersV2__Tertiary, make it a row instead of a column for mobile
    .DashboardFiltersV2__Tertiary & {
      flex-flow: row;

      // add a small space to the icon, otherwise they stick together
      .DashboardFiltersIcon__Icon {
        margin-right: $margin-small;
      }
    }
  }

  &__Icon {
    font-size: 1.8em;
  }

  &__Label {
    font-weight: $hf__font-weight-bold;
    font-size: $size-5;
    flex: 1 0 auto;
    text-align: left;

    @include desktop {
      margin-top: $margin-tiny;
      font-size: 1rem;
    }
  }
}
</style>
