import { handleError } from './handleErrors'
import { persist, load } from './index'
import { LocationService } from '../services/LocationService'

const RELOAD_CONSTANT = 'FORCE_RELOAD_CHUNK_MISSING'
let CONFIG = {
  isLoggedIn: () => false,
  homeRouteName: ''
}

/**
 * @param {object} params
 * @param {Function} params.isLoggedIn
 * @param {String} params.homeRouteName
 */
export function setConfigurations ({ isLoggedIn, homeRouteName }) {
  CONFIG.isLoggedIn = isLoggedIn
  CONFIG.homeRouteName = homeRouteName
}

/**
 * Returns a fallback route or false
 * @param {import("vue-router").Route} from
 * @return {Boolean | { name: string }}
 */
export function getFallbackNextUrl (from) {
  return from.fullPath === '/' && from.name === null
    ? { name: CONFIG.homeRouteName }
    : false
}

/**
 * Load a vue page async
 * @param {string} component - The path to the Vue page component
 * @param {object} [config]
 * @param {string} config.domain
 * @return {(function(): Promise<Vue>)}
 */
export function loadPageAsync (component, { domain } = {}) {
  if (domain === 'shared') {
    return () => import(/* webpackChunkName: "[request]" */ `@hypefactors/shared/js/pages/${component}.vue`)
  }
  // '@' is aliased to src/components
  return () => import(/* webpackChunkName: "[request]" */ `@/pages/${component}.vue`)
}

export function onError (error) {
  // '@' is aliased to src/components
  if (/Loading chunk .* failed./.test(error.message)) {
    const forceReloadedBefore = load(RELOAD_CONSTANT, false)
    // if we have not reloaded before try a reload
    if (!forceReloadedBefore) {
      persist(RELOAD_CONSTANT, true)
      // server is missing files so we refresh the whole page
      return LocationService.reload(true)
    }
    // we have reloaded before, so we go to the critical error page.
    persist(RELOAD_CONSTANT, false)
    handleError(error, {
      source: 'Router onError handler'
    })
    return LocationService.assign('/static/critical_error.html?redirect=' + window.location.pathname)
  }
  throw error
}

/**
 * Disallows access for guests.
 * @param {import("vue-router").Route} to
 * @param {import("vue-router").Route} from
 * @param {Function} next
 */
export function disallowIfNotLoggedIn (to, from, next) {
  if (!CONFIG.isLoggedIn()) {
    return next({ name: 'login', query: { redirect: to.fullPath } })
  }

  next()
}

/**
 * Disallows access for the signed in user.
 *
 * @export
 * @param {import("vue-router").Route} to
 * @param {import("vue-router").Route} from
 * @param {Function} next
 */
export function disallowIfLoggedIn (to, from, next) {
  if (!CONFIG.isLoggedIn()) return next()

  let redirectTo = to.query.redirect
  // Check if there is a redirect parameter
  if (redirectTo) {
    if (redirectTo.startsWith('http')) {
      return LocationService.assign(redirectTo)
    }
    // add a forward slash in the beginning if by some reason we dont have it there
    if (!redirectTo.startsWith('/')) (redirectTo = '/' + redirectTo)
    // Finally redirect to the page
    return next(redirectTo)
  }
  // if there is no name, then we came here from a page refresh
  return next(getFallbackNextUrl(from))
}
