export const VueI18nFixer = {
  install (Vue, options) {
    const _$t = Vue.prototype.$t

    Vue.prototype.$t = function () {
      if (this.$i18n) {
        return _$t.apply(this, arguments)
      } else {
        return _$t.apply(this.$root, arguments)
      }
    }
  }
}
