export const DEFAULT_LANGUAGE = 'en'
export const FALLBACK_LANGUAGE = 'en'

// WARNING: Update loadLocaleFile in dateFormatters.js if you change these!!! The keys should match the available date-fns locales
export const LANGUAGE_TO_COUNTRY = {
  en: 'US',
  da: 'DK',
  fr: 'FR',
  es: 'ES',
  it: 'IT',
  pl: 'PL',
  pt: 'PT',
  sv: 'SE',
  fi: 'FI',
  no: 'NO',
  'pt_BR': 'BR'
}
// export const SUPPORTED_LANGUAGES = ['en', 'da', 'de', 'fr', 'nl', 'es', 'it', 'pl', 'pt', 'sv', 'fi', 'pt_BR']
export const SUPPORTED_LANGUAGES = Object.keys(LANGUAGE_TO_COUNTRY)

export const isLangSupported = (lang) => SUPPORTED_LANGUAGES.includes(lang)

export const LANGUAGE_TO_FILE_MAP = {}

export const LANGUAGE_VARIATIONS = [
  {
    name: 'Portuguese Brazilian',
    slug: 'portuguese-brazilian',
    code: 'pt_BR'
  }
]
