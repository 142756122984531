<script>
import { mapGetters, mapActions } from 'vuex'
import { LANGUAGE_VARIATIONS, SUPPORTED_LANGUAGES } from '../../constants/trans'
import { generateUniqueElementSelectList } from '../../utils/componentUtilities'

/**
 * @module LanguagesProvider
 */
export default {
  name: 'LanguagesProvider',

  props: {
    exclude: {
      type: Array,
      default: () => []
    },
    showSupportedOnly: {
      type: Boolean,
      default: false
    },
    showLanguageVariations: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters(['languages']),

    languagesWithVariations () {
      if (!this.showLanguageVariations) return this.languages
      return this.languages.concat(LANGUAGE_VARIATIONS)
    },
    /**
     * Returns a list of languages that exclude the provided list of elements
     * @return {{}[]}
     */
    notExcludedLanguages () {
      return generateUniqueElementSelectList(this.languagesWithVariations, this.exclude, this.$attrs['value-key'], 'code')
    },

    /**
     * Returns a list of languages that only include the SUPPORTED_LANGUAGES
     * @return {{}[]}
     */
    supportedLanguages () {
      return generateUniqueElementSelectList(this.languagesWithVariations, SUPPORTED_LANGUAGES, undefined, 'code', true)
    },

    /**
     * Determines what languages to loop, based on the showSupportedOnly prop
     * @return {{}[]}
     */
    languagesToLoop () {
      return this.showSupportedOnly ? this.supportedLanguages : this.notExcludedLanguages
    }
  },

  mounted () {
    this.fetchLanguages()
  },

  methods: {
    ...mapActions(['fetchLanguages'])
  },

  render () {
    return this.$scopedSlots.default({
      languages: this.languagesToLoop
    })
  }
}
</script>
