/**
 * Returns the values that exist in the first array, but not in the second
 * @param {array} array1 - The array to filter from
 * @param {array} array2 - The array to check against
 * @param {string} prop - allows searching by a prop
 * @return {array}
 */
export function getUniqueArrayValues (array1, array2, prop) {
  return array1.filter(element => !array2.find(sc => (prop ? sc[prop] === element[prop] : sc === element)))
}

/**
 * Returns the values from the first array that are also included in second
 * @param {array} array1
 * @param {array} array2
 * @return {array}
 */
export function intersectBetweenArrays (array1, array2) {
  return array1.filter(el => array2.includes(el))
}

export function removeDuplicatesSimple (array) {
  return [...new Set(array)]
}

/**
 * Removes element from array by its key
 * @param {Array} elements
 * @param {String} key
 * @param value
 */
export function removeOneByKey (elements, key, value) {
  const clone = elements.slice(0)
  const index = elements.findIndex((el) => el[key] === value)
  if (index !== -1) {
    clone.splice(index, 1)
  }
  return clone
}

export function togglePlainElementFormArray (elements, element, key) {
  const isObject = typeof element === 'object'
  const elementsClone = elements.slice(0)
  const elementIndex = elementsClone.findIndex(el => {
    if (key) {
      return el[key] === (!isObject ? element : element[key])
    }
    return el === element
  })
  // included
  if (elementIndex !== -1) {
    elementsClone.splice(elementIndex, 1)
  } else {
    // not included
    elementsClone.push(element)
  }
  return elementsClone
}

export function toggleArrayElement (array, element) {
  const arrayClone = array.slice(0)
  const selectedCountryIndex = arrayClone.indexOf(element)
  if (selectedCountryIndex !== -1) {
    arrayClone.splice(selectedCountryIndex, 1) // remove country from list
  } else {
    arrayClone.push(element)
  }
  return arrayClone
}
