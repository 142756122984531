import { api } from './ApiInstanceInjector'

export const NEWSROOM_CRUD_URL = (newsroomId) => `newsrooms/${newsroomId}`

export const NewsroomService = {
  /**
   * Creates a newsroom
   * @param payload
   * @param params
   * @return {AxiosPromise<any>}
   */
  create (payload, params) {
    return api.post('newsrooms', payload, params)
  },
  /**
   * Updates a newsroom
   * @param newsroomId
   * @param payload
   * @param params
   * @return {AxiosPromise<any>}
   */
  update (newsroomId, payload, params) {
    return api.patch(NEWSROOM_CRUD_URL(newsroomId), payload, params)
  },

  delete (newsroomId) {
    return api.delete(NEWSROOM_CRUD_URL(newsroomId))
  },
  /**
   * Publishes a newsroom
   * @param companyId
   * @param payload
   * @param params
   * @return {AxiosPromise<any>}
   */
  publish (companyId, payload, params) {
    return api.post(`newsrooms/${companyId}/publish`, payload, params)
  },
  /**
   * Unpublishes a newsroom
   * @param companyId
   * @param payload
   * @param params
   * @return {AxiosPromise<any>}
   */
  unpublish (companyId, payload, params) {
    return api.post(`newsrooms/${companyId}/unpublish`, payload, params)
  },

  fetchNewsroom (id, params) {
    return api.get(`newsrooms/${id}`, params)
  },

  findNewsrooms (query, params) {
    return api.fetchAllWithCursor('newsrooms', {
      params: {
        query,
        ...params
      }
    })
  },

  isNameTaken (name, country) {
    return api.postData('newsrooms/name-taken', { name, country })
      .then(response => response.name_is_taken)
  },

  getNewsroomSiblings (newsroomId, options) {
    return api.getData(`newsrooms/${newsroomId}/siblings`, options)
  },

  /**
   * Public endpoint to fetch all social handles of a newsroom
   * @param {String} newsroomId
   * @param {AxiosRequestConfig} [params]
   * @return {Promise<any>}
   */
  fetchSocialHandles (newsroomId, params) {
    return api.getData(`newsrooms/${newsroomId}/social`, params)
  },

  /**
   * Creates a social handle and attaches to newsroom
   * @param {String} newsroomId
   * @param {Object} payload
   * @param {AxiosRequestConfig} [params]
   * @return {Promise<any>}
   */
  createSocialHandle (newsroomId, payload, params) {
    return api.postData(`newsrooms/${newsroomId}/social`, payload, params)
  },

  /**
   * Updates a Newsroom social handle
   * @param {String} newsroomId
   * @param {String} socialHandleId
   * @param {Object} payload
   * @param {AxiosRequestConfig} [params]
   * @return {Promise<any>}
   */
  updateSocianHandle (newsroomId, socialHandleId, payload, params) {
    return api.putData(`newsrooms/${newsroomId}/social/${socialHandleId}`, payload, params)
  },

  /**
   * Deletes a newsroom social handle
   * @param {String} newsroomId
   * @param {String} socialHandleId
   * @return {Promise<any>}
   */
  deleteSocialHandle (newsroomId, socialHandleId) {
    return api.delete(`newsrooms/${newsroomId}/social/${socialHandleId}`)
  },

  fetchSocialHandleFeed (newsroomId, socialHandleId, params) {
    return api.getData(`/newsrooms/${newsroomId}/social/${socialHandleId}/feed`, params)
  }
}
