<template>
  <transition-group
    :class="[{'columns': width}]"
    tag="div"
    name="list"
    class="Collection is-multiline is-position-relative"
  >
    <div
      v-if="initiallyLoaded && $slots.firstItem"
      key="firstItem"
      :class="width"
      class="Collection-Item Collection-Item--first"
    >
      <slot name="firstItem" />
    </div>
    <template v-if="!initiallyLoaded && filteredItems.length === 0">
      <slot name="placeholder">
        <template v-if="placeholder">
          <div
            v-for="n in placeholderItems"
            :key="n"
            :class="width"
            class="column Collection__Placeholder"
          >
            <component
              :is="placeholderComponent"
              :class="[placeholder]"
            />
          </div>
        </template>
      </slot>
    </template>
    <template v-if="filteredItems.length">
      <slot
        name="items"
        :items="filteredItems"
        :addItem="addItem"
        :removeItem="removeItem"
        :updateItem="updateItem"
        :class="['Collection-Item', width]"
      >
        <div
          v-for="item in filteredItems"
          :key="item.id"
          :ref="`item_${item.id}`"
          :class="width"
          class="Collection-Item"
        >
          <slot
            :item="item"
            :addItem="addItem"
            :removeItem="removeItem"
            :updateItem="updateItem"
            name="item"
          >
            <p class="Collection__item">
              {{ item }}
            </p>
          </slot>
        </div>
      </slot>
    </template>
    <div
      v-else-if="initiallyLoaded"
      key="no-items"
      class="Collection__no-items column is-12"
    >
      <slot name="noItems">
        <p class="has-text-centered">
          {{ $t('general.no_more_items') }}
        </p>
      </slot>
    </div>
    <div
      v-if="initiallyLoaded && hasMore"
      key="load-more"
      class="Collection__load-more column is-12 has-text-centered"
    >
      <transition
        name="fade"
        mode="out-in"
      >
        <button
          v-if="hasMore"
          :class="{ 'is-loading': isLoading }"
          :disabled="isLoading"
          class="button is-primary"
          @click="handleLoadMoreClick"
        >
          {{ $t('general.load_more') }}
        </button>
        <p
          v-else
          class="noMoreText"
        >
          {{ $t('general.no_more_items') }}
        </p>
      </transition>
    </div>
  </transition-group>
</template>

<script>
import _uniqBy from 'lodash/uniqBy'
import debounce from 'lodash/debounce'

import { mapGetters, mapMutations } from 'vuex'
import { buildUrlWithParams } from '@hypefactors/shared/js/utils/UrlUtils'

export default {
  name: 'LoadMore',
  props: {
    method: {
      type: String,
      required: false,
      default: 'get'
    },
    endpoint: {
      type: String,
      required: true
    },
    dataPayload: {
      type: Object,
      required: false,
      default: () => {}
    },
    width: {
      type: String,
      default: ''
    },
    filter: {
      type: Function,
      default: (items) => items
    },
    preserveState: {
      type: Boolean,
      default: false
    },
    identifier: {
      type: String,
      default: ''
    },
    restoreState: {
      type: Boolean,
      default: false
    },
    focusItem: {
      type: [String, Number],
      default: null
    },
    beforeFocus: {
      type: Function,
      default: () => {}
    },
    placeholder: {
      type: String,
      default: ''
    },
    placeholderItems: {
      type: Number,
      default: 1
    },
    debounce: {
      type: Number,
      default: 0
    },
    preserveParams: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    list: [],
    hasMore: false,
    cursor: null,
    isLoading: false,
    cancelToken: null,
    initiallyLoaded: false
  }),

  computed: {
    ...mapGetters('loadmore', ['getStateById']),

    stateInStore () {
      return this.getStateById(this.identifier)
    },

    cursorInStore () {
      return this.stateInStore.cursor || null
    },

    itemsInStore () {
      return this.stateInStore.items || []
    },

    paramsInStore () {
      return this.stateInStore.params || null
    },

    endpointWithCursors () {
      return buildUrlWithParams(this.endpoint, {
        cursor: this.cursor
      })
    },

    filteredItems: {
      get () {
        return this.filter(this.list)
      },
      set (items) {
        this.list = items
      }
    },

    placeholderComponent () {
      if (!this.placeholder) return null
      return this.importPlaceholder
    }
  },

  watch: {
    endpoint () {
      if (this.debounce) {
        return this.refreshDebounced()
      }
      this.refresh()
    },

    dataPayload () {
      if (this.debounce) {
        return this.refreshDebounced()
      }
      this.refresh()
    }
  },

  async mounted () {
    if (this.preserveState) {
      if (this.restoreState && this.itemsInStore.length) {
        this.list = this.itemsInStore.slice(0)
        this.cursor = this.cursorInStore
        this.paramsInStore && this.$emit('update:preserveParams', this.paramsInStore)
        this.initiallyLoaded = true
      } else {
        // reset the state in Vuex
        this.storeItemsInStorage([])
        await this.load()
      }
    } else {
      await this.load()
    }
    if (this.focusItem) {
      this.focusTo(this.focusItem)
      // refresh the list if changes occur
      this.list = this.itemsInStore.slice(0)
    }
  },

  created () {
    this.refreshDebounced = debounce(this.refresh, this.debounce)
  },

  beforeDestroy () {
    this.cancelToken && this.cancelToken.cancel()
  },

  methods: {
    ...mapMutations('loadmore', { storeItems: 'SET_ITEMS_BY_ID' }),
    async refresh () {
      this.cancelToken && this.cancelToken.cancel() // abort Axios request
      this.list = []
      this.hasMore = false
      this.cursor = null
      this.updateLoading(false)
      this.initiallyLoaded = false
      if (this.preserveState) await this.storeItemsInStorage([]) // clear out store
      return this.load()
    },

    load () {
      this.updateLoading(true)

      this.cancelToken = this.$api.cancelToken()

      return this.$api
        .request(this.method, this.endpointWithCursors, {
          cancelToken: this.cancelToken.token,
          ...this.dataPayload
        })
        .then(({ data }) => {
          this.hasMore = this.$safeGet(data, 'meta.has_more', false)
          this.cursor = this.$safeGet(data, 'meta.cursor', null)

          this.list = _uniqBy([...this.list, ...data.data], 'id')

          // If the resulting list is empty, spread the word.
          if (!this.list.length) {
            this.$bus.$emit('load-more-empty')
            this.$emit('empty')
          }

          this.$emit('loaded', this.list)
          if (this.preserveState) {
            this.storeItemsInStorage(this.list)
          }
        })
        .catch(error => {
          if (this.$api.isCancelToken(error)) return
          this.hasMore = false
        })
        .finally(() => {
          this.initiallyLoaded = true
          this.updateLoading(false)
        })
    },

    addItem (item) {
      this.list.push(item)
    },

    addItemPrepended (item) {
      this.list.unshift(item)
    },

    removeItem (item, identifier = 'id') {
      const index = this.list.findIndex(i => i[identifier] === item[identifier])
      this.list.splice(index, 1)
    },

    updateItem (item, identifier = 'id') {
      const index = this.list.findIndex(itm => itm[identifier] === item[identifier])
      const newItem = { ...this.list[index], ...item }
      this.list.splice(index, 1, newItem)
    },

    /**
     * Removes all items that match
     * @param {Array} items
     * @param {String} identifier
     */
    removeItemsBy (items, identifier = 'id') {
      this.list = this.list.filter(item => !items.includes(item[identifier]))
    },

    storeItemsInStorage (items) {
      this.storeItems({ id: this.identifier, cursor: this.cursor, items, params: this.preserveParams })
    },

    async focusTo (id) {
      await this.beforeFocus(this.identifier, id)
      await this.$nextTick()
      const item = this.$refs[`item_${id}`]
      if (item && item.length && typeof item[0].scrollIntoView === 'function') {
        item[0].scrollIntoView()
      }
    },

    importPlaceholder () {
      return import(`@/components/placeholders/${this.placeholder}.vue`)
        .catch(() => {})
    },

    updateLoading (val) {
      this.isLoading = val
      this.$emit('loading', this.isLoading)
    },

    handleLoadMoreClick () {
      this.load()
      this.$emit('load-more-clicked')
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.Collection {
  &__load-more {
    margin: $margin auto;
  }
}
</style>
