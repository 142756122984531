<template>
  <div class="DataCard has-text-centered" :class="bodyClasses">
    <slot name="outside-body" />
    <div :class="[size]" class="DataCard__wrap">
      <div class="DataCard__title is-uppercase has-text-weight-semibold tracking-wider">
        <slot name="title">
          {{ title }}
        </slot>
      </div>
      <div class="DataCard__body p-v-s">
        <slot name="outerBody">
          <div class="DataCard__data">
            <slot />
          </div>
        </slot>
      </div>
      <div class="DataCard__footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @module DataCard
 */
export default {
  name: 'DataCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'small',
      validate (size) {
        return ['small', 'medium', 'large', 'xl'].includes(size)
      }
    },
    active: {
      type: Boolean,
      default: false
    },
    seeThrough: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    bodyClasses () {
      return {
        'has-background-grey-darker has-text-white': this.active && !this.seeThrough,
        'has-background-grey-lighter': !this.active && !this.seeThrough
      }
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.DataCard {
  font-size: .6rem;

  @include tablet-only {
    font-size: .7rem;
  }
  @include desktop {
    font-size: 0.8rem;
  }
  @include fullhd {
    font-size: 1rem;
  }
}

.DataCard__wrap {
  max-width: 100%;

  &.small {
    font-size: 0.75em;
  }

  &.medium {
    font-size: 1em;
    padding: 1em;
  }

  &.large {
    font-size: 1.25em;
    padding: 1em*1.1;
  }

  &.xl {
    font-size: 1.35em;
    padding: 1em*1.2;
  }
}

.DataCard__data {
  font-size: 2.5em;
  font-weight: 900;
  line-height: 1;

  .currency-symbol {
    font-size: 0.6em;
  }
}
</style>
