import _debounce from 'lodash/debounce'

/**
 * Factory function to create a mixin, generating debounced variants of methods.
 * @param {Array} methods
 * @param {Number|Object} delayDef - either a delay number or an object mapping methods to delay
 * @param {Object} options - options to pass to lodash debounce
 * @returns {{created(): void}}
 */
export function debounceMixin (methods, delayDef = 500, options = {}) {
  return {
    created () {
      const customDelay = typeof delayDef !== 'number'
      methods.forEach(m => {
        const delay = customDelay
          ? delayDef[m]
          : delayDef
        this[m] = _debounce(this[m], delay, options)
      })
    }
  }
}
