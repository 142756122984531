import ifVisible from 'ifvisible.js'

const IDLE_DURATION = 60 * 15 // 15 mins

// Set the default duration always
ifVisible.setIdleDuration(IDLE_DURATION)

export const IdleVisibiltyService = {
  setDuration (duration) {
    ifVisible.setIdleDuration(duration || IDLE_DURATION)
  },
  on (name, cb) {
    return ifVisible.on(name, cb)
  },
  off (name, cb) {
    return ifVisible.off(name, cb)
  },
  isVisible () {
    return ifVisible.now('active')
  },
  isHidden () {
    return ifVisible.now('hidden')
  },
  isIdle () {
    return ifVisible.now('idle')
  },
  getIdleInfo () {
    return ifVisible.getIdleInfo()
  }
}
