<template>
  <el-dialog
    :visible.sync="isVisible"
    :title="$t('components.the_no_brands_dialog.title')"
    class="TheNoBrandsDialog"
  >
    {{ $t('components.the_no_brands_dialog.content') }}
    <div class="has-text-right">
      <router-link :to="{ name: 'cx.contact_us' }" class="button is-primary">
        {{ $t('general.contact_us') }}
      </router-link>
    </div>
  </el-dialog>
</template>

<script>
/**
 * @module TheNoBrandsDialog
 */
export default {
  name: 'TheNoBrandsDialog',
  data () {
    return {
      isVisible: true
    }
  }
}
</script>
