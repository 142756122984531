import * as colors from '../components/charts/DefaultChartColors'

export const MEDIA_TYPE_ICONS = {
  print: 'print',
  web: 'laptop',
  tv: 'tv',
  radio: 'radio',
  instagram: 'instagram',
  facebook: 'facebook',
  twitter: 'twitter',
  pinterest: 'pinterest',
  tiktok: 'tiktok',
  youtube: 'youtube',
  forum: 'forum',
  review: 'review',
  reddit: 'reddit',
  linkedin: 'linkedin',
  social: 'share-alt'
}

/**
 * Returns the media types
 * @type {Object.<String, { label: string, color: string, icon: string }>}
 */
export const MEDIA_TYPES = {
  social: { label: 'general.social', color: colors.salmon, icon: MEDIA_TYPE_ICONS.social },
  web: { label: 'components.media_types.online', color: colors.charcoal, icon: MEDIA_TYPE_ICONS.web },
  tv: { label: 'components.media_types.tv', color: colors.brick, icon: MEDIA_TYPE_ICONS.tv },
  radio: { label: 'components.media_types.radio', color: colors.grey, icon: MEDIA_TYPE_ICONS.radio },
  instagram: { label: 'components.media_types.instagram', color: colors.salmon, icon: MEDIA_TYPE_ICONS.instagram },
  facebook: { label: 'components.media_types.facebook', color: colors.salmon, icon: MEDIA_TYPE_ICONS.facebook },
  twitter: { label: 'components.media_types.twitter', color: colors.salmon, icon: MEDIA_TYPE_ICONS.twitter },
  pinterest: { label: 'components.media_types.pinterest', color: colors.blue, icon: MEDIA_TYPE_ICONS.pinterest },
  print: { label: 'components.media_types.print', color: colors.blue, icon: MEDIA_TYPE_ICONS.print },
  tiktok: { label: 'components.media_types.tiktok', color: colors.salmon, icon: MEDIA_TYPE_ICONS.tiktok },
  youtube: { label: 'components.media_types.youtube', color: colors.salmon, icon: MEDIA_TYPE_ICONS.youtube },
  forum: { label: 'components.media_types.forum', color: colors.charcoal, icon: MEDIA_TYPE_ICONS.forum },
  review: { label: 'components.media_types.review', color: colors.green, icon: MEDIA_TYPE_ICONS.review },
  reddit: { label: 'components.media_types.reddit', color: colors.charcoal, icon: MEDIA_TYPE_ICONS.reddit },
  linkedin: { label: 'components.media_types.linkedin', color: colors.charcoal, icon: MEDIA_TYPE_ICONS.linkedin }
}

export const MEDIA_TYPES_ARRAY = Object.entries(MEDIA_TYPES).map(([key, type]) => ({ key, ...type }))

export const MEDIA_TYPE_ORDER = ['print', 'web', 'tv', 'radio', 'social']

// media types we can only observe, but cannot create clippings for
export const UNCLIPPABLE_MEDIA_TYPES = []

// media types we should not show measurements for
export const UNMEASURABLE_MEDIA_TYPES = ['social']

// media types that should not allow manual clipping creation
// TODO: Temporarily add facebook as unclippable
export const MANUALLY_UNCLIPPABLE_MEDIA_TYPES = ['facebook', 'review', 'forum', 'reddit', 'tiktok', 'pinterest']

// Media types that we should not be able to create streams for.
export const UNFOLLOWABLE_MEDIA_TYPES = ['linkedin']

export const SOCIAL_MEDIA_TYPES = ['instagram', 'twitter', 'facebook', 'linkedin', 'pinterest', 'tiktok', 'youtube', 'reddit', 'forum']
