<template>
  <div class="AuthorizedFallback is-column is-aligned-middle">
    <div class="icon is-xlarge">
      <i :class="[icon || 'hf-dashboard']" class="hf hf-5x" />
    </div>
    <subheading>{{ computedTitle }}</subheading>
    <slot :cause="cause" name="content">
      <template v-if="cause === 'plan'">
        {{ $t('components.core.authorize.contact_support') }}
        <router-link
          v-if="authorizeBool('organisation.update')"
          :to="{ name: 'settings.billing.billing' }"
          class="button is-primary is-large m-v-l"
        >
          {{ $t('components.core.authorize.billing_settings') }}
        </router-link>
        <a
          v-else
          href="mailto:support@hypefactors.com"
          class="button is-primary is-large m-v-l"
        >
          {{ $t('general.contact') }}
        </a>
      </template>
      <template v-else>
        {{ $t('components.core.authorize.contact_brand_manager') }}
      </template>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    permission: {
      type: Object,
      default: () => ({ can: false, reason: { cause: 'role', type: 'bool' } }),
      validation: (prop) => prop.hasOwnProperty('can') && prop.hasOwnProperty('reason')
    }
  },
  computed: {
    cause () {
      return this.$safeGet(this.permission, 'reason.cause')
    },
    computedTitle () {
      if (this.title) return this.title
      return this.cause === 'plan' ? this.$t('warnings.permissions.plan_limits_content') : this.$t('warnings.permissions.role_limits_content')
    }
  }
}
</script>
