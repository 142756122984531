import Api from '@/services/Api'

export const BRAND_PR_STYLE_TEMPLATES_ENDPOINT = (brandId) => `/brands/${brandId}/pr-styles`
export const UPDATE_PR_STYLE_TEMPLATE_ENDPOINT = storyId => `/stories/${storyId}/pr-styles`

export const StoryPRApi = {
  /**
   * Fetches all the PR Style templates available for the brand
   * @param {string} brandId
   * @return {Promise<any>}
   */
  fetchBrandPRStyleTemplates (brandId) {
    return Api.get(BRAND_PR_STYLE_TEMPLATES_ENDPOINT(brandId)).then((response) => response.data.data)
  },
  /**
   * Creates a new Press Release Styles template.
   * @param {string} brandId
   * @param {object} payload
   * @return {Promise<any>}
   */
  createBrandPRStyleTemplates (brandId, payload) {
    return Api.post(BRAND_PR_STYLE_TEMPLATES_ENDPOINT(brandId), payload).then((response) => response.data.data)
  },
  /**
   * Updates the Story Press release template and sets possible overrides.
   * @param {string} storyId
   * @param {StoryPRStylesOverridesFormObject} payload
   * @return {Promise<any>}
   */
  updateStoryPRStyleTemplate (storyId, payload) {
    return Api.post(UPDATE_PR_STYLE_TEMPLATE_ENDPOINT(storyId), payload)
  }
}
