import { errorLoggingService } from '../services/errorLoggingService'
import _get from 'lodash/get'
import { formatComponentName } from './errorHandlerUtils'

/**
 * Transform an array of messages from laravel error message bag to be a single html string with breaks
 * @param {Object | Array | String} message
 * @param {String} $glue
 * @example
 * message = { username: ['some error', 'maybe another error'], email: ['another error', 'and another error']}
 * message = { username: 'some error', email: 'and another error'}
 * message = ['some error', 'and another error']
 * message = [['some error'], ['and another error']]
 * message = 'some error'
 * @return { String, Array }
 */
export function flattenErrorMessage (message, $glue = '<br/>') {
  // Check if messages are an Object of array items
  if (typeof message === 'object') {
    const msgArray = message.map((error) => {
      if (typeof error === 'object') {
        return error['detail']
      }

      return error
    }, [])

    return $glue ? msgArray.join($glue) : msgArray
  }
  // its not an object so we just return it.
  return message
}

/**
 * Displays error messages if they are present in the response
 * @name displayRequestError
 * @func
 * @param {Error| String} error - Http response from Axios.
 * @param {String | Object} noneStandardErrorMessage - Error message to display if no response from server. Can be settings object or string.
 * @param {Number} duration - duration of error message
 * @param {Function | Boolean | Array} skipErrorReporting - allows skipping the error reporting
 * @param {Function} [notification] - The notification function to call. Uses ElementUIs Notification
 */
export function displayRequestError (
  error,
  noneStandardErrorMessage = false,
  duration = 10000,
  skipErrorReporting = false,
  notification) {
  if (!error || error === 'cancel' || error === 'close') return
  notification = notification || this.$notify
  let title = ''
  let message = ''
  let severity = 'error'
  // Check if we have a response from the server
  let response = error.response
  let status = _get(response, 'status', 0)
  if (response && status < 500) {
    const errorMessage = response.data.message
    const errorFields = response.data.errors
    let dangerouslyUseHTMLString = false
    // If api return errors we add them to the message bag
    if (errorFields && errorFields.length > 0) {
      const flatErrors = flattenErrorMessage(errorFields, '')
      title = errorMessage
      message = getErrorsString(flatErrors)
      dangerouslyUseHTMLString = true
    } else {
      title = 'An Error has occurred'
      message = errorMessage
    }
    notification.error({
      title,
      duration,
      message,
      dangerouslyUseHTMLString
    })
    if (status === 401) skipErrorReporting = true // We dont want to notify errorLogger when a user is not authenticated
    if (status === 422) severity = 'warning'
  } else if (noneStandardErrorMessage) {
    const msgType = typeof noneStandardErrorMessage
    if (msgType === 'string') {
      notification.error({
        title: 'Error',
        message: noneStandardErrorMessage
      })
    } else if (msgType === 'object') {
      notification(noneStandardErrorMessage)
    }
  } else if (error.message) {
    this.$notify.error({
      title: 'Error',
      message: error.message
    })
  }
  // if true just return
  if (skipErrorReporting === true) return
  // if is an array, check if error status numner is inside
  if (Array.isArray(skipErrorReporting) && skipErrorReporting.includes(status)) return
  // if is a function, run it
  if (typeof skipErrorReporting === 'function' && skipErrorReporting(error)) return
  handleError.call(this, error, {
    serverResponse: response || error.message || error,
    customMessage: noneStandardErrorMessage || ''
  }, severity)
}

/**
 * Handles error reporting to errorLogger
 * @name handleError
 * @function
 * @param {Error} error - Error to notify
 * @param {Object} [meta] - Extra meta data
 * @param {String} [severity] - Severity
 */
export function handleError (error, meta = {}, severity = 'error') {
  let extra = {
    severity: severity,
    metaData: meta,
    componentName: formatComponentName(this)
  }

  errorLoggingService.notify(error, extra)
}

/**
 * Supply an array of errors and creates a vnode tree. Used with displaying errors mostly.
 * @param {Array} flatErrors - Array of errors
 * @return {string}
 */
function getErrorsString (flatErrors) {
  return `<div class="errors">${flatErrors.map(e => `<p>${e}</p>`).join('')}</div>`
}
