import Form from '@hypefactors/shared/js/services/forms/Form'
import Api from '@/services/Api'

/**
 * @extends Form
 */
export default class AppForm extends Form {
  constructor (options) {
    super(options)
    this._api = Api
  }
}
