import Vue from 'vue'
import Clickoutside from 'element-ui/lib/utils/clickoutside'

import { directive as vueClickAway } from 'vue-clickaway'

import MultiRef from '@/directives/MultiRef.js'

Vue.directive('clickoutside', Clickoutside)
Vue.directive('multi-ref', MultiRef)
Vue.directive('clickAway', vueClickAway)
