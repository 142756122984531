import api from '@/services/Api'
import { i18n } from '@/bootstrap/i18n'

export const SET_CATEGORIES = 'SET_CATEGORIES'

const state = {
  categories: []
}

const getters = {
  all (state) {
    return state.categories
  },

  allTranslated (state) {
    return state.categories.map(category => {
      category.name = i18n.tc(`connect-categories.${category.slug}`)

      return category
    })
  }
}

const actions = {
  async fetch ({ commit }) {
    return api.getData('/connect/categories')
      .then(response => commit(SET_CATEGORIES, response))
      .catch(() => null)
  }
}

const mutations = {
  [SET_CATEGORIES] (state, categories) {
    state.categories = categories
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
