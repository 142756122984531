import { load, persist } from '@/utils'
import { brandTotalsFactory } from '@hypefactors/shared/js/factories/metrics'
import * as MetricsApi from '@hypefactors/shared/js/services/api/MetricsService'
import { forget } from '@hypefactors/shared/js/utils'

const state = {
  activeDashboardBrands: [],
  brandTotals: brandTotalsFactory(),
  brandTotalsLoading: false,

  activeBrandsLimit: 5
}

const getters = {
  appliedDashboardFilters (state, getters, globalState, globalGetters) {
    return globalGetters.filtersQueryFor('dashboard', false)
  },

  arePersistedBrandsRelevant (state, getters, globalState, globalGetters) {
    return state.activeDashboardBrands.every((brandId) => {
      return globalGetters.brands.findIndex(b => b.id === brandId.id) !== -1
    })
  }
}

const mutations = {
  UPDATE_ACTIVE_DASHBOARD_BRANDS (state, brands) {
    state.activeDashboardBrands = brands
  },

  SET_BRAND_TOTAL_BY_TYPE (state, { name, value }) {
    state.brandTotals[name] = value
  },

  CLEAR_BRAND_TOTALS (state) {
    state.brandTotals = brandTotalsFactory()
  },

  SET_DASHBOARD_TOTALS_LOADING (state, value = !state.brandTotalsLoading) {
    state.brandTotalsLoading = value
  }
}

const actions = {
  updateActiveDashboardBrands ({ dispatch, state, commit }, { brand, limit = state.activeBrandsLimit }) {
    return new Promise((resolve, reject) => {
      let brands = state.activeDashboardBrands.slice()
      const orgIndex = brands.findIndex(org => org.id === brand.id)
      let payload = {
        message: '',
        data: ''
      }
      if (orgIndex !== -1) {
        if (brands.length === 1) return reject(new Error('components.dashboard.at_least_one_brand_should_be_selected'))
        brands.splice(orgIndex, 1)
        payload = {
          message: 'components.dashboard.brand_removed_from_list',
          data: { brand: brand.name }
        }
      } else {
        if (brands.length >= limit) {
          brands.shift()
          payload = {
            message: 'components.dashboard.max_selected_brands_reached',
            data: { limit }
          }
        }
        brands.push(brand)
        if (brands.length < limit) {
          payload = {
            message: 'components.dashboard.brand_added_to_list',
            data: { brand: brand.name }
          }
        }
      }
      const brandIds = brands.map(org => org.id)
      commit('UPDATE_ACTIVE_DASHBOARD_BRANDS', brands)
      persist('HF_Filter_ActiveDashboardBrandsIds', brandIds)
      dispatch('setFilter', {
        name: 'brands',
        value: brandIds
      }, { root: true })
      resolve(payload)
    })
  },

  clearActiveDashboardBrands ({ commit, dispatch }) {
    commit('UPDATE_ACTIVE_DASHBOARD_BRANDS', [])
    forget('HF_Filter_ActiveDashboardBrandsIds')
    dispatch('removeFilter', 'brands', { root: true })
  },

  fetchBrandTotalMetrics ({ commit, getters }) {
    commit('SET_DASHBOARD_TOTALS_LOADING', true)
    return Promise.all([
      MetricsApi.hfValueTotals({ params: getters.appliedDashboardFilters }),
      MetricsApi.clippingsTotals({ params: getters.appliedDashboardFilters }),
      MetricsApi.impressionsTotals({ params: getters.appliedDashboardFilters })
    ])
      .then(([hfValues, clippings, impressions]) => {
        commit('SET_BRAND_TOTAL_BY_TYPE', { name: 'hfValue', value: hfValues.data.data })
        commit('SET_BRAND_TOTAL_BY_TYPE', { name: 'clippings', value: clippings.data.data })
        commit('SET_BRAND_TOTAL_BY_TYPE', { name: 'impressions', value: impressions.data.data })
        commit('SET_DASHBOARD_TOTALS_LOADING', false)
      })
  },

  setPersistedDashboardBrands ({ commit, getters, rootGetters }, options = {}) {
    let { brands = rootGetters.brands, loadPersistedOnly = true, limit = state.activeBrandsLimit } = options
    const savedFilterBrandIds = load('HF_Filter_ActiveDashboardBrandsIds', [])
    if (loadPersistedOnly && savedFilterBrandIds.length) {
      brands = brands.filter(org => savedFilterBrandIds.includes(org.id))
    } else {
      brands = brands.slice(0, limit)
    }
    commit('UPDATE_ACTIVE_DASHBOARD_BRANDS', brands)
    persist('HF_Filter_ActiveDashboardBrandsIds', brands.map(org => org.id))
    return brands
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
