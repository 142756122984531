import _get from 'lodash/get'
import { croppieDataFactory } from '@hypefactors/shared/js/factories/croppie'

/**
 * Creates an empty Newsroom
 * @class Newsroom
 */
export class Newsroom {
  /**
   * @param newsroom
   * @property {String|null} id
   * @property {String} slug
   * @property {String} name
   * @property {String} website
   * @property {String} address
   * @property {String} description
   * @property {Boolean} legal_warning
   * @property {String} main_color
   * @property {String} secondary_color
   * @property {croppieData} logo
   * @property {croppieData} page_header
   *
   * @property {String} contact_name
   * @property {String} contact_phone
   * @property {String} contact_email
   * @property {String} contact_role
   *
   * @property {Array} videos
   *
   * @property {Number} files_count
   * @property {Number} categories_count
   * @property {Number} contacts_count
   * @property {Number} followers_count
   * @property {Number} stories_count
   *
   * @property {Boolean} is_published
   * @property {String} created_at
   * @property {String} updated_at
   * @property {String|null} published_at
   *
   * @property {HF_Brand} brand
   * @property {HF_Category[]} categories
   * @property {!HF_Country} country
   * @property {Array} contacts
   * @property {Array} files
   * @property {Array} followers
   * @property {Object} rss
   */
  constructor (newsroom = {}) {
    this.id = newsroom.id || null
    this.slug = newsroom.slug || ''
    this.name = newsroom.name || ''
    this.website = newsroom.website || ''
    this.address = newsroom.address || ''
    this.description = newsroom.description || ''
    this.legal_warning = newsroom.legal_warning || false
    this.main_color = newsroom.main_color || ''
    this.secondary_color = newsroom.secondary_color || ''
    this.logo = newsroom.logo || croppieDataFactory()
    this.page_header = newsroom.page_header || croppieDataFactory()

    this.contact_name = newsroom.contact_name || ''
    this.contact_phone = newsroom.contact_phone || ''
    this.contact_email = newsroom.contact_email || ''
    this.contact_role = newsroom.contact_role || ''

    this.social_handles_count = newsroom.social_handles_count || 0

    this.videos = newsroom.videos || []

    this.files_count = newsroom.files_count || 0
    this.categories_count = newsroom.categories_count || 0
    this.contacts_count = newsroom.contacts_count || 0
    this.followers_count = newsroom.followers_count || 0
    this.stories_count = newsroom.stories_count || 0

    this.is_published = newsroom.is_published || false
    this.created_at = newsroom.created_at
    this.updated_at = newsroom.updated_at
    this.published_at = newsroom.published_at

    this.brand = _get(newsroom, 'brand.data')

    this.categories = _get(newsroom, 'categories.data', [])
    this.country = _get(newsroom, 'country.data', _get(newsroom, 'country', null)) // TODO: Improve this by appliying data normalizing on the backend
    this.contacts = _get(newsroom, 'contacts.data', [])
    this.files = _get(newsroom, 'files.data', [])
    this.followers = _get(newsroom, 'followers.data', [])
    this.rss = _get(newsroom, 'rss.data', {})
  }
}
