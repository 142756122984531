import Form from '@/services/forms/Form'

/**
 * @alias HF_PaymentPlanCommitment
 * @description The default payment plan returned from the API
 */
export class PaymentPlanCommitment {
  constructor (plan) {
    /** @type {string} name (Monthly|Yearly) */
    this.name = plan.name
    /** @type {string} interval - (yearly|monthly) */
    this.interval = plan.interval
    /** @type {number} amount */
    this.amount = plan.amount
    /** @type {string} slug */
    this.slug = plan.slug
    /** @type {string} currency */
    this.currency = plan.currency
    /** @type {number} order - order number used for ordering */
    this.order = plan.order
  }
}

/**
 * @interface PaymentPlanCommitmentFormPayload
 * @property {string} plan
 * @property {string|null} payment_method_id
 * @property {number} licenses
 * @property {string} coupon
 */

/**
 * @interface PaymentPlanCommitmentForm
 * @extends Form
 * @extends PaymentPlanCommitmentFormPayload
 */

/**
 * Returns a new Form instance for a PaymentPlan
 * @returns {PaymentPlanCommitmentForm}
 */
export function createPaymentPlanCommitmentForm () {
  return new Form({
    plan: { value: '', rules: {} },
    coupon: { value: '', rules: {} },
    payment_method_id: { value: null, rules: {} },
    licenses: { value: 1, rules: { zeroOrMore: v => v >= 0 } },
    readOnlyLicenses: { value: 0, rules: { zeroOrMore: v => v >= 0 } }
  })
}

/**
 * @interface PaymentPlanCommitmentFormRequest
 * @extends PaymentPlanCommitmentFormPayload
 * @property {string} plan - the PlanSlug for a product
 */

/**
 * Prepares the PaymentPlan to be send to the Api for updating
 * @deprecated - Will most probably get deprecated as it is not needed
 * @param {HF_PaymentPlanTransformed[]} filteredPaymentPlans
 * @returns {function(PaymentPlanCommitmentFormPayload): PaymentPlanCommitmentFormRequest}
 */
export function createPaymentPlanCommitmentRequest (filteredPaymentPlans) {
  return (payload) => {
    const plan = filteredPaymentPlans.find(p => p.slug === payload.plan)
    const planSlug = plan.commitment.slug

    return {
      ...payload,
      plan: planSlug
    }
  }
}
