<template>
  <el-tooltip
    :content="tooltipContent"
    :disabled="!disabledStatus"
    class="RestrictedButtonTooltip"
    effect="dark"
    placement="top"
  >
    <span>
      <component
        :is="tag"
        :class="extraClasses"
        v-bind="computedAttributes"
        :disabled="disabledStatus"
        type="button"
        class="RestrictedButton"
        v-on="computedListeners"
      >
        <slot />
      </component>
    </span>
  </el-tooltip>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    permission: {
      type: [String, Boolean],
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    restrictedText: {
      type: String,
      default: 'warnings.permissions.restricted'
    },
    limitedText: {
      type: String,
      default: 'warnings.permissions.limit_reached'
    },
    tooltipText: {
      type: String,
      default: ''
    },
    to: {
      type: [Object, String],
      default: null
    },
    tag: {
      type: String,
      default: 'button'
    },
    actionClass: {
      type: [String, Object, Array],
      default: ''
    },
    enableWhenNotAuthorized: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    permissionObject () {
      return this.authorize(this.permission)
    },
    hasAuthorization () {
      return this.permissionObject['can']
    },
    limitationType () {
      return this.$safeGet(this.permissionObject, 'reason.type')
    },
    isLimitReached () {
      return this.limitationType === 'quota'
    },
    limitMax () {
      return this.$safeGet(this.permissionObject, 'reason.max', 0)
    },
    limitCurrent () {
      return this.$safeGet(this.permissionObject, 'current', 0)
    },
    extraClasses () {
      return [
        { isRestricted: !this.hasAuthorization },
        { isLimitReached: this.isLimitReached },
        { 'is-loading': this.loading },
        this.actionClass
      ]
    },
    disabledStatus () {
      return this.disabled || (this.enableWhenNotAuthorized ? false : !this.hasAuthorization)
    },
    tooltipContent () {
      switch (this.limitationType) {
        case 'quota':
          return this.$t(this.limitedText, { limit: this.limitMax, current: this.limitCurrent })
        default:
          return (this.disabled && this.tooltipText)
            ? this.$t(this.tooltipText)
            : this.$t(this.restrictedText)
      }
    },
    computedListeners () {
      return {
        ...this.$listeners,
        click: this.handleClick
      }
    },
    location () {
      return this.to ? this.$router.resolve(this.to) : null
    },
    computedAttributes () {
      const attr = {
        ...this.$attrs
      }
      if (this.to && this.tag === 'a') {
        attr.href = this.location.href
      }
      return attr
    }
  },
  methods: {
    handleClick (e) {
      this.$emit('authorized-click', this.hasAuthorization, {
        max: this.limitMax,
        current: this.limitCurrent,
        isLimitReached: this.isLimitReached
      })
      if (this.isLimitReached) {
        this.$emit('limit-reached-click', {
          max: this.limitMax,
          current: this.limitCurrent
        })
      }
      // if we dont have authorization to click, we stop
      if (!this.hasAuthorization) return false
      if (this.to) {
        e.preventDefault()
        return this.$router.push(this.location.location)
      }
      this.$emit('click', e)
      return true
    }
  }
}
</script>
