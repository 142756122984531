const numeral = require('numeral')

function round (value, precision = 2) {
  const factor = Math.pow(10, precision)

  return Math.round(value * factor) / factor
}

function abbr (v, cutoff = 10000) {
  const numeralValue = numeral(v)
  const value = numeralValue.value()

  // do not abbreviate numbers below 10k
  if (Math.abs(value) < cutoff) {
    return numeralValue.format('0')
  }

  const result = numeralValue.format('0[.]0a').toUpperCase()

  // if result has more than 4 chars (without decimal dot) after abbreviation
  if (result.replace('.', '').length > 4) {
    // return a rounded value
    return numeralValue.format('0a').toUpperCase()
  }

  return result
}

function abbrInt (value) {
  return numeral(value).format('0a').toUpperCase()
}

function percentage (value) {
  let precision = 1
  if (Math.abs(value) > 0.1) precision = 0
  return round(value * 100, precision)
}

function minAbbr (value, minValue, cutoff) {
  if (value < minValue) {
    return `< ${minValue}`
  }
  return abbr(value, cutoff)
}

export {
  round,
  abbr,
  abbrInt,
  percentage,
  minAbbr
}
