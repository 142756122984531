<template functional>
  <div class="DashboardFiltersV2Item" v-bind="data.attrs" :class="[data.class,data.staticClass]">
    <slot />
  </div>
</template>

<script>
/**
 * @module DashboardFiltersV2Item
 */
export default {
  name: 'DashboardFiltersV2Item'
}
</script>

<style lang='scss'>
@import '~utils';

@include touch {
  .DashboardFiltersV2__Tertiary {
    .DashboardFiltersV2Item {
      .DashboardFiltersV2-DropdownTrigger {
        padding: 0 $padding $padding;
        margin: 0 0 $margin 0;
        border-bottom: 1px solid $hf__color-black;
      }

      .DropdownList {
        display: block;

        .dropdown-menu {
          margin-top: $margin;
          margin-bottom: $margin;
          position: static;
          padding: 0;
        }

        .dropdown-content {
          background: $grey-light;
          box-shadow: none;
          border-radius: 0;
        }
      }
    }

    .DashboardFiltersV2-ButtonsContainer {
      padding: 0 $padding;
      flex-flow: column;

      .DashboardFiltersV2Item {
        margin: 0 0 $margin 0;
      }

      .button {
        width: 100%;
      }
    }
  }
}

.DashboardFiltersV2Item {
  min-width: 85px;
  text-align: center;

  .DashboardFiltersV2-DropdownTrigger {
    cursor: pointer;
  }

  .DropdownList.is-selected .DashboardFiltersIcon__Icon {
    color: $hf__color-primary;
  }
}

.DashboardFiltersV2-ButtonsContainer {
  display: flex;
  flex-flow: row;
  padding-left: $padding;

  &:last-child {
    margin-left: auto;
  }

  @include rtl {
    padding-right: $padding;

    &:last-child {
      margin-right: auto;
      margin-left: unset;
    }
  }

  .DashboardFiltersV2Item {
    margin: 0 $margin-small;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    @include rtl {
      &:first-of-type {
        margin-right: 0;
        margin-left: $margin-small;
      }

      &:last-of-type {
        margin-left: 0;
      }
    }
  }

}
</style>
