export {
  load,
  persist,
  forget,
  checkForAddblocker,
  loadScript,
  hasHttp,
  // stripHtml,
  storagePath,
  handleError,
  getUrlToDomain
} from '@hypefactors/shared/js/utils/index.js'

export { percentage, round, abbr, abbrInt } from '@hypefactors/shared/js/utils/numberUtils.js'

export { getUniqueArrayValues, removeDuplicatesSimple } from '@hypefactors/shared/js/utils/arrayUtils'

/**
 * Checks if url is Valid
 * @param str
 * @return {boolean}
 * @constructor
 */
export function validURL (str) {
  let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?' + // port
    '(\\/[-a-z\\d%_.~+&:]*)*' + // path
    '(\\?[;&a-z\\d%_.,~+&:=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
  return pattern.test(str)
}

export function sluggify (st) {
  st = st.toLowerCase()
  st = st.replace(/[\u00C0-\u00C5]/ig, 'a')
  st = st.replace(/[\u00C8-\u00CB]/ig, 'e')
  st = st.replace(/[\u00CC-\u00CF]/ig, 'i')
  st = st.replace(/[\u00D2-\u00D6]/ig, 'o')
  st = st.replace(/[\u00D9-\u00DC]/ig, 'u')
  st = st.replace(/[\u00D1]/ig, 'n')
  st = st.replace(/[^a-z0-9 ]+/gi, '')
  st = st.trim().replace(/ /g, '-')
  st = st.replace(/[-]{2}/g, '')
  return (st.replace(/[^a-z\- ]*/gi, ''))
}

/**
 * Opens a window popup with url and options
 * @param {String} url - Url to open to
 * @param {Object} options - Options object
 * @return {Window}
 */
export function openWindow (url, options = {}) {
  const { width = 800 } = options
  const { height = 600 } = options
  const { left = (window.screen.width / 2) - (width / 2) } = options
  const { top = (window.screen.height / 2) - (height / 2) } = options

  const windowOptions = `menubar=0,location=0,status=0,width=${width},height=${height},top=${top},left=${left}`
  const popup = window.open(url, '_blank', windowOptions)
  if (!popup) {
    throw new Error('Please enable popups')
  }
  popup.focus()
  return popup
}

/**
 * Poll a window for specific query params
 * @param popup
 * @param interval
 * @param search
 * @return {Promise<any>}
 */
export function pollPopupForParameter (popup, { interval = 200, search = null }) {
  return new Promise((resolve, reject) => {
    const intervalId = window.setInterval(function () {
      let documentOrigin = null
      let popupWindowOrigin = null
      try {
        documentOrigin = document.location.host
        popupWindowOrigin = popup.location.host
      } catch (e) {}
      if ((popupWindowOrigin && documentOrigin) && (popupWindowOrigin === documentOrigin) && (popup.location.search === search)) {
        clearInterval(intervalId)
        resolve()
        popup.close()
      } else if (popup.closed !== false) {
        return reject(new Error('User closed window without it being ready'))
      }
    }, interval)
  })
}
