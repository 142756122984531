import Pusher from 'pusher-js'
import Echo from 'laravel-echo'
import { load } from '@hypefactors/shared/js/utils'
import { getUrlToDomain } from '../utils'

// Pusher.logToConsole = process.env.VUE_APP_ENV === 'development'
// mock Pusher instance
let PusherInstance = {
  subscribe: channel => ({ bind: event => console.log('[PusherService]', channel, event) }),
  config: {
    auth: {
      headers: {}
    }
  }
}
const channel = { listen: () => {} }
let EchoInstance = {
  private: () => channel,
  join: () => channel,
  channel: () => channel,
  leave: () => {},
  socketId: () => 'fake_id'
}

// remove the Pusher service if in CI or Cypress
if (process.env.VUE_APP_ENV !== 'ci' && !window.Cypress) {
  PusherInstance = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
    cluster: 'eu',
    encrypted: true,
    authEndpoint: getUrlToDomain('broadcasting/auth', 'api'),
    auth: {
      headers: {
        'Authorization': `Bearer ${load('access_token', '')}`
      }
    }
  })
  EchoInstance = new Echo({
    broadcaster: 'pusher',
    client: PusherInstance
  })
}

export { PusherInstance, EchoInstance }
