export const fakeLocalStorage = {
  _data: {},
  setItem: function (id, val) { this._data[id] = String(val) },
  getItem: function (id) { return this._data.hasOwnProperty(id) ? this._data[id] : undefined },
  removeItem: function (id) { return delete this._data[id] },
  clear: function () { this._data = {} }
}

export function isLocalStorageAvailable (w = window) {
  const mod = 'localstorage_test'
  try {
    w.localStorage.setItem(mod, mod)
    w.localStorage.removeItem(mod)
    return true
  } catch (e) {
    return false
  }
}

export const localStorageService = isLocalStorageAvailable() ? window.localStorage : fakeLocalStorage
