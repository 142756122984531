<template>
  <div class="TheGuidedTour">
    <el-popover
      v-if="currentSettings"
      placement="bottom"
      width="450"
      popper-class="TheGuidedTour-Dropdown"
    >
      <div slot="reference" class="is-size-5 is-cursor-pointer">
        <v-icon type="question-balloon-o" />
        <span class="m-l-s is-uppercase tracking-wider">{{ $t('components.the_guided_tour.title') }}</span>
      </div>
      <div>
        <div class="p-a-m">
          <div class="has-text-weight-bold is-size-2 m-b-m">
            {{ currentSettings.title }}
          </div>
          <div
            v-for="(guide, index) in currentSettings.guides"
            :key="index"
            :class="{ 'border-t-grey-light m-t-s p-t-s' : index > 0 }"
          >
            <div class="has-text-weight-bold">
              {{ guide.label }}
            </div>
            <div class="columns is-mobile">
              <div class="column">
                {{ guide.description }}
              </div>
              <div class="column is-narrow">
                <v-button class="is-black is-small" @click="play(guide)">
                  <template v-if="guide.type === 'video'">
                    {{ $t('components.the_guided_tour.play') }}
                  </template>
                  <template v-else-if="guide.type === 'tour'">
                    {{ $t('components.the_guided_tour.start') }}
                  </template>
                </v-button>
              </div>
            </div>
          </div>
        </div>
        <div class="p-h-m p-v-s has-background-grey-darker has-text-weight-bold">
          {{ $t('components.the_guided_tour.check_all') }}
          <router-link :to="{ name: 'support.guides' }" class="has-text-white has-text-decoration-underline">
            {{ $t('components.the_guided_tour.here') }}
          </router-link>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import { TOUR_GUIDES } from '@/constants/tourGuides'
import { LocationService } from '@hypefactors/shared/js/services/LocationService'

/**
 * @module TheGuidedTour
 */
export default {
  name: 'TheGuidedTour',
  data () {
    return {
      settings: TOUR_GUIDES
    }
  },
  computed: {
    currentSettings () {
      return this.settings.find(setting => this.$route.name.includes(setting.name))
    }
  },
  methods: {
    play (guide) {
      const type = guide.type
      if (type === 'video') {
        this.$bus.$emit('VideoDialog:open', guide.videoId)
      } else if (type === 'tour') {
        typeof Intercom !== 'undefined' && Intercom('startTour', guide.tourId)
      } else {
        LocationService.assign(guide.href)
      }
    }
  }
}
</script>
<style lang="scss">
@import "~utils";

.TheGuidedTour-Dropdown {
  background: $hf__color-gray--light;
  border-radius: 0;
  padding: 0;

  .popper__arrow:after {
    border-bottom-color: $hf__color-gray--light !important;
  }
}
</style>
