import CancelToken from 'axios/lib/cancel/CancelToken'
import isCancel from 'axios/lib/cancel/isCancel'
import _merge from 'lodash/merge'
import axiosRetry from 'axios-retry'

function datafy (method, args) {
  return this[method](...args).then(r => r.data.data)
}

/**
 * Api class for all ajax requests. Should be instantiated only once per project.
 */
export class Api {
  constructor (service) {
    if (!service) {
      throw Error('Pass an ajax library like fetch or axios in constructor param')
    }
    this.service = service

    this.service.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
    this.service.defaults.headers.common['Accept'] = 'application/json'
    this.service.defaults.headers.common['Content-Type'] = 'application/json'

    // By default, we do not need to perform any kind of retrying.
    // Initialization of the library is required here so it can
    // retrieve the configuration from the request options.
    axiosRetry(this.service, { retries: 0 })
  }

  /**
   * Get request
   * @param args
   * @return {Promise<any>}
   */
  get (...args) {
    return this.service.get(...args)
  }

  getData (...args) { return datafy.call(this, 'get', args) }

  /**
   * Post request
   * @param args
   * @return {Promise<any>}
   */
  post (...args) {
    return this.service.post(...args)
  }

  postData (...args) { return datafy.call(this, 'post', args) }

  /**
   * Put request
   * @param args
   * @return {Promise<any>}
   */
  put (...args) {
    return this.service.put(...args)
  }

  putData (...args) { return datafy.call(this, 'put', args) }

  /**
   * Patch request
   * @param args
   * @return {Promise<any>}
   */
  patch (...args) {
    return this.service.patch(...args)
  }

  patchData (...args) { return datafy.call(this, 'patch', args) }

  /**
   * Delete request
   * @param args
   * @return {Promise}
   */
  delete (...args) {
    return this.service.delete(...args)
  }

  deleteData (...args) { return datafy.call(this, 'delete', args) }

  request (method, ...args) {
    return this[method](...args)
  }

  /**
   * Recursively fetches all items from a cursor paginated endpoint.
   *
   * @param {string} endpoint
   * @returns Promise
   */
  fetchAllWithCursor (endpoint, options) {
    return new Promise((resolve, reject) => {
      let items = []

      const opts = _merge(options, { params: { cursor: null } })

      const cb = (data, error) => {
        if (error) return reject(error)

        // We're done if we don't have more items
        if (!data.meta || !data.meta.cursor) {
          return resolve([...items, ...data.data])
        }

        // Shift cursors
        opts.params.cursor = data.meta.cursor

        // Append new data
        items = [...items, ...data.data]

        if (opts.params.cursor !== null) {
          this.fetchWithCursor(endpoint, opts, cb)
        }
      }

      this.fetchWithCursor(endpoint, opts, cb)
    })
  }

  /**
   * Performs a GET request to fetch a chunk of data
   * using the provided cursor.
   *
   * @param {string} endpoint
   * @param {object} options
   * @param {object} options.params
   * @param {string} options.params.cursor
   * @param {function} callback
   */
  fetchWithCursor (endpoint, options, callback) {
    this.get(endpoint, options)
      .then(response => callback(response.data, null))
      .catch(error => callback(null, error))
  }

  /**
   * Generates a cancel token
   * @return {CancelTokenSource}
   */
  cancelToken () {
    return CancelToken.source()
  }

  /**
   * Checks if a the param is a token
   * @return {boolean}
   * @param token
   */
  isCancelToken (token) {
    return isCancel(token)
  }
}

export default Api
