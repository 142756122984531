import { api } from './ApiInstanceInjector'

const METRICS_URL = 'metrics/clippings'

/* Hypefactors Value */
const HF_VALUE_URL = `${METRICS_URL}/hypefactors_value`

export function hfValueTotals (params) {
  return api.get(`${HF_VALUE_URL}`, params)
}

export function hfValueTotalsByCountry (params) {
  return api.get(`${HF_VALUE_URL}/by_country`, params)
}

export function hfValueTimeseriesByBrand (params) {
  return api.get(`${HF_VALUE_URL}/timeseries/by_brand`, params)
}

export function hfValueTimeseriesByCountry (params) {
  return api.get(`${HF_VALUE_URL}/timeseries/by_country`, params)
}

/* Clippings */
const CLIPPINGS_URL = `${METRICS_URL}/total`

export function clippingsTotals (params) {
  return api.get(`${CLIPPINGS_URL}`, params)
}

export function clippingsTotalsByCountry (params) {
  return api.get(`${CLIPPINGS_URL}/by_country`, params)
}

export function clippingsTimeseriesByBrand (params) {
  return api.get(`${CLIPPINGS_URL}/timeseries/by_brand`, params)
}

export function clippingsTimeseriesByCountry (params) {
  return api.get(`${CLIPPINGS_URL}/timeseries/by_country`, params)
}

/* Impressions */
const IMPRESSIONS_URL = `${METRICS_URL}/impressions`

export function impressionsTotals (params) {
  return api.get(`${IMPRESSIONS_URL}`, params)
}

export function impressionsTotalsByCountry (params) {
  return api.get(`${IMPRESSIONS_URL}/by_country`, params)
}

export function impressionsTimeseriesByBrand (params) {
  return api.get(`${IMPRESSIONS_URL}/timeseries/by_brand`, params)
}

export function impressionsTimeseriesByCountry (params) {
  return api.get(`${IMPRESSIONS_URL}/timeseries/by_country`, params)
}

/* Media Type */
/**
 * Fetches the media type metrics
 * @param {object} params
 * @return {Promise<any>}
 */
export function mediaType (params) {
  return api.get(`${METRICS_URL}/media_type`, params)
}
/**
 * Fetches the media type impressions metrics
 * @param {object} params
 * @return {Promise<any>}
 */
export function mediaTypeByImpressions (params) {
  return api.get(`${METRICS_URL}/media_type/by_impressions`, params)
}
/**
 * Fetches the media type clippings metrics
 * @param {object} params
 * @return {Promise<any>}
 */
export function mediaTypeByClippings (params) {
  return api.get(`${METRICS_URL}/media_type/by_clippings`, params)
}

/* Media Category */
/**
 * Fetches the media category metrics
 * @param {object} params
 * @return {Promise<any>}
 */
export function mediaCategory (params) {
  return api.get(`${METRICS_URL}/media_category`, params)
}
/**
 * Fetches the media category impressions metrics
 * @param {object} params
 * @return {Promise<any>}
 */
export function mediaCategoryByImpressions (params) {
  return api.get(`${METRICS_URL}/media_category/by_impressions`, params)
}
/**
 * Fetches the media category clippings metrics
 * @param {object} params
 * @return {Promise<any>}
 */
export function mediaCategoryByClippings (params) {
  return api.get(`${METRICS_URL}/media_category/by_clippings`, params)
}

/* Branding */
/**
 * Fetch branding related metrics
 * @param {object} params
 * @return {Promise<any>}
 */
export function branding (params) {
  return api.get(`${METRICS_URL}/branding`, params)
}

/**
 * Fetches the Top Clippings for the dashboard
 * @param {object} options
 * @param {object} options.params - the appliedDashboardFilters from Vuex
 * @param {CancelToken} options.cancelToken - Axios cancel token
 * @return {Promise<any>}
 */
export function topClippings (options) {
  return api.get('clippings', {
    params: {
      include: 'brand',
      limit: 10,
      sort: 'highest-pr-value',
      ...options.params // destructure all the applied filters
    },
    cancelToken: options.cancelToken
  })
}

/**
 * Fetches all the Top Clippings for the dashboard
 * @param {object} options
 * @param {object} options.params - the appliedDashboardFilters from Vuex
 * @param {CancelToken} options.cancelToken - Axios cancel token
 * @return {Promise<any>}
 */
export function allTopClippings (options) {
  return api.fetchAllWithCursor('clippings', {
    params: {
      include: 'brand',
      sort: 'highest-pr-value',
      ...options.params // destructure all the applied filters
    },
    cancelToken: options.cancelToken
  })
}

/**
 * Fetches the top relations for the dashboard
 * @param {object} options
 * @param {object} options.params - the appliedDashboardFilters from Vuex
 * @param {CancelToken} options.cancelToken - Axios cancel token
 * @return {Promise<any>}
 */
export function topRelations (options) {
  return api.get('/connect/relations', {
    params: {
      limit: 3,
      include: ['stats'],
      'highest-pr-value': true,
      ...options.params
    },
    cancelToken: options.cancelToken
  })
}

/* Sentiments */
const SENTIMENTS_URL = `${METRICS_URL}/sentiment`

/**
 * Fetches the sentiments for text and picture by country
 * @param {object} options
 * @return {Promise<any>}
 */
export function sentimentsByCountries (options) {
  return api.get(`${SENTIMENTS_URL}/split/by_countries`, options)
}

/**
 * Fetches the sentiments for text and picture by sentiment type
 * @param {object} options
 * @return {Promise<any>}
 */
export function sentimentsBySentiments (options) {
  return api.get(`${SENTIMENTS_URL}/split/by_sentiments`, options)
}

export function sentimentsByBrand (options) {
  return api.getData(`${SENTIMENTS_URL}/split/by_brands`, options)
}

export function sentimentsTimeseriesByBrand (options) {
  return api.getData(`${SENTIMENTS_URL}/timeseries/by_brand`, options)
}

export function sentimentsTimeseriesByCountry (options) {
  return api.getData(`${SENTIMENTS_URL}/timeseries/by_country`, options)
}

/**
 * Fetches the tags that are used, based on the applied filters
 * @param options
 * @return {Promise<any>}
 */
export function tags (options) {
  return api.get(`${METRICS_URL}/tags`, options)
}

/**
 * Fetches the markets(Countries) that are used, based on the applied filters
 * @param options
 * @return {Promise<any>}
 */
export function markets (options) {
  return api.get(`${METRICS_URL}/countries`, options)
}

/* ======== Convenience methods ======== */

/**
 * Return the right timeseries by brand method based on the type of metrics needed
 * @param {string} type
 * @return {function(*): Promise<any>}
 */
export function fetchTimeseriesByBrandAndByType (type) {
  switch (type) {
    case 'impressions':
      return impressionsTimeseriesByBrand
    case 'clippings':
      return clippingsTimeseriesByBrand
    case 'hfValue':
      return hfValueTimeseriesByBrand
    default:
      return hfValueTimeseriesByBrand
  }
}

/**
 * Return the right timeseries by country based on the type of metrics needed
 * @param {string} type
 * @return {function(*): Promise<any>}
 */
export function fetchTimeseriesByCountryAndByType (type) {
  switch (type) {
    case 'impressions':
      return impressionsTimeseriesByCountry
    case 'clippings':
      return clippingsTimeseriesByCountry
    case 'hfValue':
      return hfValueTimeseriesByCountry
    default:
      return hfValueTimeseriesByCountry
  }
}

export function fetchTopMediaOutlets (params) {
  return api.getData(`${METRICS_URL}/top-media-outlets`, params)
}

export function fetchTopTags (params) {
  return api.getData(`${METRICS_URL}/top-tags`, params)
}
