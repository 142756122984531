import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

const BUGSNAG_KEY = process.env.VUE_APP_BUGSNAG_API_KEY

if (BUGSNAG_KEY) {
  Bugsnag.start({
    apiKey: BUGSNAG_KEY,
    maxBreadcrumbs: 40,
    releaseStage: process.env.VUE_APP_ENV,
    notifyReleaseStages: ['staging', 'production'],
    appVersion: process.env.VUE_APP_COMMIT_REF,
    plugins: [new BugsnagPluginVue()]
  })
}

export const errorLoggingService = {
  notify (error, extra) {
    if (BUGSNAG_KEY) {
      Bugsnag.notify(error, extra)
    } else {
      console.log(error, extra)
    }
  },
  identify (user, extra) {
    if (BUGSNAG_KEY) {
      Bugsnag.user = {
        id: user.id,
        name: user.full_name,
        email: user.email,
        ...extra
      }
    }
  }
}
