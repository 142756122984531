import { api } from './ApiInstanceInjector'

const BRAND_CRUD_ENDPOINT = (brandId) => `/brands/${brandId}`

export const BrandApiService = {
  delete (id) {
    return api.delete(BRAND_CRUD_ENDPOINT(id))
  },
  /**
   * Removes a user from a brand
   * @param {string} brandId
   * @param {string} brandMemberId
   * @return {Promise<any>}
   */
  removeUser (brandId, brandMemberId) {
    return api.delete(`brands/${brandId}/members/${brandMemberId}/remove-user`)
  },
  /**
   * Automatically follow every new stream that gets created on the brand
   * @param {string} brandId
   * @param {boolean} status
   * @return {Promise<any>}
   */
  autoFollowAllStreams (brandId, status) {
    return api.post(`brands/${brandId}/streams/auto-follow-new-streams`, { status })
  },
  /**
   * Follows all streams in a brand
   * @param {string} brandId
   * @return {Promise<any>}
   */
  followAllStreams (brandId) {
    return api.post(`brands/${brandId}/streams/follow-all`)
  },
  /**
   * Unfollow all streams
   * @param {string} brandId
   * @return {Promise<any>}
   */
  unfollowAllStreams (brandId) {
    return api.post(`brands/${brandId}/streams/unfollow-all`)
  },
  fetchNewsrooms (brandId, params) {
    return api.fetchAllWithCursor(`/brands/${brandId}/newsrooms`, params)
  },
  /**
   * Fetches all the newsrooms that are associated to a brand's newsrooms
   * @param {string} brandId
   * @returns {*}
   */
  fetchAttachedNewsroomCountries (brandId) {
    return api.get(`/brands/${brandId}/newsrooms/countries`).then(r => r.data.data)
  },
  fetchMembers (brandId) {
    return api.getData(`brands/${brandId}/members`)
  },
  updateMemberRole (brandId, memberId, payload, params) {
    return api.postData(`/brands/${brandId}/members/${memberId}/update-role`, payload, params)
  },
  fetchInvitation (brandId, invitationId, params) {
    return api.getData(`/brands/${brandId}/invitations/${invitationId}`, params)
  },
  fetchInvitations (brandId, params) {
    return api.getData(`brands/${brandId}/invitations`, params)
  },
  acceptInvitation (brandId, invitationId) {
    return api.postData(`/brands/${brandId}/invitations/${invitationId}`)
  },
  deleteInvitation (brandId, invitationId) {
    return api.delete(`/brands/${brandId}/invitations/${invitationId}`)
  },
  resendInvitation (brandId, invitationId) {
    return api.post(`/brands/${brandId}/invitations/${invitationId}/resend`)
  },
  create (payload, options) {
    return api.post('brands', payload, options)
  },
  update (brandId, payload, options) {
    return api.put(BRAND_CRUD_ENDPOINT(brandId), payload, options)
  },
  patch (brandId, payload) {
    return api.patch(BRAND_CRUD_ENDPOINT(brandId), payload)
  }
}
