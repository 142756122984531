import { createSetter } from '@hypefactors/shared/js/utils/vuexUtilities'

export function AppModule ({ Api, currencyConverter }) {
  const state = {
    initialLoadingState: true,

    appDataLoaded: false,

    apiMaintenaceModeStatus: null,
    apiMaintenaceModeMessage: null,

    /**
     * @type {HF_CountriesList}
     */
    countries: [],
    /**
     * @type {HF_CountriesMap}
     */
    countriesByIso: {},
    /**
     * @type {HF_CurrencyList}
     */
    currencies: [],
    /**
     * @type {HF_LanguagesList}
     */
    languages: []
  }

  const getters = {
    initialLoadingState (state) {
      return state.initialLoadingState
    },

    /**
     * Determines if the application is on maintenance mode.
     * @returns {Boolean}
     */
    isOnMaintenanceMode (state) {
      return state.apiMaintenaceModeStatus === true
    },

    /**
     * Returns the maintenance mode status message, if set.
     * @return {String}
     */
    getMaintenanceModeMessage (state) {
      return state.apiMaintenaceModeMessage
    },

    /**
     * Returns the countries list
     * @returns {HF_CountriesList}
     */
    countries (state) {
      return state.countries
    },

    /**
     * @returns {HF_CurrencyList}
     */
    currencies (state) {
      return state.currencies
    },

    /**
     * adding it here so shared components can easily convert currencies
     * @param {number} amount - amount to convert
     * @param {string} from - from currency. Usually DKK (form api)
     * @param {string} to - Current currency. Usually the active brand's currency
     * @return {number}
     */
    convertCurrency: (state) => (amount, from, to) => currencyConverter.convertCurrency(amount, from, to),

    /**
     * Returns the array of languages
     * @returns {HF_LanguagesList}
     */
    languages (state) {
      return state.languages
    },

    /**
     * Finds a country by its iso_alpha2
     * @param {string} iso2
     * @returns {HF_Country}
     */
    getCountryByIso2: (state, getters) => iso2 => {
      if (!iso2) return
      return state.countriesByIso[iso2.toUpperCase()]
    },

    getCountryNameByIsoCode2: (state, getter) => iso2 => {
      if (!iso2) return

      let country = state.countriesByIso[iso2.toUpperCase()]

      return country ? country.name : null
    }
  }

  const mutations = {
    SET_INITIAL_LOADING: createSetter('initialLoadingState'),

    SET_APP_DATA_LOADED: createSetter('appDataLoaded'),

    SET_API_MAINTENANCE_MODE_STATUS: createSetter('apiMaintenaceModeStatus'),
    SET_API_MAINTENANCE_MODE_MESSAGE: createSetter('apiMaintenaceModeMessage'),

    /**
     * Sets the countries as both a collection and a list
     * @param {HF_CountriesList} countries
     */
    SET_ALL_COUNTRIES (state, countries) {
      const countriesWithHashes = countries.map((country) => {
        return {
          ...country,
          compoundIndex: country.compoundIndex.data[0]
        }
      })
      state.countries = countriesWithHashes
      state.countriesByIso = countriesWithHashes.reduce((all, current) => {
        all[current.iso_alpha2] = current
        return all
      }, {})
    },

    /**
     * @param {HF_CurrencyList} currencies
     */
    SET_ALL_CURRENCIES: createSetter('currencies'),

    /**
     * @param {HF_LanguagesList} languages
     */
    SET_ALL_LANGUAGES: createSetter('languages')
  }

  const actions = {
    setMaintenanceModeStatus ({ commit }, statusCode) {
      commit('SET_API_MAINTENANCE_MODE_STATUS', statusCode)
    },

    setMaintenanceModeMessage ({ commit }, message) {
      commit('SET_API_MAINTENANCE_MODE_MESSAGE', message)
    },

    fetchCountries ({ commit, state }) {
      if (state.countries.length) return Promise.resolve()

      return Api.getData('countries?include=currency,compoundIndex').then(response => {
        commit('SET_ALL_COUNTRIES', response)
      })
    },

    fetchCurrencies ({ commit, state }) {
      if (state.currencies.length) return Promise.resolve()

      return Api.getData('currencies').then(response => {
        commit('SET_ALL_CURRENCIES', response)
        currencyConverter.updateCurrencies(response)
      })
    },

    async fetchLanguages ({ commit, state }) {
      if (state.languages.length) return

      const response = await Api.getData('languages')
      commit('SET_ALL_LANGUAGES', response)
    },

    async bootstrapAppData ({ dispatch }) {
      await Promise.all([
        dispatch('fetchCountries'),
        dispatch('fetchCurrencies'),
        dispatch('fetchLanguages')
      ])
    }
  }

  return {
    state,
    getters,
    mutations,
    actions
  }
}
