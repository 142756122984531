<template>
  <img class="VImage" :src="imageSource" @error="useFallback=true">
</template>

<script>
/**
 * @module VImage
 */
export default {
  name: 'VImage',
  props: {
    src: {
      type: String,
      default: ''
    },
    fallback: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      useFallback: false
    }
  },
  computed: {
    imageSource () {
      return this.useFallback && this.fallback
        ? this.fallback
        : this.src
    }
  },
  watch: {
    src () {
      this.useFallback = false
    }
  }
}
</script>
