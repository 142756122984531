import Vue from 'vue'

const state = {
  storage: {}
}

const getters = {
  getStateById: state => id => {
    return state.storage[id] || {}
  },
  itemsById: state => id => {
    if (!state.storage.hasOwnProperty(id)) return []
    return state.storage[id]['items'] || []
  },
  cursorById: state => id => {
    if (!state.storage.hasOwnProperty(id)) return null
    return state.storage[id]['cursor'] || null
  }
}

const mutations = {
  SET_ITEMS_BY_ID (state, { id, cursor, items, params }) {
    Vue.set(state.storage, id, { cursor, items, params })
  },
  UPDATE_ITEM (state, { id, itemId, override }) {
    if (!state.storage.hasOwnProperty(id)) return false
    const itemIndex = state.storage[id]['items'].findIndex((item) => item.id === itemId)
    if (itemIndex === -1) return false
    const updatedItem = Object.assign({}, state.storage[id]['items'][itemIndex], override)
    Vue.set(state.storage[id]['items'], itemIndex, updatedItem)
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
