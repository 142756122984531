import 'intersection-observer'

import Vue from 'vue'

import store from '@/store'
import router from '@/router'
import { i18n } from '@/bootstrap/i18n'
import { LockScreen } from '@/services/LockScreen'

import Root from '@/pages/Root.vue'

import '@/bootstrap/setupSharedDependencies'
import '@/bootstrap/prototypeProperties'
import '@/bootstrap/globalComponents'
import '@/bootstrap/initElementUI'
import '@/bootstrap/directives'
import '@/bootstrap/plugins'
import '@/bootstrap/globalMixin'
import '@/bootstrap/initVuelidateErrorExtractor'

Vue.config.productionTip = false

store.dispatch('updateChecker/init') // TODO: Create a service instead
LockScreen.init()

shouldSyncGlobalAuth()
  .finally(() => {
    const app = new Vue({
      el: '#hypefactors',
      router,
      store,
      i18n,
      render: h => h(Root)
    })

    if (window.Cypress) {
      window.app = app
    }
  })

function shouldSyncGlobalAuth () {
  return (!window.Cypress || window.location.search.includes('syncDownGlobalAuth'))
    ? store.dispatch('syncDownGlobalAuth')
    : Promise.resolve()
}
