<template>
  <div
    class="flag is-cover-image m-r-s is-inline-block"
    :style="backgroundImageStyle"
  />
</template>

<script>
export default {
  name: 'CountryFlag',

  props: {
    country: {
      type: String,
      required: true
    }
  },

  computed: {
    flagPath () {
      return this.getPath()
    },

    backgroundImageStyle () {
      if (this.flagPath) {
        return {
          'background-image': `url(${this.flagPath})`
        }
      }

      return ''
    }
  },

  methods: {
    getPath () {
      const country = this.country

      if (!country || typeof country !== 'string' || !!(country instanceof String)) {
        return ''
      }

      return this.$asset(`/images/flags/${country.toLowerCase()}.svg`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~utils";

.flag {
  width: 15px;
  height: 15px;
  border-radius: 100%;
}
</style>
