import Api from '@/services/Api'
import { i18n } from '@/bootstrap/i18n'
import { CrossAuth } from '@/services/CrossStorage'
import { TranslationService } from '@hypefactors/shared/js/services/TranslationService'
import { handleError } from '@hypefactors/shared/js/utils'

const Trans = new TranslationService({
  Api,
  i18n,
  CrossAuth,
  handleError
})

export { Trans }
