import { createSetter } from '@hypefactors/shared/js/utils/vuexUtilities'

const state = {
  isCoverageReady: false,
  markets: [], // TODO: Hack so the Report generator has access to this information...
  tags: [] // TODO: Hack so the Report generator has access to this information...
}

const getters = {
  appliedCoverageFilters (state, getters, globalState, globalGetters) {
    return {
      ...globalGetters.filtersQueryFor('coverage', false),
      brands: [globalGetters.activeBrandId]
    }
  }
}

const mutations = {
  SET_IS_COVERAGE_FACTS_READY: createSetter('isCoverageReady'),

  SET_MARKETS: createSetter('markets'), // TODO: Hack so the Report generator has access to this information...

  SET_TAGS: createSetter('tags') // TODO: Hack so the Report generator has access to this information...
}

export default {
  state,
  getters,
  mutations,
  namespaced: true
}
