<template>
  <div class="Banded-section">
    <div
      v-if="title"
      :class="titleColor"
      class="Banded-section__title has-text-serif"
    >
      <help-tooltip>
        <slot name="tooltip">
          {{ tooltip }}
        </slot>
      </help-tooltip>
      <span class="m-l-s">{{ title }}</span>
      <a
        v-if="collapsable"
        class="m-l-a"
        data-testid="toggleAccordion"
        @click.prevent="toggleAccordion()"
      >
        <v-icon
          size="lg"
          :type="visible ? 'times' : 'chevron-down'"
        />
      </a>
    </div>
    <transition name="fade">
      <div
        v-show="visible"
        class="Banded-section__default-slot"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>
<script>
import { persist, load } from '@/utils'
import _snakeCase from 'lodash/snakeCase'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    storageKey: {
      type: String,
      default () {
        return `panel_${_snakeCase(this.title)}_visible`
      }
    },
    isOpen: {
      type: Boolean,
      default: true
    },
    collapsable: {
      type: Boolean,
      default: true
    },
    tooltip: {
      type: String,
      default: ''
    },
    transparentTitle: {
      type: Boolean,
      default: false
    }
  },
  data () {
    let visible = load(this.storageKey, true)
    if (!this.collapsable) {
      visible = true
    } else if (this.$options.propsData.isOpen !== undefined) {
      visible = this.isOpen
    }

    return {
      // check if the isOpen prop is provided
      visible
    }
  },
  computed: {
    titleColor () {
      return {
        'has-background-grey-darker': !this.transparentTitle,
        'has-text-black': this.transparentTitle
      }
    }
  },
  methods: {
    toggleAccordion (state = !this.visible) {
      const isVisible = state
      persist(this.storageKey, isVisible)
      this.visible = isVisible
    }
  }
}
</script>
<style lang='scss'>
@import "~utils";

.Banded-section {
  padding: 0;
  width: 100%;
  background: $white;

  & + &:not(.is-gapless) {
    margin-top: $margin;
  }

  &.is-transparent {
    .Banded-section__body {
      background-color: transparent;
    }
  }

  &__title {
    padding: $padding-small $padding;
    font-size: $size-5;
    font-weight: bold;
    display: flex;
    align-items: center;

    a {
      color: $grey
    }
  }

  &__default-slot {
    padding: $padding-small;

    .has-no-bottom-padding & {
      padding-bottom: 0;
    }
  }
}
</style>
