<template>
  <el-dialog
    :visible.sync="visibleLocal"
    :title="$t('components.free_trial_upgrade_dialog.title')"
    width="70%"
    class="FreeTrialUpgradeDialog is-fullscreen-mobile"
    data-testid="FreeTrialUpgradeDialog"
  >
    <div class="columns">
      <div class="column is-6">
        <billing-stripe-card-update
          ref="stripeCardUpdate"
          :card="activeCard"
          :organisation-id="firstOrganisation.id"
          :user="currentUser"
        >
          <!-- Remove the header and buttons from the card update component F-->
          <div slot="header" />
          <div slot="actions" />
        </billing-stripe-card-update>
      </div>
      <div class="column is-6">
        <c-x-cart
          v-loading="validatingCoupon || fetchingPaymentPlans"
          interval-picker-disabled
        >
          <div class="has-text-right">
            <v-button
              :loading="isProcessing"
              class="is-primary"
              data-testid="applyCouponToTrialPlanTrigger"
              @click="applyCouponToTrialPlan"
            >
              {{ $t('general.upgrade') }}
            </v-button>
          </div>
        </c-x-cart>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { BillingApiService } from '@/services/api/BillingApiService'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'

import CXCart from '@/components/cx/payment/CXCart'
import BillingStripeCardUpdate from '@/components/billing/BillingStripeCardUpdate'

/**
 * Renders a the dialog to apply a coupon to a trial account
 * @module FreeTrialUpgradeDialog
 */
export default {
  name: 'FreeTrialUpgradeDialog',
  components: { BillingStripeCardUpdate, CXCart },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    /** @type Coupon */
    coupon: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isProcessing: false
    }
  },
  computed: {
    ...mapState('cx/payment', [
      'validatingCoupon',
      'fetchingPaymentPlans'
    ]),
    ...mapGetters([
      'currentUser',
      'activeCard',
      'subscription',
      'firstOrganisation'
    ]),
    visibleLocal: generateComputedSetterWithEmit('visible', 'update:visible')
  },
  watch: {
    // on dialog open, prepare working data
    visibleLocal (isOpened) {
      isOpened && this.handleOpen()
    }
  },
  methods: {
    ...mapActions(['fetchUser']),
    ...mapActions('cx/payment', ['fetchPlans', 'checkCoupon']),
    ...mapMutations('cx/payment', ['SET_PAYMENT_PLAN', 'SET_INTERVAL', 'CLEAR_COUPON']),

    /**
     * Prepares the data for the modal when it gets opened
     * Fetches plans and sets the coupon
     */
    handleOpen () {
      // fetch the plans
      this.fetchPlans()
      // apply the coupon if valid
      this.checkCoupon(this.coupon.id)
      // set the current plan and interval
      this.SET_PAYMENT_PLAN(this.subscription.planSlug)
      this.SET_INTERVAL(this.subscription.commitmentInterval)
    },
    /**
     * Applies the coupon to the current trial plan
     * @return {Promise<void>}
     */
    async applyCouponToTrialPlan () {
      this.isProcessing = true
      const stripeCard = this.$refs.stripeCardUpdate
      let msg = this.$t('success.coupon_applied_to_trial')

      try {
        // If the stripe card needs updating, update it
        if (stripeCard.shouldUpdate) {
          await stripeCard._updateCardAction()
        }

        // apply teh coupon to the organisation
        await BillingApiService.applyCouponToPlan(this.firstOrganisation.id, this.coupon.id)
        this.CLEAR_COUPON()

        /* Re-fetch the user and permissions and everything */
        await this.fetchUser({
          forcePermissions: true
        })

        // tell the user it was all good
        this.$notify.success(msg)

        // close the modal
        this.visibleLocal = false
      } catch (err) {
        let skipErrorReport = false
        // if the error comes from the card update, we just set a proper error to be displayed
        if (err === 'invalid_card') {
          skipErrorReport = true
          msg = this.$t('errors.credit_card_errors')
        }
        this.$displayRequestError(err, msg, 5000, skipErrorReport)
      } finally {
        this.isProcessing = false
      }
    }
  }
}
</script>
