import Vue from 'vue'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { currencySymbol } from '@/utils/currencySymbol'
import { Trans } from '@/services/TranslationService'

import ApiStatusChecker from '@hypefactors/shared/js/services/ApiStatusChecker'
import { displayRequestError, handleError } from '@hypefactors/shared/js/utils/handleErrors'
import { $redirectTo, getUrlToDomain, storagePath } from '@hypefactors/shared/js/utils/index'
import { PusherInstance } from '@hypefactors/shared/js/services/SocketService'
import { LocationService } from '@hypefactors/shared/js/services/LocationService'
import { moneyAbbrFormatHtml } from '@hypefactors/shared/js/utils/moneyFormatters'

import Api from '@/services/Api'

Vue.prototype.$safeGet = _get
Vue.prototype.$bus = new Vue()
Vue.prototype.$isEmpty = _isEmpty
Vue.prototype.$asset = storagePath
Vue.prototype.$pusher = PusherInstance
Vue.prototype.$getUrl = getUrlToDomain
Vue.prototype.$redirectTo = $redirectTo
Vue.prototype.$handleError = handleError
Vue.prototype.$ENV = process.env.VUE_APP_ENV
Vue.prototype.$displayRequestError = displayRequestError
Vue.prototype.$locationService = LocationService
Vue.prototype.$isDevMode = process.env.VUE_APP_ENV === 'development'
Vue.prototype.$apiStatusChecker = ApiStatusChecker

Vue.prototype.$api = Api
Vue.prototype.$currencySymbol = currencySymbol
Vue.prototype.$moneyAbbrFormatHtml = moneyAbbrFormatHtml
Vue.prototype.$TranslationService = Trans

Vue.prototype.$isReport = false

Vue.prototype.$notifyWithLink = function ({ title, message, to, linkContent, type = 'success', duration = 10000 }) {
  const h = this.$createElement
  const notifyInstance = this.$notify({
    title: title || (type === 'success' && this.$t('general.success')),
    message: h('div', {}, [
      message,
      ' ',
      h('a', {
        class: 'notify-link',
        on: {
          click: () => {
            this.$router.push(to)
            notifyInstance.close()
          }
        }
      }, linkContent)
    ]),
    type,
    duration
  })
  return notifyInstance
}
