<template>
  <div class="Brands-sidebar has-background-grey-darker">
    <div class="Brands-sidebar__list">
      <el-scrollbar class="Brands-sidebar__list-inner">
        <brand
          v-for="brand in brands"
          :key="brand.id"
          :brand="brand"
          :loading.sync="loading"
        />
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import Brand from './BrandsSidebarItem.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    Brand
  },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    ...mapGetters(['brands'])
  }
}
</script>

<style lang="scss">
@import "~utils";

.Brands-sidebar {
  background-color: $white;
  display: flex;
  top: 0;
  /*z-index: 10;*/

  &__list {
    width: 100%;
    overflow-y: auto;
    display: flex;

    &-inner {
      width: 100%;

      .el-scrollbar__wrap {
        overflow-x: hidden;
        margin-bottom: 0 !important;
      }

      .el-scrollbar__view {
        display: flex;
      }
    }
  }

  @include tablet {
    height: 100vh;
    width: $brands-sidebar-width;
    position: sticky;

    .el-scrollbar__view {
      display: block;
    }
  }
}
</style>
