import store from '@/store'

import ApiStatusChecker from '@hypefactors/shared/js/services/ApiStatusChecker'

export const maintenanceMode = (to, from, next) => {
  ApiStatusChecker.on('status', (previousStatusCode, currentStatusCode, statusMessage) => {
    if (store.state.app.initialLoadingState === true) {
      store.commit('SET_INITIAL_LOADING', false)
    }

    store.dispatch('setMaintenanceModeStatus', currentStatusCode === 503)
    store.dispatch('setMaintenanceModeMessage', statusMessage)
  })

  next()
}
