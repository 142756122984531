import Vue from 'vue'
import Router from 'vue-router'

import routes from './routes'
import { activeCampaignHook, reloadOnDeploy } from '@/router/guards'
import { onError } from '@hypefactors/shared/js/utils/routerUtilities'
import { maintenanceMode } from '@hypefactors/shared/js/router/guards'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        selector: to.hash
      }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

/**
 * Sets the title of the page to the deepest title available.
 *
 * @param {Route} to
 */
const setPageTitle = to => {
  let withTitle = to.matched.filter(p => p.meta.pageTitle)

  if (withTitle.length) {
    document.title = withTitle[withTitle.length - 1].meta.pageTitle
  }
}

router.beforeEach(maintenanceMode)
router.beforeEach(reloadOnDeploy)

router.afterEach(setPageTitle)
router.afterEach(activeCampaignHook)

router.onError(onError)

export default router
