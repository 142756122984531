import { DEFAULT_LANGUAGE, LANGUAGE_TO_FILE_MAP, SUPPORTED_LANGUAGES } from '../constants/trans'
import { loadLocaleFile } from '../utils/dateFormatters'

class TranslationService {
  /**
   * @param props
   * @param props.Api
   * @param props.i18n
   * @param {CrossAuth} props.CrossAuth
   * @param {string} [props.defaultLanguage]
   * @param {string[]} [props.supportedLanguages]
   * @param {function} props.handleError
   */
  constructor (props) {
    this.Api = props.Api
    this.i18n = props.i18n
    this.CrossAuth = props.CrossAuth
    this.defaultLanguage = props.defaultLanguage || DEFAULT_LANGUAGE
    this.supportedLanguages = props.supportedLanguages || SUPPORTED_LANGUAGES
    this.handleError = props.handleError
  }

  /**
   * Returns the currently active language
   * @returns {string}
   */
  get currentLanguage () {
    return this.i18n.locale
  }

  /**
   * Returns the currently active language, without locale
   * Fixes issues with #1009
   * @returns {string}
   */
  get currentLanguageNoLocale () {
    return this.i18n.locale.split('_')[0]
  }

  /**
   * Sets the language on vue-i18n
   * @param {string} lang
   */
  set currentLanguage (lang) {
    this.i18n.locale = lang
  }

  t (...params) {
    return this.i18n.t(...params)
  }

  /**
   * Returns the users browser language.
   * Checks if the users preferred browser language matches one of the supported languages.
   * Returns 'default language' if no match found
   */
  getUserSupportedLang () {
    const userPreferredLang = this.getUserLang()

    // Check if user preferred browser lang is supported
    if (this.isLangSupported(userPreferredLang.lang)) {
      return userPreferredLang.lang
    }
    // Check if user preferred lang without the ISO is supported
    if (this.isLangSupported(userPreferredLang.langNoISO)) {
      return userPreferredLang.langNoISO
    }
    return this.defaultLanguage
  }

  /**
   * Tries to get the language from CrossStorage.
   * If errored out or not supported, returns the user's browser language.
   * This will most probably be the place to use the User language from their settings when we get there.
   * @return {Promise<*>}
   */
  async getGloballySetLanguage () {
    const presetLanguage = await this._getCrossStorageLanguage()
    if (this.isLangSupported(presetLanguage)) return presetLanguage
    // we dont support the language, so just return the user's browser one.
    return this.getUserSupportedLang()
  }

  /**
   * Returns the language in cross storage
   * @return {Promise<*>}
   * @private
   */
  _getCrossStorageLanguage () {
    return this.CrossAuth.get('CURRENT_LANGUAGE')
      .catch((e) => {
        console.log(e)
      })
  }

  /**
   * Returns the users preferred language
   * @return {{lang: string, langNoISO: string}}
   */
  getUserLang () {
    const lang = window.navigator.language || window.navigator.userLanguage || this.defaultLanguage
    return {
      lang: lang.replace('-', '_'), // convert `en-US` to 'en_US'
      langNoISO: lang.split('-')[0]
    }
  }

  /**
   * Sets the language in various external services
   * @param lang
   * @returns {Promise<*>}
   */
  async setI18nLanguageInServices (lang) {
    this.currentLanguage = lang
    this.Api.service.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
    // this._changeHelpServiceLanguage(lang)
    await loadLocaleFile(lang)
    if (this.CrossAuth) {
      await this.CrossAuth.set('CURRENT_LANGUAGE', lang)
        .catch((e) => {
          console.log(e)
        })
    }
    return lang
  }

  /**
   * Changes the language if supported and not already loaded
   * @param lang
   * @return {Promise<any>}
   */
  async changeLanguage (lang) {
    if (!this.isLangSupported(lang)) throw new Error(`Language ${lang} is not a supported language`)

    if (this.currentLanguage === lang) {
      return lang // we are good, no need to change
    }
    const msgs = await this.loadLanguageFile(lang)
    this._setLocaleMessages(lang, msgs)
    return this.setI18nLanguageInServices(lang)
  }

  // TODO: Solution to change language without persisting to localstorage
  // This will need to be revised and heavily improved...
  async changeLanguageWithoutPersisting (lang) {
    if (!this.isLangSupported(lang)) throw new Error(`Language ${lang} is not a supported language`)

    if (this.currentLanguage === lang) {
      return lang // we are good, no need to change
    }

    const msgs = await this.loadLanguageFile(lang)
    this._setLocaleMessages(lang, msgs)

    this.currentLanguage = lang
    this.Api.service.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
    // this._changeHelpServiceLanguage(lang)
    await loadLocaleFile(lang)
    return lang
  }

  /**
   * Sets the locale messages in vue-i18n
   * @param {string} lang
   * @param {Object} messages
   * @private
   */
  _setLocaleMessages (lang, messages) {
    this.i18n.setLocaleMessage(lang, messages)
  }

  /**
   * Loads the language file async
   * @param {string} lang
   * @returns {Promise<*>|*}
   */
  loadLanguageFile (lang) {
    const langPath = LANGUAGE_TO_FILE_MAP[lang] || lang
    return import(/* webpackChunkName: "lang-[request]" */ `@/lang/${langPath}.json`)
  }

  /**
   * Checks if the provided language is supported by us
   * @param lang
   * @return {boolean}
   */
  isLangSupported (lang) {
    return this.supportedLanguages.includes(lang)
  }

  // /**
  //  * Changes the language in the external help service
  //  * @param {string} lang
  //  * @private
  //  */
  // _changeHelpServiceLanguage (lang) {
  //   const langMap = {
  //     'en': 'en-US',
  //     'da': 'da-DK',
  //     'de': 'de-DE',
  //     'fr': '',
  //     'nl': 'nl',
  //     'es': 'es-ES',
  //     'it': 'it-IT',
  //     'pl': '',
  //     'pt': 'pt-PT',
  //     'sv': 'sv',
  //     'fi': 'fi-FI'
  //   }
  // }
}

export { TranslationService }
