<template>
  <div
    class="BillingStripeCardUpdate"
    data-testid="BillingStripeCardUpdate"
  >
    <slot name="header">
      <h4 class="title is-4 m-t-l">
        {{ $t('components.billing.update_credit_card.title') }}
      </h4>
      <h6 class="subtitle is-6">
        {{ $t('components.billing.update_credit_card.subtitle') }}
      </h6>
    </slot>
    <stripe-card-manager
      ref="stripeCardForm"
      :card="card"
      :user="user"
      :is-replacing-card.sync="isInEditMode"
    >
      <slot name="actions">
        <v-button
          v-if="shouldUpdate"
          slot="actions"
          :loading="updatingCard"
          class="is-primary"
          data-testid="BillingStripeCardUpdate__UpdateButton"
          @click="updateCard"
        >
          {{ $t(`components.billing.update_credit_card.${!!card ? 'replace_credit_card': 'set_credit_card'}`) }}
        </v-button>
      </slot>
    </stripe-card-manager>
  </div>
</template>

<script>
import StripeCardManager from '@/components/billing/StripeCardManager'
import { BillingApiService } from '@/services/api/BillingApiService'

/**
 * Allows updating the StripeCard on the API, essentially replacing it with a new one.
 * @module BillingStripeCardUpdate
 */
export default {
  name: 'BillingStripeCardUpdate',
  components: { StripeCardManager },

  props: {
    /**
     * @type {HF_StripeCard}
     */
    card: {
      type: Object,
      default: null
    },

    organisationId: {
      type: String,
      required: true
    },

    alwaysOpen: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      /** Shows the {@see module:StripeCardForm} when a user wants to replace their card. */
      isReplacingCard: false,
      updatingCard: false
    }
  },

  computed: {
    isInEditMode: {
      get () {
        if (this.$options.propsData.hasOwnProperty('alwaysOpen')) return this.alwaysOpen
        return this.isReplacingCard
      },
      set (value) {
        if (value === false) {
          this.$emit('cancel')
        }
        this.isReplacingCard = value
      }
    },
    shouldUpdate () {
      return this.isInEditMode || !this.card
    }
  },

  methods: {
    /**
     * Updates the Stripe Card and notifies the parent.
     * @returns {Promise<*>}
     * @emits update
     */
    async updateCard () {
      if (this.updatingCard) return

      this.updatingCard = true

      try {
        await this._updateCardAction()
        this.isInEditMode = false
        this.$emit('update')
      } catch (err) {
        if (err === 'invalid_card') return
        this.$displayRequestError(err)
      } finally {
        this.updatingCard = false
      }
    },
    /**
     * Gets the token and calls the API to update the card details
     * @return {Promise<void>}
     */
    async _updateCardAction () {
      const result = await this.$refs.stripeCardForm.getToken()
      if (result.error) {
        this.updatingCard = false
        // eslint-disable-next-line no-throw-literal
        throw 'invalid_card'
      }

      await BillingApiService.updateCard(this.organisationId, {
        payment_method_id: result.setupIntent.payment_method
      })
    }
  }
}
</script>
