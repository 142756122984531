import _uniqBy from 'lodash/uniqBy'
import { Newsroom } from '@hypefactors/shared/js/models/Newsroom'

import { displayRequestError } from '@hypefactors/shared/js/utils'
import { createGetter, createMappedClassSetter, createSetter } from '@hypefactors/shared/js/utils/vuexUtilities'
import CategoryService from '@hypefactors/shared/js/services/CategoryService'

import { removeOneByKey } from '@hypefactors/shared/js/utils/arrayUtils'

import { StoryPRApi } from '@/services/api/PressReleaseService'
import { StoryApiService } from '@hypefactors/shared/js/services/api/StoryApiService'
import { BrandApiService } from '@hypefactors/shared/js/services/api/BrandApiService'
import { NewsroomService } from '@hypefactors/shared/js/services/api/NewsroomService'
import * as CategoryApiService from '@hypefactors/shared/js/services/api/CategoryService'

/* Vuex Modules */
import facts from './facts'
import pressRelease from './pressRelease'

/**
 * Determines whether the brand data should be refetched.
 * Returns true of date is older than one hour or the already present data is not fresh
 * @param {string} brandId - newly set brand
 * @param lastFetchedFor - Brand ID that data was last fetched for
 * @param lastFetchedAt - Timestamp when data wa last fetched
 * @return {boolean}
 */
const shouldBrandDataBeRefetched = (brandId, lastFetchedFor, lastFetchedAt) => {
  const shouldBeRefetched = lastFetchedAt
    ? Date.now() - lastFetchedAt >= 3600000 // if data was last fetched 1h ago
    : true
  return brandId !== lastFetchedFor || shouldBeRefetched
}

const state = {
  /** Updated when active brand changes and data is fetched  */
  lastFetchedBrandDataId: null,
  /** Updated when brand data like files or contacts gets updated */
  lastFetchedBrandDataAt: null,
  categories: [],
  categoriesFlat: [],
  brandFiles: [],
  brandContacts: [],
  /** @type {BrandPRStyle[]} */
  brandPRStyleTemplates: [],

  /** @type {Newsroom[]} */
  newsrooms: [],

  /* Loading States */
  isFetchingBrandData: false,
  isDeletingNewsroom: null,
  isCreatingNewsroom: null,
  isFetchingNewsrooms: false
}

const getters = {
  categories (state) {
    return state.categories
  },
  categoriesFlat (state) {
    return state.categoriesFlat
  },
  brandFiles (state) {
    return state.brandFiles
  },
  brandContacts (state) {
    return state.brandContacts
  },
  /**
   * Checks whether the fetched Newsroom belongs to the provided brand.
   * Uses activeBrandId by default
   * @param {string} [brandId] - the brand to check if newsroom belongs to
   * @return {Function}
   */
  isCurrentFetchedDataRelevant: (state, getters, rootSetters, rootGetters) => (brandId = rootGetters.activeBrandId) => {
    if (!state.lastFetchedBrandDataId || !brandId) {
      return false
    }
    return state.lastFetchedBrandDataId === brandId
  },
  /**
   * Fetches the primary Brand PR style for the current brand
   * @param state
   * @return {BrandPRStyle}
   */
  primaryPRStyleTemplate (state) {
    return state.brandPRStyleTemplates.find(template => template.is_primary)
  },

  /* Loading State Getters */
  newsrooms: createGetter('newsrooms'),
  isDeletingNewsroom: createGetter('isDeletingNewsroom'),
  isFetchingNewsrooms: createGetter('isFetchingNewsrooms'),
  isCreatingNewsroom: createGetter('isCreatingNewsroom'),
  attachedCountriesToNewsrooms (state, getters) {
    const array = getters.newsrooms.map((newsroom) => newsroom.country)
    return _uniqBy(array, 'iso_alpha2')
  },

  appliedNewsroomFilters (state, getters, rootState, rootGetters) {
    return rootGetters.filtersQueryFor('newsroom', false)
  },

  findNewsroom: (state, getters) => (newsroomProperty, prop = 'id') => getters.newsrooms.find(n => n[prop] === newsroomProperty),

  newsroomsFiltered (state, getters) {
    const { countries } = getters.appliedNewsroomFilters
    return getters.newsrooms.filter(n => countries ? countries.includes(n.country.iso_alpha2) : true)
  }
}

const mutations = {
  SET_NOT_PUBLISHED_WARNING: createSetter('showNotPublishedWarning'),
  SET_CATEGORIES: createSetter('categories'),
  SET_CATEGORIES_FLAT: createSetter('categoriesFlat'),
  SET_BRAND_FILES: createSetter('brandFiles'),
  SET_BRAND_CONTACTS: createSetter('brandContacts'),
  SET_BRAND_PR_STYLE_TEMPLATES: createSetter('brandPRStyleTemplates'),

  SET_LAST_FETCHED_BRAND_ID: createSetter('lastFetchedBrandDataId'),
  SET_LAST_FETCHED_BRAND_TIMESTAMP (state, value) {
    state.lastFetchedBrandDataAt = value || Date.now()
  },

  SET_NEWSROOMS: createMappedClassSetter('newsrooms', Newsroom),
  REMOVE_NEWSROOM (state, newsroomId) {
    state.newsrooms = removeOneByKey(state.newsrooms, 'id', newsroomId)
  },

  /* Loading State Mutators */
  SET_FETCHING_BRAND_DATA: createSetter('isFetchingBrandData'),
  SET_DELETING_NEWSROOM: createSetter('isDeletingNewsroom'),
  SET_IS_CREATING_NEWSROOM: createSetter('isCreatingNewsroom'),
  SET_FETCHING_NEWSROOMS: createSetter('isFetchingNewsrooms')
}

const actions = {
  fetchCategories ({ getters, commit }) {
    if (getters.categories.length) return Promise.resolve()
    return CategoryApiService.getAll()
      .then(response => commit('SET_CATEGORIES', response))
  },
  fetchCategoriesFlat ({ getters, commit }) {
    if (getters.categoriesFlat.length) return Promise.resolve()
    return CategoryApiService.getAllFlat()
      .then(response => {
        commit('SET_CATEGORIES_FLAT', response)
        CategoryService._setFlatCategories(response)
      })
  },
  fetchBrandFiles ({ getters, commit }) {
    return StoryApiService.fetchBrandFiles()
      .then(files => commit('SET_BRAND_FILES', files))
  },
  // leave this here for future contact edit on NewsroomEdit page
  fetchBrandContacts ({ commit }) {
    return StoryApiService.fetchBrandContacts()
      .then(contacts => commit('SET_BRAND_CONTACTS', contacts))
  },
  /**
   * Fetches the PR Styles for the provided brand
   * @param {string} [brandId]
   * @return {Promise<any>}
   */
  fetchBrandPRStyleTemplates ({ state, getters, commit, rootGetters }, brandId = rootGetters.activeBrandId) {
    return StoryPRApi.fetchBrandPRStyleTemplates(brandId)
      .then((response) => {
        commit('SET_BRAND_PR_STYLE_TEMPLATES', response)
      })
  },

  /**
   * Helper action to fetch the majority of brand data that is shared across stories.
   * Allows caching fetched data by brand ID and time
   * @param options
   * @param {string} [options.brandId]
   * @param {boolean} [options.forceFetch=false]
   * @return {Promise<void>}
   */
  async fetchAttachedBrandData ({ state, commit, dispatch, rootGetters }, options = {}) {
    const { brandId = rootGetters.activeBrandId, forceFetch = false } = options

    const shouldFetch = shouldBrandDataBeRefetched(brandId, state.lastFetchedBrandDataId, state.lastFetchedBrandDataAt)
    if (!shouldFetch && !forceFetch) return

    await Promise.all([
      dispatch('fetchBrandFiles'),
      dispatch('fetchBrandPRStyleTemplates')
    ])
    commit('SET_LAST_FETCHED_BRAND_ID', brandId)
    commit('SET_LAST_FETCHED_BRAND_TIMESTAMP')
  },

  /**
   * Fetches all the newsrooms for a brand
   * @param {string} brandId
   * @param {object} options
   * @return {Promise<void>}
   */
  async fetchNewsrooms ({ state, commit, getters, rootGetters }, { brandId = rootGetters.activeBrandId, options } = {}) {
    options = {
      params: {
        include: 'country'
      },
      ...options
    }
    try {
      commit('SET_FETCHING_NEWSROOMS', true)
      const newsrooms = await BrandApiService.fetchNewsrooms(brandId, options)
      commit('SET_NEWSROOMS', newsrooms)
    } finally {
      commit('SET_FETCHING_NEWSROOMS', false)
    }
  },

  async deleteNewsroom ({ commit }, newsroomId) {
    try {
      commit('SET_DELETING_NEWSROOM', newsroomId)
      await NewsroomService.delete(newsroomId)
      commit('REMOVE_NEWSROOM', newsroomId)
    } catch (err) {
      displayRequestError(err)
    } finally {
      commit('SET_DELETING_NEWSROOM', null)
    }
  },

  async createNewsroom ({ commit, dispatch }, form) {
    try {
      commit('SET_IS_CREATING_NEWSROOM', true)
      await NewsroomService.create(form)
      return dispatch('fetchNewsrooms')
    } finally {
      commit('SET_IS_CREATING_NEWSROOM', false)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    facts,
    pressRelease
  }
}
