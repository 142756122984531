import axios from 'axios'
import Api from '@hypefactors/shared/js/services/Api'
import { attachAccessToken, unauthorizedInterceptor, attachDemoDataHeader, metricsDates } from './api/ApiInterceptors'

/**
 * Creates a new Axios instance with the Hypefactors base url
 * @type {AxiosInstance}
 */
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

/**
 * Add the authorization header to each request
 */
axiosInstance.interceptors.request.use(attachAccessToken)

axiosInstance.interceptors.request.use(attachDemoDataHeader)

axiosInstance.interceptors.request.use(metricsDates)

/**
 * Logout the user if we get a 401
 */
axiosInstance.interceptors.response.use(undefined, unauthorizedInterceptor)

const api = new Api(axiosInstance)

export default api
