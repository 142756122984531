<template>
  <div
    ref="stripeCard"
    class="StripeCardElement"
  />
</template>

<script>
import { loadScript } from '@hypefactors/shared/js/utils'
import { Trans } from '@/services/TranslationService'
import { BillingApiService } from '@/services/api/BillingApiService'

const cardElementConfig = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#32325d',
      // lineHeight: '18px',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
}

/**
 * @module StripeCardElement
 */
export default {
  name: 'StripeCardElement',
  async mounted () {
    await loadScript('https://js.stripe.com/v3/', 'stripe')
    await this.$nextTick() // just in case
    this.mountStripeCardElement()
  },
  beforeDestroy () {
    this.__card && this.__card.removeEventListener('change', this.handleErrors)
  },
  methods: {
    mountStripeCardElement () {
      this.__stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY)
      const elements = this.__stripe.elements({
        locale: Trans.currentLanguageNoLocale
      })
      this.__card = elements.create('card', cardElementConfig)
      this.__card.mount(this.$refs.stripeCard)
      this.__card.addEventListener('change', this.handleErrors)
    },
    /**
     * Returns a stripe token
     * @returns {Promise<{setupIntent: { payment_method: { id: string }}, error: { messages:string }}>}
     */
    async getToken (data) {
      const clientSecret = await BillingApiService.initiateSetupIntent()
      return this.__stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: this.__card,
          billing_details: data
        }
      })
    },
    handleErrors (event) {
      if (event.error) {
        this.$emit('error', event.error.message)
      } else {
        this.$emit('error', '')
      }
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.StripeCardElement {
  border: 1px solid $grey-lighter;
  padding: $padding-small;
}
</style>
