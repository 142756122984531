
import { createSetter } from '@hypefactors/shared/js/utils/vuexUtilities'

/**
 * will generate a new date when given 2 date objects
 * preserving either the date or the time from the current date
 * @param {Boolean} useTime
 * @param {Date} current
 * @param {Date} next
 */
const getNewDate = (useTime, current, next) => {
  let year = useTime ? current.getFullYear() : next.getFullYear()
  let month = useTime ? current.getMonth() : next.getMonth()
  let day = useTime ? current.getDate() : next.getDate()
  let hours = useTime ? next.getHours() : current.getHours()
  let minutes = useTime ? next.getMinutes() : current.getMinutes()
  let seconds = useTime ? next.getSeconds() : current.getSeconds()

  return new Date(year, month, day, hours, minutes, seconds)
}

const state = {
  component: 'Start',
  originalItem: {},
  startTime: new Date(),
  endTime: new Date(),
  notifyParticipants: true,
  eventTypes: {
    Meeting: 'Meeting',
    Task: 'Task'
  },
  eventType: null,
  location: ''
}

const mutations = {
  SET_COMPONENT: createSetter('component'),
  SET_ITEM: createSetter('originalItem'),
  SET_NOTIFY_PARTICIPANTS: createSetter('notifyParticipants'),
  SET_START_TIME: createSetter('startTime'),
  SET_END_TIME: createSetter('endTime'),
  SET_EVENT_TYPE: createSetter('eventType'),
  SET_EVENT_LOCATION: createSetter('location'),

  RESET: (state) => {
    state.startTime = new Date()
    state.endTime = new Date()
    state.notifyParticipants = true
    state.component = 'Start'
    state.location = ''
  }
}

const getters = {
  isMeetingType: state => state.eventType === state.eventTypes.Meeting,
  getEventTypes: state => Object.values(state.eventTypes),
  getCurrentEventType: state => [state.eventType || state.eventTypes.Meeting],

  getStartTime: state => state.startTime,
  getEndTime: state => state.endTime
}

const actions = {
  setComponent ({ commit }, component) {
    commit('SET_COMPONENT', component)
  },

  setOriginatingItem ({ commit }, item) {
    commit('SET_ITEM', item)
  },

  setNotifyParticipants ({ commit }, value) {
    commit('SET_NOTIFY_PARTICIPANTS', value)
  },

  setEventType ({ commit }, val) {
    commit('SET_EVENT_TYPE', val)
  },

  setStartTime ({ commit, state }, val) {
    commit('SET_START_TIME', getNewDate(true, state.startTime, val))
  },

  setStartDate ({ commit, state }, val) {
    commit('SET_START_TIME', getNewDate(false, state.startTime, val))
  },

  setEndTime ({ commit, state }, val) {
    commit('SET_END_TIME', getNewDate(true, state.endTime, val))
  },

  setEndDate ({ commit, state }, val) {
    commit('SET_END_TIME', getNewDate(false, state.endTime, val))
  },

  setLocation ({ commit, state }, val) {
    commit('SET_EVENT_LOCATION', val)
  },

  resetEvents ({ commit }) {
    commit('RESET')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
