<template>
  <div class="Tier">
    <div
      :class="[`is-${size}`, {'has-background-primary': displayTier === 'A', 'has-background-dark': displayTier === 'B' }]"
      class="icon is-round"
    >
      <template v-if="displayTier">
        {{ displayTier }}
      </template>
      <div />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tier',
  props: {
    tier: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    }
  },
  computed: {
    displayTier () {
      switch (this.tier) {
        case 'aplus':
          return 'A'
        case 'bplus':
          return 'B'
        default:
          return null
      }
    }
  }
}
</script>

<style lang='scss'>
@import "~utils";

.Tier {
  padding-top: 1%;
}

</style>
