<template functional>
  <div class="HorizontalField field is-horizontal">
    <div
      :class="[`is-${props.labelType}`]"
      class="field-label"
    >
      <slot name="label" />
    </div>
    <div class="field-body">
      <slot />
    </div>
  </div>
</template>
<script>

/**
 * Renders a horizontal form field wrapper
 * @module HorizontalField
 */
export default {
  name: 'HorizontalField',
  props: {
    labelType: {
      type: String,
      default: 'normal'
    }
  }
}
</script>
