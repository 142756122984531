<template>
  <div :class="`is-${size}`" class="Avatar">
    <VImage
      v-if="src"
      :src="src"
      :fallback="fallback"
      :alt="alt"
      class="Avatar__image"
    />
    <svg
      v-else
      class="Avatar__image"
      viewBox="0 0 120 120"
    >
      <rect
        :fill="backgroundColor"
        x="0"
        y="0"
        width="120"
        height="120"
        rx="120"
        ry="120"
      />
      <text
        :font-size="fontSize"
        :fill="textColor"
        x="50%"
        y="50%"
      >
        {{ letters }}
      </text>
    </svg>
  </div>
</template>

<script>
import VImage from '@hypefactors/shared/js/components/core/VImage'

import { isColorDark } from '../../utils/colorUtils'

export default {
  name: 'RoundAvatar',
  components: {
    VImage
  },
  props: {
    src: { type: String, default: '' },
    alt: { type: String, default: 'Avatar picture' },
    fallback: { type: String, default: '' },
    acronymFallback: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => ['xsmall', 'small', 'medium', 'large', 'x-large', 'xx-large', 'xxx-large'].includes(value)
    },
    mainColor: { type: String, default: '' }
  },

  computed: {
    letters () {
      if (this.acronymFallback) {
        return this.getInitials()
      }

      return 'hf'
    },

    fontSize () {
      switch (this.size) {
        case 'xsmall':
          return '40'
        case 'small':
          return '40'
        case 'medium':
          return '30'
        case 'large':
          return '30'
        case 'x-large':
          return '35'
        case 'xx-large':
          return '35'
        default:
          return '35'
      }
    },

    backgroundColor () {
      return this.mainColor || '#4a4a4a'
    },

    textColor () {
      return isColorDark(this.backgroundColor) ? '#fff' : '#999'
    }
  },

  methods: {
    getInitials () {
      let parts = this.acronymFallback.split(/[ -]/)

      let initials = parts.map(part => part.charAt(0)).join('')

      if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
        initials = initials.replace(/[a-z]+/g, '')
      }

      return initials.substr(0, 3).toUpperCase()
    }
  }
}
</script>

<style lang="scss">
@import '~utils';

@mixin avatar-size($size) {
  height: $size;
  width: $size;
  border-radius: $size;
}

.Avatar {
  position: relative;
  overflow: hidden;
  font-size: 2.5rem;

  &.is-xsmall {
    @include avatar-size(30px);
  }

  &.is-small {
    @include avatar-size(40px);
  }

  &.is-medium {
    @include avatar-size(50px);
  }

  &.is-large {
    @include avatar-size(60px);
  }

  &.is-x-large {
    @include avatar-size(90px);
  }

  &.is-xx-large {
    @include avatar-size(120px);
  }

  &.is-xxx-large {
    @include avatar-size(160px);
  }
}

.Avatar__image {
  object-fit: cover;
  object-position: center;
  width: 100%;
  min-height: 100%;
  max-width: 100%;
  display: block;

  text {
    dominant-baseline: central;
    text-anchor: middle;
  }
}
</style>
