<template>
  <div
    id="hypefactors"
    :class="{ initialLoad: initialLoadingState }"
  >
    <div
      v-if="initialLoadingState"
      class="initialLoad__content"
    >
      <div class="loader" />
    </div>
    <template v-else>
      <maintenance-mode v-if="isOnMaintenanceMode" />
      <router-view v-else />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { Trans } from '@/services/TranslationService'
import { CrossAuth } from '@/services/CrossStorage'

import MaintenanceMode from '@hypefactors/shared/js/components/Maintenance'

export default {
  name: 'Root',
  components: {
    MaintenanceMode
  },
  computed: {
    ...mapGetters(['initialLoadingState', 'isOnMaintenanceMode'])
  },
  async mounted () {
    await this.$apiStatusChecker.start()

    if (window.Cypress) {
      document.body.classList.add('disableAnimations')
    }

    try {
      if (this.signedIn) return
      const crossStorageLang = await CrossAuth.get('CURRENT_LANGUAGE')
      Trans.changeLanguage(crossStorageLang || Trans.getUserSupportedLang())
    } catch (err) { }
  }
}
</script>

<style lang="scss">
@import "../assets/styles/app.scss";
</style>
