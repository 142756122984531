<template>
  <component
    :is="tag"
    :class="classes"
    class="RoundIcon icon is-round"
  >
    <clipping-type-icon :class="iconClass" :type="type" />
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    type: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'medium'
    },
    bgColor: {
      type: String,
      default: ''
    },
    iconClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    classes () {
      return [
        this.size ? `is-${this.size}` : '',
        `has-background-${this.bgColor || this.type}`
      ]
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.RoundIcon {
  vertical-align: middle;
}
</style>
