import { PaymentPlanCommitment } from '@/models/PaymentPlanCommitment'

/**
 * @alias HF_PaymentPlan
 * A Payment Plan holding the available Commitments
 */
export class PaymentPlan {
  constructor (plan) {
    /** @type {string} */
    this.name = plan.name
    /** @type {string} */
    this.internal_name = plan.internal_name
    /** @type {string} */
    this.slug = plan.slug
    /** @type {number} order - the order of the item */
    this.order = plan.order
    /** @type {HF_PaymentPlanCommitment[]} - List of available commitments */
    this.commitments = plan.commitments.data.map(plan => new PaymentPlanCommitment(plan))
  }
}

/**
 * @typedef {Object} HF_PaymentPlanTransformed
 * @property {string} name
 * @property {string} slug
 * @property {HF_PaymentPlanCommitment} commitment
 */
