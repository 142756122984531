import { Trans } from '@/services/TranslationService'
import { storagePath } from '@hypefactors/shared/js/utils'

const cover = (slug) => storagePath(`/images/support/${slug}.jpg`)

const tourFactory = (tourId, translationKey) => ({
  id: tourId + translationKey,
  label: Trans.t(`components.the_guided_tour.guides.${translationKey}.label`),
  description: Trans.t(`components.the_guided_tour.guides.${translationKey}.description`),
  type: 'tour',
  tourId,
  cover: cover(translationKey)
})

const videoFactory = (videoId, translationKey) => ({
  id: videoId + translationKey,
  label: Trans.t(`components.the_guided_tour.guides.${translationKey}.label`),
  description: Trans.t(`components.the_guided_tour.guides.${translationKey}.description`),
  type: 'video',
  videoId: videoId,
  cover: cover(translationKey)
})

export const TOUR_GUIDES = [
  {
    name: 'dashboard',
    title: Trans.t('general.dashboard'),
    guides: [
      tourFactory(74582, 'tour_of_hypefactors'),
      tourFactory(75272, 'dashboard_overview_tour')
    ]
  },
  {
    name: 'settings',
    title: Trans.t('general.settings'),
    guides: [
      tourFactory(78160, 'manage_daily_media_monitoring_email'),
      tourFactory(78133, 'create_a_new_brand'),
      tourFactory(78935, 'connect_email_with_hypefactors')
    ]
  },
  {
    name: 'streams',
    title: Trans.t('components.the_guided_tour.monitor'),
    guides: [
      tourFactory(78143, 'create_a_new_search_stream'),
      tourFactory(78133, 'create_a_new_brand'),
      tourFactory(75281, 'save_a_mention_manually'),
      tourFactory(80914, 'block_media_outlet'),
      videoFactory('8rCA3U8izxM', 'free_media_monitoring_overview'),
      videoFactory('RlrY5WIgZUE', 'setting_up_media_monitoring')
    ]
  },
  {
    name: 'performance',
    title: Trans.t('components.the_guided_tour.measure'),
    guides: [
      tourFactory(75285, 'edit_the_evaluations_of_a_mention'),
      tourFactory(75290, 'generate_a_media_report'),
      tourFactory(80968, 'measure_tour')
    ]
  },
  {
    name: 'story',
    title: Trans.t('general.story'),
    guides: [
      videoFactory('XhLUDNLId1o', 'setting_up_a_newsroom'),
      videoFactory('cB3Y1tyDXYA', 'publish_content_to_online_newsroom'),
      tourFactory(78927, 'manage_newsroom'),
      tourFactory(83208, 'publish_draft_story'),
      tourFactory(88997, 'embed_newsroom')
    ]
  }
]
